import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Anlass",
        "labelParticipantCount":"Teilnehmerzahl",
        "labelOrganizer":"Organisator",
        "labelLocation":"Durchführungsort",
        "labelName":"Anlass",
        "labelShortName":"Kurzname",
        "labelExerciseCount":"Gelöste Stiche",
        "labelOrganizationsCount":"Vereine",
        "labelProgramStatistics":"Stichstatistiken",
        "labelYear":"Jahr",
        "titleStatistics":"Statistiken",
        "headerAgeCategory":"Alterskategorie",
        "headerOrderCount":"Gelöst",
        "headerIncome":"Einnahmen",
        "headerProgramCharges":"Davon Doppelgeld",
        "headerMunitionType":"Munition",
        "headerMunitionCount":"Anzahl",
        "headerWeapon":"Waffe",
        "headerAwardReceived":"Kranz",
        "headerAwardCardReceived":"Kranzkarte",
        "headerQuota":"Quote",
        "headerPayoutCount":"Anzahl Auszahlungen",
        "headerPayoutAmount":"Auszahlung",
        "errorGeneralText":"Ein Fehler ist aufgetreten. Bitte nochmals versuchen."
    },
    "fr": {
        "title" : "Manifestation",
        "labelParticipantCount":"nombre de participants",
        "labelOrganizer":"organisateur",
        "labelLocation":"lieu d'exécution",
        "labelName":"manifestation",
        "labelShortName":"nom court",
        "labelExerciseCount":"passes vendues",
        "labelOrganizationsCount":"associations",
        "labelProgramStatistics":"statistiques du passe",
        "labelYear":"Année",
        "titleStatistics":"statistiques",
        "headerAgeCategory":"catégorie d'âge",
        "headerOrderCount":"vendu",
        "headerIncome":"recettes",
        "headerProgramCharges":"dont finance de passe",
        "headerMunitionType":"munition",
        "headerMunitionCount":"nombre",
        "headerWeapon":"arme",
        "headerAwardReceived":"Kranz",
        "headerAwardCardReceived":"carte couronne",
        "headerQuota":"Quote",
        "headerPayoutCount":"nombre de paiements",
        "headerPayoutAmount":"répartition"
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;