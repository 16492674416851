import localizatedTexts from "./texts/standard.texts";
import localizatedOverviewTexts from "./texts/overview.texts"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ShootAccountingOverview : React.FC = () => {
    return <div className={"container-fluid"}>
        <h1>{localizatedTexts.overview}</h1>
        <div className={"row"}>
            <div className={"col-md-4 p-2 m-2 shadow rounded"}>
                <h1>{localizatedTexts.organization}</h1>
                <div className={"text-start"}>Nicht verrechnet</div>
                <div className="progress progress-bar-animated" role="progressbar" aria-label="Warning with label" aria-valuenow={25}
                     aria-valuemin={0} aria-valuemax={100}>
                    <div className="progress-bar bg-warning text-dark" style={{"width":25+"%"}} >{(25).toFixed(2)}%</div>
                </div>
                <div className={"text-start"}>Verrechnet</div>
                <div className="progress progress-bar-animated" role="progressbar" aria-label="Warning with label" aria-valuenow={75}
                     aria-valuemin={0} aria-valuemax={100}>
                    <div className="progress-bar bg-success text-dark" style={{"width":75+"%"}} >{(75).toFixed(2)}%</div>
                </div>
            </div>
            <div className={"col-md-4 p-2 m-2 shadow rounded"}><h1>{localizatedTexts.shooter}</h1>
                <div className={"text-start"}>Nicht verrechnet</div>
                <div className="progress progress-bar-animated" role="progressbar" aria-label="Warning with label" aria-valuenow={45}
                     aria-valuemin={0} aria-valuemax={100}>
                    <div className="progress-bar bg-warning text-dark" style={{"width":45+"%"}} >{(45).toFixed(2)}%</div>
                </div>
                <div className={"text-start"}>Verrechnet</div>
                <div className="progress progress-bar-animated" role="progressbar" aria-label="Warning with label" aria-valuenow={55}
                     aria-valuemin={0} aria-valuemax={100}>
                    <div className="progress-bar bg-success text-dark" style={{"width":55+"%"}} >{(55).toFixed(2)}%</div>
                </div></div>
        </div>
        <div className={"row"}>
            <div className={"col-md-4 p-2 m-2 shadow rounded"}><h1>{localizatedTexts.shootingrange}</h1>
                <div className={"text-start"}>Nicht verrechnet</div>
                <div className="progress progress-bar-animated" role="progressbar" aria-label="Warning with label" aria-valuenow={45}
                     aria-valuemin={0} aria-valuemax={100}>
                    <div className="progress-bar bg-warning text-dark" style={{"width":45+"%"}} >{(45).toFixed(2)}%</div>
                </div>
                <div className={"text-start"}>Verrechnet</div>
                <div className="progress progress-bar-animated" role="progressbar" aria-label="Warning with label" aria-valuenow={55}
                     aria-valuemin={0} aria-valuemax={100}>
                    <div className="progress-bar bg-success text-dark" style={{"width":55+"%"}} >{(55).toFixed(2)}%</div>
                </div></div>
            <div className={"col-md-4 p-2 m-2 shadow rounded"}><h1>{localizatedTexts.report}</h1>
                <h3>{localizatedOverviewTexts.quickreport}</h3>
                <form>
                    <div className={"input-group mb3 m-1"}>
                        <span className={"input-group-text col-2"}>{localizatedOverviewTexts.startDate}</span>
                        <DatePicker className={"form-control"}/>
                    </div>
                    <div className={"input-group mb3 m-1"}>
                        <span className={"input-group-text col-2"}>{localizatedOverviewTexts.endDate}</span>
                        <DatePicker className={"form-control"}/>
                    </div>
                    <button className={"btn btn-primary m-1"} type={"submit"}>
                        {localizatedOverviewTexts.quickreport}
                    </button>
            </form>
            </div>
        </div>
    </div>
}

export default ShootAccountingOverview;