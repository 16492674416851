import LocalizedStrings from 'react-localization';

const localizedTexts = new LocalizedStrings({
"de": {
    "quickreport": "Schnellen Bericht erstellen",
    "startDate": "von",
    "endDate": "bis"
},
    "fr": {
        "quickreport": "Créer un rapport rapide",
        "startDate": "de",
        "endDate": "à"
    },
    "it": {
        "quickreport": "Creare un rapporto veloce",
        "startDate": "da",
        "endDate": "a"
    },
    "en": {
        "quickreport": "Create Quick Report",
        "startDate": "from",
        "endDate": "to"
    }})
export default localizedTexts;