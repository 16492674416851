import localizatedOrganisationTexts from "./texts/organisation.texts";
import localizatedTexts from "./texts/standard.texts";
import ApiClientFactory from "../../models/ApiClientFactory";
import React, {useEffect, useState} from "react";
import {OrganizationShotCount, ShootingRange, ShotAccountingOrganization} from "../../models/Models";
import localizatedReportTexts from "./texts/report.texts";
import ToastMessage from "../../../common/components/ToastMessage";
import {useParams} from "react-router";
import Shooter from "./Shooter";

function OrganizationWrapper(){
    const {rangeId, distanceId} = useParams();
    return <Organization rangeId={rangeId} distanceId={parseInt(distanceId??"")}/>
}

interface OrganizationProps {
    rangeId: string | undefined
    distanceId: number
}

const Organization : React.FC<OrganizationProps> = ({ rangeId, distanceId }) => {


    const apiClient = ApiClientFactory.GetApiClient();
    const [shootingRanges , setShootingRanges] = useState<ShootingRange[]>()
    const [selectedShootingRange, setSelectedShootingRange] = useState<string>("")
    const [selectedDistance , setSelectedDistance] = useState<number>(0)
    const [toastMessage, setToastMessage] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastBgClass, setToastBgClass] = useState('danger');
    const [organizations, setOrganizations] = useState<ShotAccountingOrganization[]>([]);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [filter, setFilter] = useState<string>("");
    const [orgUpdate , isOrgUpdate] = useState<boolean>(false)

    const [orgId , setOrgId] = useState<number>(0);
    const [orgNumber, setOrgNumber] = useState<string>("");
    const [orgName, setOrgName] = useState<string>("");
    const [orgCity, setOrgCity] = useState<string>("");
    const [orgZipCode, setOrgZipCode] = useState<string>("");


    useEffect(() => {
        if(shootingRanges == undefined){
            loadData();
        }
        if(selectedShootingRange === "" && selectedDistance === 0 && rangeId !=="" &&distanceId !== 0) {
            setSelectedDistance(distanceId)
            setSelectedShootingRange(rangeId??"")
        }
        getOrganization()
    }, [selectedDistance, selectedShootingRange])

    function loadData() {
        apiClient.api.coreShootingRangesList().then(data => {
            setShootingRanges(data.data);
            if(data.data.length ===1){
                setSelectedShootingRange(data.data[0].id??"");
            }
        })
    }


    const deleteOrganisation = async (orgId: number)=> {
        if(orgId !== 0){
            const result = await apiClient.api.coreOrganizationDelete(selectedShootingRange, selectedDistance, orgId)
            if(result.ok){
                showSuccessToast("Deleted");
                getOrganization()
            }
        } else
            showErrorToast("Error deleting organisation");
    }

    function changePage(page: number) {
        setCurrentPage(page)
    }

    function getDistance() {
        const distanceList = shootingRanges?.find(sr => sr.id === selectedShootingRange)?.shootingRangeDisciplines ?? [];
        if(distanceList.length === 1 && selectedDistance !== distanceList[0].distanceId){
            setSelectedDistance(distanceList[0].distanceId??0);
        }
        return shootingRanges?.find(sr => sr.id === selectedShootingRange)?.shootingRangeDisciplines ?? [];
    }

    function getOrganization() {
        if(selectedShootingRange != "" && selectedDistance != 0){
            apiClient.api.coreOrganizationDetail(selectedShootingRange, selectedDistance).then(data => {
                setTotalItems(data.data.length);
                setOrganizations(data.data);
            })
        }else{
            setOrganizations([]);
        }
    }

    interface OrganisationUpdate {
        number: string
        name: string
        city: string
        zipcode: string
        id : number
    }

    function set4Update(updateData : OrganisationUpdate){
        setSelectedShootingRange(selectedShootingRange);
        setSelectedDistance(selectedDistance);
        isOrgUpdate(true);
        setOrgName(updateData.name);
        setOrgCity(updateData.city)
        setOrgZipCode(updateData.zipcode)
        setOrgNumber(updateData.number)
        setOrgId(updateData.id)
    }

    function resetInputFields() {
        setOrgNumber("");
        setOrgName("");
        setOrgCity("");
        setOrgZipCode("");
        isOrgUpdate(false)
        setOrgId(0);
    }


    const createOrganization = async (name: string, city: string, zipcode: string, number: string )=> {

        if(name.trim().length > 0 && city.trim().length > 0){
            const shootaccountOrg: ShotAccountingOrganization = {
                "id": orgId,
                "number" : number.trim(),
                "name": name.trim(),
                "city": city.trim(),
                "zipCode": zipcode.trim(),
                "persons": [
                ]
            }
            if(!orgUpdate){
                try{
                    const result =  await apiClient.api.coreOrganizationCreate(selectedShootingRange,selectedDistance, shootaccountOrg);
                    if (result.ok){
                        showSuccessToast("Saved");
                        resetInputFields();
                        getOrganization()
                    } else {
                        showErrorToast("Error"+result.statusText);
                    }
                }catch(e){
                    showErrorToast("Fault");
                }
            } else {
                const result = await apiClient.api.coreOrganizationUpdate(selectedShootingRange, selectedDistance, shootaccountOrg);
                if (result.ok){
                    showSuccessToast("Updated");
                    getOrganization()
                    isOrgUpdate(false);
                    resetInputFields()
                }
                else
                    showErrorToast("Update Error" + result.statusText);
            }


        }
    }

    function showSuccessToast(message: string) {
        setShowToast(true);
        setToastMessage(message);
        setToastBgClass("success");
    }

    function showErrorToast(errorCode: string) {
        setToastMessage(`${errorCode}`);
        setToastBgClass("danger");
        setShowToast(true);
    }

    const currentOrganizations =
        organizations.filter(
            org =>
                org.name?.toLowerCase().includes(filter.toLowerCase())
                || org.city?.toLowerCase().includes(filter.toLowerCase())
                || org.number?.toLowerCase().includes(filter.toLowerCase())
            || org.zipCode?.toLowerCase().includes(filter.toLowerCase())
        ).slice(
            (currentPage - 1) * itemPerPage, currentPage * itemPerPage)


    const totalPages = Math.ceil(organizations.length / itemPerPage);

    return <div>
        {showToast && (
            <div id="toast-container" className="position-fixed top-0 start-50 translate-middle-x p-3"
                 style={{zIndex: 11}}>
                <ToastMessage id="errorToast" message={toastMessage} onClose={() => setShowToast(false)} role="alert" bgClass={toastBgClass}/>
            </div>
        )}
        <h1>
        {localizatedTexts.organization}
    </h1>
        <div className={"container-fluid "}>
            <div className={"row"}>

                <div className={"col-md-10 shadow rounded p-4 m-4 mt-2"}>
                    <form>
                    <h2>{orgUpdate ? localizatedOrganisationTexts.editOrganisation : localizatedOrganisationTexts.createOrganisation}</h2>
                    <div className={"row"}>
                        {shootingRanges?.length != undefined && shootingRanges.length > 1 ?
                            <div className={"input-group mb-3 m-1"}>
                                <span className={"input-group-text"}>{localizatedTexts.shootingrange}</span>
                                <select disabled={true} value={selectedShootingRange} className={"form-select"} onChange={data => {
                                    setSelectedShootingRange(data.currentTarget.value);
                                    setSelectedDistance(0)

                                    const selectElement = document.getElementById("distanceSelector") as HTMLSelectElement;
                                    if (selectElement) {
                                        selectElement.value = ""; // Zurücksetzen auf den übergebenen Standardwert
                                    }
                                }}>
                                    <option value={""}></option>
                                    {shootingRanges.map((range) => {
                                        return <option value={range.id || 0}>{range.name}</option>
                                    })
                                    }
         *                       </select>
                            </div> : <div>{localizatedTexts.shootingrange} : {shootingRanges?.map(sr => {
                                return <h1>{sr.name}</h1>
                            })}</div>}
                        {selectedShootingRange != "" && getDistance().length > 1?
                            <div className={"input-group mb-3 m-1"}>
                                <span className={"input-group-text "}>{localizatedReportTexts.discipline}</span>
                                <select disabled={true} value={selectedDistance} id={"distanceSelector"} className={"form-select col-10"} onChange={data => {setSelectedDistance(parseInt(data.currentTarget.value))}}>
                                    <option value={0}></option>
                                    { getDistance()?.map((ds) => {
                                        return <option value={ds.distanceId || 0}>{ds.disciplineName}</option>
                                    })
                                    }
                                </select>
                            </div> :  getDistance().length === 1 ? <div className={"input-group mb-3 m-1"}><span className={"input-group-text "}>{localizatedReportTexts.discipline}</span>  {getDistance()?.map(dc => {

                                return <input type={"text"} className={"form-control"} disabled={true} value={dc.disciplineName ??""}></input>
                            })}</div> : ""}

                        <div className={"input-group mb-3 m-1"}>
                            <span className={"input-group-text col-2"}>{localizatedOrganisationTexts.organisationNumber}</span>
                            <input value={orgNumber} onChange={(e) => setOrgNumber(e.target.value)} required={true} type={"text"} className={"form-control col-3"} id={"Number"}/>
                            <span className={"input-group-text col-2"}>{localizatedOrganisationTexts.organisationName}</span>
                            <input value={orgName} onChange={(e) => setOrgName(e.target.value)}required={true} type={"text"} className={"form-control col-5"} id={"Name"}/>
                        </div>
                        <div className={"input-group mb-3 m-1"}>
                            <span className={"input-group-text col-2"}>{localizatedOrganisationTexts.postcode}</span>
                            <input value={orgZipCode} onChange={(e) => setOrgZipCode(e.target.value)} required={true} type={"text"} className={"form-control col-3"} id={"PostCode"}/>
                            <span className={"input-group-text col-2"}>{localizatedOrganisationTexts.city}</span>
                            <input value={orgCity} onChange={(e) => setOrgCity(e.target.value)} required={true} type={"text"} className={"form-control col-5"} id={"City"}/>
                        </div>
                        <button className={"btn btn-primary m-1 col-2"} onClick={(e) => {
                            e.preventDefault()
                            createOrganization(orgName, orgCity, orgZipCode, orgNumber)}}>
                            {localizatedTexts.save}
                        </button>
                        <button className={"btn btn-secondary m-1 col-2"} onClick={(e) => {
                            e.preventDefault()
                            resetInputFields()}}>
                        {localizatedTexts.reset}
                        </button>
                    </div>
                    </form>
                </div>
                <div className={"col-md-10 shadow rounded p-4 m-4 mt-2"}>
                    <h2>{localizatedTexts.organisations}</h2>
                    <form>
                        {organizations.length > 0 ?
                        <div className={"row"}>
                            <div className={"input-group mb-3 m-1"}>
                                <span className={"input-group-text"}>{localizatedTexts.search}</span>
                                <input value={filter} type={"text"} className={"form-control"} placeholder={localizatedTexts.OrganisationNameOrCity} onChange={data => {setFilter(data.currentTarget.value)}}/>
                                <span className={"input-group-text"}>{localizatedTexts.elementsPerPage}</span>
                                <select className={"form-select"} defaultValue={itemPerPage} onChange={data => {setItemPerPage(parseInt(data.currentTarget.value))}}>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                            </div>

                        </div> :"" }

                    </form>
                    {organizations.length > 0 ?
                    <table className={"table table-striped table-hover table-bordered"}>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>{localizatedTexts.organization}</th>
                        <th>{localizatedOrganisationTexts.city}</th>
                        <th className={"col-2"}></th>
                    </tr>
                    </thead>
                    <tbody className={"table-hover"}>
                    {currentOrganizations.map((sr, index: number) => {
                        return  <tr key={index+"orgalist"}>
                            <td className={"align-middle"}>{sr.number}</td>
                            <td className={"align-middle"}>{sr.name}</td>
                            <td className={"align-middle"}>{sr.zipCode + " "+ sr.city}</td>
                            <td className={"text-center align-middle"}>
                                <button className={"btn btn-primary"} onClick={()=> set4Update({
                                    name: sr.name??"",
                                    city: sr.city??"",
                                    zipcode: sr.zipCode??"",
                                    number: sr.number??"",
                                    id: sr.id??0
                                })}><span className={"bi-pen"}></span></button>
                                &nbsp;
                                <button className={"btn btn-danger"} onClick={() => deleteOrganisation(sr.id??0)}><span className={"bi-trash"}></span></button>
                            </td>
                        </tr>
                    })}

                    </tbody>
                </table> : "" }

                    <nav aria-label="Page navigation">
                        <ul className="pagination">
                            {currentPage !== 1 ? <li className="page-item"><a className="page-link" onClick={() => changePage(currentPage-1)} >{localizatedTexts.paginatinoPrevious}</a></li> :""}
                            {Array.from({length: totalPages}, (_, i) => (
                                <li key={i} className={i === currentPage - 1 ? 'page-item active' : 'page-item'}><a className={"page-link"} onClick={() => {
                                    if(currentPage !== i+1){
                                        changePage(i+1)
                                    }
                                }}>{i+1}</a> </li>
                            ))}
                            {currentPage !== totalPages && totalPages > 0 ? <li className="page-item"><a className="page-link" onClick={() => changePage(currentPage+1)}>{localizatedTexts.paginationNext}</a></li> : ""}
                        </ul>
                    </nav>

                </div>
            </div>
        </div>
    </div>
}



export default OrganizationWrapper;