import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "overview" : "Übersicht",
        "ranges" : "Schiessanlagen",
        "organizations": "Vereine",
        "members":"Mitglieder",
        "shootAccounting":"Schussabrechnung",
        "winfFireLive":"WinFire Live",
    },
    "fr": {
        "overview": "aperçu",
        "ranges": "stands de tir",
        "organizations": "associations",
        "members": "membres",
        "shootAccounting": "comptabilité de tir",
        "winfFireLive": "WinFire Live"
    },
    "it": {
        "overview": "panoramica",
        "ranges": "poligoni di tiro",
        "organizations": "associazioni",
        "members": "membri",
        "shootAccounting": "contabilità di tiro",
        "winfFireLive": "WinFire Live"
    },
    "en": {
        "overview": "overview",
        "ranges": "shooting ranges",
        "organizations": "organizations",
        "members": "members",
        "shootAccounting": "shot accounting",
        "winfFireLive": "WinFire Live"
    }
});
export default localizedTexts;