import {Component} from "react";
import {NavLink} from "react-router-dom";
import localizedTexts from "./texts/ScreenManagment.texts";

function ScreenManagementNavigationWrapper() {

    return <ScreenManagementNavigation/>
}

export class ScreenManagementNavigation extends Component<ScreenManagementNavigationProps, ScreenManagementNavigationState> {

    render() {
        return <div className="btn-group py-2" role="group" aria-label="Vertical button group">
            <NavLink to="screens" className="btn btn-outline-dark">
                <span className="bi-table"> </span>{localizedTexts.screenList}
            </NavLink>
        </div>
    }
}

interface ScreenManagementNavigationState {

}

interface ScreenManagementNavigationProps {

}

export default ScreenManagementNavigationWrapper;