import {useParams} from "react-router";
import React, {ChangeEvent, Component} from "react";
import localizedTexts from "./texts/EventParticipantsResult.texts";
import ApiClientFactory from "./models/ApiClientFactory";
import {EventRegistratioInformation, Exercise, ParticipantWithResults, ShootingProgramDefinition, ShootingProgramSequence} from "./models/Models";

function EventParticipantsResultWrapper(){
    let {eventId, participantnumber} = useParams();
    return <EventParticipantsResult eventId={eventId} participantNumber={participantnumber} />
}

export class EventParticipantsResult extends Component<EventParticipantsResultProps, EventParticipantsResultState> {
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: EventParticipantsResultProps) {
        super(props);

        this.state = {
            eventRegistrationInformation: undefined,
            participant: undefined,
            allWeapons: [],
            shootingProgramDefinitions: {},
            selectedExerciseId: undefined,
            isLoading: true,
        }
    }

    componentDidMount() {
        this.loadData();

        document.addEventListener("shown.bs.modal", this.handleModalShown);
    }

    componentWillUnmount() {
        document.removeEventListener("shown.bs.modal", this.handleModalShown);
    }


    handleModalShown = (event: Event) => {
        if ((event.target as HTMLElement).id === "enterShotsModal") {
            const inputField = document.getElementById("shotinput0") as HTMLInputElement;
            inputField?.focus(); // Programmatically focus the input field
            inputField?.select();
        }
        else if ((event.target as HTMLElement).id === "enterSequencesModal") {
            const inputField = document.getElementById("seqinput0") as HTMLInputElement;
            inputField?.focus(); // Programmatically focus the input field
            inputField?.select();
        }
    };


    async loadData() {
        try {
            var infos = await this.apiClient.api.eventsEventInformationRegistrationinformationDetail(this.props.eventId ?? "");
            if (infos.ok) {
                var weapons: any = {};
                var shootingProgramDefinitions: any = {};
                if (infos.data.disciplines?.length && infos.data.disciplines?.length > 0) {
                    infos.data.disciplines.forEach(d => {
                        d.shootingProgramDefinitions?.forEach(s => {
                            var shootingProgramDefinitionId = `${s.id}`;
                            shootingProgramDefinitions[shootingProgramDefinitionId] = s;
                        })
                        d.fields?.forEach(f => {
                            f.weapons?.forEach(w => {
                                var weaponId = `${w.id}`;
                                weapons[weaponId] = w;
                            })
                        })
                    });
                }

                this.setState({
                    eventRegistrationInformation: infos.data,
                    allWeapons: weapons,
                    shootingProgramDefinitions: shootingProgramDefinitions,
                    isLoading: false
                });

                if (this.props.participantNumber) {
                    this.getParticipant(this.props.participantNumber);
                }
            }
        } catch (e: any) {

        }
    }

    handleSearchFieldChange = async (evt: ChangeEvent<HTMLInputElement>) => {
        var newValue = evt.currentTarget.value;
        if (newValue.length < 10 || !this.getParticipant(evt.currentTarget.value)) {
            this.setState({
                participant: {
                    participantNumber: newValue
                }
            })
        }
    }

    getParticipant = async (participantNumber: string): Promise<boolean> => {

        var result = undefined;
        try {
            if (participantNumber.length == 10) {
                result = await this.apiClient.api.eventsParticipantsWithResultsDetail(participantNumber, this.props.eventId ?? "");
            }
        } catch (e) {
        }
        if (result && result.ok) {
            this.setState({participant: result.data});
        } else {
            return false;
        }

        return true;
    }

    getShooterInfo() {
        return <div>
            <div className={"row"}>
                <div className="form-group col-md-6 mb-2">
                    <label htmlFor="shooternumber">{localizedTexts.labelBarcode}</label>
                    <input type="text" value={this.state.participant?.participantNumber ?? ""} name="shooternumber"
                           onChange={this.handleSearchFieldChange} className="form-control"
                           id="shooternumber" placeholder="" aria-describedby="shooternumber"
                           autoFocus={true}/>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-6"}>
                    <h3>{localizedTexts.labelShooter}</h3>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelPersonNumber}: </label>
                </div>
                <div className={"col-md-6"}>
                    <span> {this.state.participant?.person?.personNumber}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelLicenseNumber}: </label>
                </div>
                <div className={"col-md-6"}>
                    <span> {this.state.participant?.person?.licenseNumber}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelLastName}: </label>
                </div>
                <div className={"col-md-6"}>
                    <span> {this.state.participant?.person?.lastName}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelFirstName}: </label>
                </div>
                <div className={"col-md-6"}>
                    <span> {this.state.participant?.person?.firstName}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelCity}: </label>
                </div>
                <div className={"col-md-8"}>
                    <span> {this.state.participant?.person?.city}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelBirthdate}: </label>
                </div>
                <div className={"col-md-8"}>
                    <span>{this.state.participant?.person?.birthday? new Date(this.state.participant.person.birthday).toLocaleDateString() : ""} </span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelOrganization}: </label>
                </div>
                <div className={"col-md-8"}>
                    <span> {this.state.participant?.organization?.name} {this.state.participant?.organization?.city}</span>
                </div>
            </div>
        </div>
    }

    getShootingPrograms() {
        return <div>
            <table className={"table table-striped"}>
                <tbody>
                {
                    this.state.participant?.exercises
                        ?.filter(s => !this.state.shootingProgramDefinitions[`${s.shootingProgramDefinitionId}`]?.isCalculated)
                        ?.sort((a, b) => this.state.shootingProgramDefinitions[`${a.shootingProgramDefinitionId}`]?.visualOrder! - this.state.shootingProgramDefinitions[`${b.shootingProgramDefinitionId}`]?.visualOrder!)
                        .map((e) => {
                            var shootingProgramDefinition: ShootingProgramDefinition = this.state.shootingProgramDefinitions[`${e.shootingProgramDefinitionId}`];
                            var maxResult = 0;
                            shootingProgramDefinition.shootingProgramSequences?.forEach(s => {
                                if (s.firekindId! > 19) {
                                    maxResult += s.shotCount! * s.maxShotvalue!;
                                }
                            })


                            return <tr key={e.id} style={{verticalAlign: "middle"}}>
                                <td style={{backgroundColor: shootingProgramDefinition.color?? ""}}>
                                    {shootingProgramDefinition.name}
                                </td>
                                <td style={{backgroundColor: shootingProgramDefinition.color?? ""}}>
                                    {
                                        shootingProgramDefinition.shotsRequired
                                            ? <button className={"btn btn-outline-primary"} data-bs-toggle="modal"
                                                      data-bs-target="#enterShotsModal" onClick={() => {
                                                this.setState({selectedExerciseId: e.id});

                                            }}>{localizedTexts.buttonEnterResult}</button>
                                            : ""
                                    }
                                    {
                                        shootingProgramDefinition.sequencesRequired
                                            ? <button className={"btn btn-outline-primary"} data-bs-toggle="modal"
                                                      data-bs-target="#enterSequencesModal" onClick={() => {
                                                this.setState({selectedExerciseId: e.id});

                                            }}>{localizedTexts.buttonEnterResult}</button>
                                            : ""
                                    }
                                </td>
                                <td style={{backgroundColor: shootingProgramDefinition.color?? ""}}>
                                    {
                                        shootingProgramDefinition.isBestShotRequired ? <div><label>{localizedTexts.labelBestShot}:&nbsp;</label>
                                            <input
                                                type={"number"}
                                                disabled={shootingProgramDefinition.shotsRequired || shootingProgramDefinition.sequencesRequired}
                                                value={e.bestShotValue ?? 0}
                                                className={"form-field"}
                                                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                                    var participant = this.state.participant;
                                                    var toUpdateExercise = participant?.exercises?.find(pe => pe.id == e.id)!;
                                                    if (evt.currentTarget.valueAsNumber <= 100) {
                                                        toUpdateExercise.bestShotValue = evt.currentTarget.valueAsNumber;
                                                    }
                                                    this.setState({participant: participant});
                                                }}
                                            /></div> : <div/>
                                    }
                                    {
                                        shootingProgramDefinition.innerTenCountRequired ? <div><label>{localizedTexts.labelInnerTenCount}:&nbsp;</label>
                                            <input
                                                type={"number"}
                                                disabled={shootingProgramDefinition.shotsRequired || shootingProgramDefinition.sequencesRequired}
                                                value={e.innerTenCount ?? 0}
                                                className={"form-field"}
                                                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                                    var participant = this.state.participant;
                                                    var toUpdateExercise = participant?.exercises?.find(pe => pe.id == e.id)!;
                                                    if (evt.currentTarget.valueAsNumber <= 100) {
                                                        toUpdateExercise.innerTenCount = evt.currentTarget.valueAsNumber;
                                                    }
                                                    this.setState({participant: participant});
                                                }}
                                            /></div> : <div/>
                                    }
                                </td>
                                <td style={{backgroundColor: shootingProgramDefinition.color?? ""}}>
                                    <label>{localizedTexts.labelResult}:&nbsp;</label>
                                    <input
                                        type={"number"}
                                        disabled={shootingProgramDefinition.shotsRequired || shootingProgramDefinition.sequencesRequired}
                                        value={e.result ?? 0}
                                        max={maxResult}
                                        className={"form-field"}
                                        onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                            var participant = this.state.participant;
                                            var toUpdateExercise = participant?.exercises?.find(pe => pe.id == e.id)!;
                                            var shotValue = evt.currentTarget.valueAsNumber;
                                            if (shotValue <= maxResult) {
                                                toUpdateExercise.result = evt.currentTarget.valueAsNumber;
                                            }
                                            this.setState({participant: participant});
                                        }}/>
                                </td>
                            </tr>
                        })
                }
                </tbody>
            </table>
        </div>
    }

    handleSaveButton = async () => {
        if (this.state.participant) {
            var participant = this.state.participant;
            participant.person?.organizations?.forEach(o => o.discipline = undefined);
            try {
                var saveResult = await this.apiClient.api.eventsParticipantsUpdateresultsUpdate(this.props.eventId ?? "", participant)
                if (saveResult.ok) {
                    this.handleNextShooter();
                }
            } catch (e: any) {

            }
        }
    }

    handleNextShooter = () => {
        this.setState({selectedExerciseId: undefined, participant: undefined});
        this.reFocusShooterNumber();
    }

    handleSaveAndChangeToSettlement = async () => {
        if (this.state.participant) {
            var participant = this.state.participant;
            participant.person?.organizations?.forEach(o => o.discipline = undefined);
            try {
                var saveResult = await this.apiClient.api.eventsParticipantsUpdateresultsUpdate(this.props.eventId ?? "", participant)
                if (saveResult.ok) {
                    window.location.href=`/event/${this.props.eventId}/participants/settlement/${participant.participantNumber}`;
                }
            } catch (e: any) {

            }
        }
    }

    reFocusShooterNumber = () => {
        document.getElementById("shooternumber")?.focus();
    }

    render() {
        if (this.state.isLoading) {
            return <div>
                <h1>{localizedTexts.title}</h1>
                <h4>{localizedTexts.textSiteLoading}</h4>
            </div>
        }

        return <div>
            <h1>{localizedTexts.title}</h1>
            <div className={"row p-3 g-3 mt-1 shadow bg-body rounded"}>
                <div className={"col-md-6"}>
                    {this.getShooterInfo()}
                </div>
                <div className={"col-md-6"}>
                    {this.getShootingPrograms()}
                </div>

                <div className={"col-md-3"}>
                    <button className={"btn btn-outline-dark registrationButton"} style={{width: "90%"}}
                            onClick={() => this.handleSaveButton()}>{localizedTexts.buttonSave}</button>
                </div>
                <div className={"col-md-3"}>
                    <button className={"btn btn-outline-primary registrationButton"} style={{width: "90%"}}
                            onClick={() => this.handleNextShooter()}>{localizedTexts.buttonNextParticipant}</button>
                </div>
                <div className={"col-md-3"}>
                    <button className={"btn btn-outline-primary registrationButton"} style={{width: "90%"}}
                            onClick={() => this.handleSaveAndChangeToSettlement()}>{localizedTexts.buttonChangeToSettlement}</button>
                </div>
            </div>
            {this.getShotEntryModal()}
            {this.getSequenceEntryModal()}
        </div>
    }

    getShotEntryModal = () => {
        var exercise = this.state.participant?.exercises?.find(e => e.id == this.state.selectedExerciseId);
        return <div className="modal fade modal-l" id="enterShotsModal" tabIndex={-1}
                    aria-labelledby="enterShotsModal"
                    aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={"enterShotsModalLabel"}>{localizedTexts.labelEnterShotsModalTitle}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <table className={"table table-striped table-hover table-responsive-sm"}>
                            <thead>
                            <tr>
                                <td>SchussNr.</td>
                                <td></td>
                                <td>Schusswert</td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.getShotRowsForModal(exercise)}
                            <tr>
                                <td colSpan={3}  style={{height: "10px", border: "none", padding: "0", verticalAlign: "middle"}}>
                                    <div style={{width: "100%", height: "2px", backgroundColor: "black"}}></div>
                                </td>
                            </tr>
                            {this.getResultRowForModal(exercise, false)}
                            <tr>
                                <td colSpan={3} style={{height: "10px", border: "none", padding: "0", verticalAlign: "middle"}}>
                                    <div style={{width: "100%", height: "2px", backgroundColor: "black"}}></div>
                                </td>
                            </tr>
                            {this.getBestShotInput(exercise)}
                            {this.getInnerTenCountInput(exercise)}
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer d-flex justify-content-between align-items-center" style={{ backgroundColor: this.state.shootingProgramDefinitions[`${exercise?.shootingProgramDefinitionId}`]?.color}}>
                        <span>{localizedTexts.labelExercise}: {this.state.shootingProgramDefinitions[`${exercise?.shootingProgramDefinitionId}`]?.name}</span>
                        <button type="button" className="btn btn-primary"
                                data-bs-dismiss="modal">{localizedTexts.buttonSaveModal}</button>
                    </div>
                </div>
            </div>
        </div>
    }

    getSequenceEntryModal = () => {
        var exercise = this.state.participant?.exercises?.find(e => e.id == this.state.selectedExerciseId);
        return <div className="modal fade modal-md" id="enterSequencesModal" tabIndex={-1}
                    aria-labelledby="enterSequencesModal"
                    aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={"enterSequencesModalLabel"}>{localizedTexts.labelEnterSequencesModalTitle}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <table className={"table table-striped table-hover table-responsive-sm"}>
                            <tbody>
                            {this.getSequenceRowsForModal(exercise)}
                            <tr>
                                <td colSpan={3} style={{height: "10px", border: "none", padding: "0", verticalAlign: "middle"}}>
                                    <div style={{width: "100%", height: "2px", backgroundColor: "black"}}></div>
                                </td>
                            </tr>
                            {this.getResultRowForModal(exercise, true)}
                            <tr>
                                <td colSpan={3}>
                                    <hr/>
                                </td>
                            </tr>
                            {this.getBestShotInput(exercise)}
                            {this.getInnerTenCountInput(exercise)}
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary"
                                data-bs-dismiss="modal">{localizedTexts.buttonSaveModal}</button>
                    </div>
                </div>
            </div>
        </div>
    }

    getBestShotInput(exercise: Exercise | undefined) {

        if (!exercise || !this.state.shootingProgramDefinitions[`${exercise.shootingProgramDefinitionId}`].isBestShotRequired) {
            return <tr></tr>
        }

        var participant = this.state.participant;
        if (participant) {
            return <tr>
                <td><label>{localizedTexts.labelBestShot}:&nbsp;</label></td>
                <td><input
                    type={"number"}
                    value={exercise.bestShotValue ?? 0}
                    min={0}
                    max={100}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                        var toUpdateExercise = participant!.exercises!.find(e => e.id == exercise.id)!;
                        if (evt.currentTarget.valueAsNumber <= 100) {
                            toUpdateExercise.bestShotValue = evt.currentTarget.valueAsNumber;
                        }
                        this.setState({participant: participant});
                    }}
                    className={"form-field"}/></td>
            </tr>
        }

        return <tr></tr>
    }

    getInnerTenCountInput(exercise: Exercise | undefined) {

        if (!exercise || !this.state.shootingProgramDefinitions[`${exercise.shootingProgramDefinitionId}`].innerTenCountRequired) {
            return <tr></tr>
        }

        var participant = this.state.participant;
        if (participant) {
            return <tr>
                <td><label>{localizedTexts.labelInnerTenCount}:&nbsp;</label></td>
                <td><input
                    type={"number"}
                    value={exercise.innerTenCount ?? 0}
                    min={0}
                    max={100}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                        var toUpdateExercise = participant!.exercises!.find(e => e.id == exercise.id)!;
                        if (evt.currentTarget.valueAsNumber <= 100) {
                            toUpdateExercise.innerTenCount = evt.currentTarget.valueAsNumber;
                        }
                        this.setState({participant: participant});
                    }}
                    className={"form-field"}/></td>
            </tr>
        }

        return <tr></tr>
    }

    getSequenceRowsForModal(exercise: Exercise | undefined) {
        if (!exercise) {
            return <div>
            </div>
        }
        var participant = this.state.participant;
        var shootingProgramSequences: ShootingProgramSequence[] = this.state.shootingProgramDefinitions[`${exercise.shootingProgramDefinitionId}`]!.shootingProgramSequences!.sort((a: ShootingProgramSequence, b: ShootingProgramSequence) => a.order! - b.order!);

        if (participant) {
            var toUpdateExercise = participant!.exercises!.find(e => e.id == exercise.id)!;

            if (!exercise.sequences) {
                exercise.sequences = [];
            }

            if (exercise.sequences.length == 0) {
                var currentDate = new Date();
                shootingProgramSequences.sort(sps => sps.order!).forEach((sps: ShootingProgramSequence) => {

                    exercise.sequences?.push({
                        sequenceNumber: sps.order,
                        sequenceResult: 0,
                        isPractice: sps.firekindId == 10,
                        date: `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`,
                        time: `${currentDate.getHours().toString().padStart(2, "0")}:${currentDate.getMinutes().toString().padStart(2, "0")}:${currentDate.getSeconds().toString().padStart(2, "0")}`
                    });
                })
            }

            return exercise.sequences?.filter(s => s.isPractice == false).map((s, idx) => {
                var firekindText = localizedTexts.labelTrainingShots;
                var firekindSequenceNumber = idx + 1;
                var maxShotValue = 10;
                var currentShotCount = 0;
                shootingProgramSequences.filter(s => s.firekindId! >= 20).sort(sps => sps.order!).map((s: ShootingProgramSequence, seqIdx) => {

                    if (seqIdx == idx) {
                        currentShotCount = s.shotCount ?? 10;
                        maxShotValue = (s.maxShotvalue ?? 100) * (s.shotCount ?? 10);
                        switch (s.firekind) {
                            case "Sighters":
                                firekindText = localizedTexts.labelTrainingShotsSequence;
                                break;
                            case "ShotByShot":
                                firekindText = localizedTexts.labelSingleShotsSequence;
                                break;
                            case "Series":
                                firekindText = localizedTexts.labelSeriesShotsSequence;
                                break;
                        }
                    }

                });

                return <tr key={exercise.id + `${idx}`}>
                    <td>{firekindSequenceNumber}.{localizedTexts.labelSequence} ({currentShotCount}{firekindText}:</td>
                    <td><input
                        id={"seqinput" + `${idx}`}
                        type={"number"}
                        style={{textAlign: "right", paddingRight: "8px"}}
                        value={s.sequenceResult ?? 0}
                        min={0}
                        max={maxShotValue}//should be updated to proper value
                        onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                            if (maxShotValue >= evt.currentTarget.valueAsNumber) {
                                toUpdateExercise.sequences!.filter(s => s.isPractice == false)[idx].sequenceResult = evt.currentTarget.valueAsNumber;
                            }

                            var arrayIndex = 0;
                            var result: number = 0;
                            shootingProgramSequences.sort((a: ShootingProgramSequence, b: ShootingProgramSequence) => a.order! - b.order!).forEach((s: ShootingProgramSequence) => {

                                if (s.firekindId && s.firekindId >= 20) {
                                    result += toUpdateExercise.sequences!.filter(s => s.isPractice == false)[arrayIndex].sequenceResult ?? 0;
                                    ++arrayIndex;
                                }

                            })
                            toUpdateExercise.result = result;
                            this.setState({participant: participant});
                        }}/></td>
                </tr>
            })

        }
        return <div></div>
    }

    getShotRowsForModal(exercise: Exercise | undefined) {
        if (!exercise) {
            return <div>
            </div>
        }
        var participant = this.state.participant;
        var shootingProgramSequences: ShootingProgramSequence[] = this.state.shootingProgramDefinitions[`${exercise.shootingProgramDefinitionId}`]!.shootingProgramSequences!.sort((a: ShootingProgramSequence, b: ShootingProgramSequence) => a.order! - b.order!);

        if (participant) {
            var toUpdateExercise = participant!.exercises!.find(e => e.id == exercise.id)!;

            if (!exercise.shots) {
                exercise.shots = [];
            }

            if (exercise.shots.length == 0) {
                var currentDate = new Date();
                shootingProgramSequences.filter(sq => sq.firekindId! >= 20).sort(sps => sps.order!).forEach(sps => {
                    Array.from({length: sps.shotCount!}, (value, index) => index + 1).forEach((s) => {
                        exercise.shots?.push({
                            shotNumber: s,
                            value: 0,
                            isIllegalShot: false,
                            isDemo: false,
                            isPractice: sps.firekindId == 10,
                            date: `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`,
                            time: `${currentDate.getHours().toString().padStart(2, "0")}:${currentDate.getMinutes().toString().padStart(2, "0")}:${currentDate.getSeconds().toString().padStart(2, "0")}`
                        });
                    })
                })
            }

            let lastFireKind = "";
            let lastSeriesCounter = 1;
            let currentSeriesShotCount = 0; // Tracks how many shots are fired within the current series
            let currentSeriesMaxShots = 0; // Tracks the required shots for the current series
            let seriesCounter = 1; // Tracks the s
            let currentRenderedSeriesCounter = 1;

            return exercise.shots?.filter(s => s.isPractice == false).map((s, idx) => {
                var firekindText = localizedTexts.labelTrainingShots;
                var firekindShotNumber = idx + 1;
                var valueSet = false;
                var maxShotValue = 10;

                // Find the sequence this shot corresponds to
                shootingProgramSequences.filter(sq => sq.firekindId! >= 20).forEach((s: ShootingProgramSequence, sequenceIndex) => {
                    if (firekindShotNumber - s.shotCount! <= 0 && !valueSet) {
                        maxShotValue = s.maxShotvalue ?? 100;

                        switch (s.firekind) {
                            case "Sighters":
                                firekindText = localizedTexts.labelTrainingShots;
                                break;
                            case "ShotByShot":
                                firekindText = localizedTexts.labelSingleShots;
                                break;
                            case "Series":
                                firekindText = localizedTexts.labelSeriesShots;

                                // Initialize series information only when processing "Series"
                                if (firekindText === localizedTexts.labelSeriesShots && currentSeriesMaxShots === 0) {
                                    currentSeriesMaxShots = s.shotCount!;
                                }

                                break;
                        }

                        valueSet = true;
                    } else if (firekindShotNumber > s.shotCount! && !valueSet) {
                        firekindShotNumber = firekindShotNumber - s.shotCount!;
                    }
                });

                // Handle "Series" logic
                if (firekindText === localizedTexts.labelSeriesShots) {
                    // Increment shot count for the current series
                    currentSeriesShotCount++;
                    firekindShotNumber = currentSeriesShotCount;

                    // before incrementing series counter, we use a temporary variable to render the shot
                    currentRenderedSeriesCounter = seriesCounter;

                    // Check if this is the last shot in the series
                    if (currentSeriesShotCount === currentSeriesMaxShots) {
                        currentSeriesShotCount = 0;
                        currentSeriesMaxShots = 0;
                        seriesCounter++;
                    }
                }


                const shouldInsertSpacer = (lastFireKind !== firekindText && idx + 1 != 1) || (lastSeriesCounter != currentRenderedSeriesCounter);
                lastFireKind = firekindText ?? "";
                lastSeriesCounter = currentRenderedSeriesCounter;

                return <React.Fragment  key={exercise.id + `${idx}`}>
                    {/* Spacer row if firekind changes */}
                    {shouldInsertSpacer && (
                        <tr key={`spacer-${exercise.id}-${idx}`}>
                            <td colSpan={3} style={{height: "10px", border: "none", padding: "0", verticalAlign: "middle"}}>
                                <div style={{width: "100%", height: "1px", backgroundColor: "black"}}></div>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td style={{ textAlign: "right", width: "60px" }}>
                            {idx + 1}
                        </td>
                        <td> {firekindText === localizedTexts.labelSeriesShots ? `${currentRenderedSeriesCounter}. ${firekindText}` : firekindText}:</td>
                        <td><input
                            id={"shotinput" + `${idx}`}
                            type={"number"}
                            value={s.value ?? 0}
                            step={1}
                            min={0}
                            max={maxShotValue}//should be updated to proper value
                            style={{textAlign: "right", paddingRight: "8px"}}
                            onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                /* Check if the string is a valid integer */
                                let currentValue = 0
                                const rawValue = evt.currentTarget.value;
                                const intValue = rawValue ? Math.floor(Number(rawValue)) : 0; // Force to integer

//                                const intValue = parseInt(rawValue, 10);
                                if (!isNaN(intValue)) {
                                    currentValue = intValue;
                                }
                                else
                                {
                                    //currentValue = evt.currentTarget.valueAsNumber
                                }

                                let isDecimal = false;
                                if (maxShotValue >= currentValue) {
                                    toUpdateExercise.shots!.filter(s => s.isPractice == false)[idx].value = currentValue;
                                }
                                else if ((currentValue / maxShotValue) < maxShotValue && isDecimal)
                                {
                                    toUpdateExercise.shots!.filter(s => s.isPractice == false)[idx].value = currentValue / maxShotValue;
                                }

                                let arrayIndex = 0;
                                let result: number = 0;
                                shootingProgramSequences.sort((a: ShootingProgramSequence, b: ShootingProgramSequence) => a.order! - b.order!).forEach((s: ShootingProgramSequence) => {
                                    for (let i = 0; i < s.shotCount!; i++) {
                                        if (s.firekindId && s.firekindId >= 20) {
                                            result += toUpdateExercise.shots!.filter(s => s.isPractice == false)[arrayIndex].value ?? 0;
                                            ++arrayIndex;
                                        }
                                    }
                                })
                                toUpdateExercise.result = result;
                                this.setState({participant: participant});
                            }}/>
                        </td>
                    </tr>

                </React.Fragment>
            })

        }

        return <div></div>
    }

    getResultRowForModal(exercise: Exercise | undefined, isPassenMode:boolean) {
        if (!exercise) {
            return <div>
            </div>
        }

        return <tr>
            {!isPassenMode ?
                <td></td> : ""
            }
            <td>
                <label>{localizedTexts.labelResult}</label>
            </td>
            <td>
                <input
                    key={"modalresult"}
                    type={"number"}
                    style={{textAlign: "right", paddingRight: "3px"}}
                    min={0}
                    max={exercise.result ?? 100}
                    value={exercise.result ?? 0}
                    disabled={true}
                    className={"form-field"}
                />
            </td>
        </tr>

        }
    }
    interface EventParticipantsResultState {
    eventRegistrationInformation: EventRegistratioInformation | undefined;
    participant: ParticipantWithResults | undefined;
    allWeapons: any;
    shootingProgramDefinitions: any;
    selectedExerciseId: number | undefined;
    isLoading: boolean;
}

interface EventParticipantsResultProps {
    eventId: string | undefined;
    participantNumber: string | undefined;
}

export default EventParticipantsResultWrapper;