import {Component} from "react";
import {useParams} from "react-router";
import {EventInfo, EventStatistics} from "./models/Models";
import ApiClientFactory from "./models/ApiClientFactory";
import localizedTexts from "./texts/EventDetailView.texts";

function EventDetailViewWrapper(){
    let {eventId} = useParams();
    return <EventDetailView eventId={eventId} />
}

class EventDetailView extends Component<EventDetailViewProps, EventDetailViewState>{
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: EventDetailViewProps) {
        super(props);

        this.state = {
            eventInfos: undefined
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData(){
        try {
            var eventInfo = await this.apiClient.api.eventsEventInformationDetail(`${this.props.eventId}`);
            if (eventInfo.ok){
                this.setState({eventInfos: eventInfo.data});
            }
        }catch (e:any){

        }
    }


    render(){
        return <div>
            <h1>{localizedTexts.title}</h1>
            <h2>{this.state.eventInfos?.name}</h2>
            <table className={"table table-striped table-responsive"}>
                <tbody>
                <tr>
                    <td>{localizedTexts.labelName}</td>
                    <td>{this.state.eventInfos?.name}</td>
                </tr>
                <tr>
                    <td>{localizedTexts.labelYear}</td>
                    <td>{this.state.eventInfos?.eventYear}</td>
                </tr>
                <tr>
                    <td>{localizedTexts.labelShortName}</td>
                    <td>{this.state.eventInfos?.shortName}</td>
                </tr>
                <tr>
                    <td>{localizedTexts.labelOrganizer}</td>
                    <td>{this.state.eventInfos?.eventOrganizer}</td>
                </tr>
                <tr>
                    <td>{localizedTexts.labelLocation}</td>
                    <td>{this.state.eventInfos?.eventLocation}</td>
                </tr>
                <tr>
                    <td>{localizedTexts.labelParticipantCount}</td>
                    <td>{this.state.eventInfos?.participantCount}</td>
                </tr>
                </tbody>
            </table>
        </div>
    }


}

interface EventDetailViewState {
    eventInfos: EventInfo | undefined;
}

interface EventDetailViewProps {
    eventId: string | undefined;
}

export default EventDetailViewWrapper;