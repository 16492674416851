import localizatedTexts from "./texts/standard.texts";
import localizatedReportTexts from "./texts/report.texts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ApiClientFactory from "../../models/ApiClientFactory";
import React, {useEffect, useState} from "react";
import {ShootingRange, ShotAccountingOrganization} from "../../models/Models";
import PrintDocument from "../../../common/Helpers/PrintDocument";
import {useParams} from "react-router";

function ReportWrapper(){
    const {rangeId, distanceId} = useParams();
    return <Report rangeId={rangeId} distanceId={parseInt(distanceId??"")}/>
}

interface ReportProps {
    rangeId: string | undefined
    distanceId: number
}



const Report: React.FC<ReportProps> = ({ rangeId, distanceId }) => {

    const apiClient = ApiClientFactory.GetApiClient();
    const [shootingRanges , setShootingRanges] = useState<ShootingRange[]>()
    const [selectedShootingRange, setSelectedShootingRange] = useState<string>("")
    const [organizations, setOrganizations] = useState<ShotAccountingOrganization[]>([]);
    const [selectedOrganization , setSelectedOrganization] = useState<number>(0)
    const [selectedShooter , setSelectedShooter] = useState("")
    const [year , setYear] = useState<string>(new Date().getFullYear() - 5+"");
    const [selectedDistance , setSelectedDistance] = useState<number>(0)

    useEffect(() => {
        if(shootingRanges == undefined){
            loadData();
        }
        if(selectedShootingRange === "" && selectedDistance === 0 && rangeId !=="" &&distanceId !== 0) {
            setSelectedDistance(distanceId)
            setSelectedShootingRange(rangeId??"")
        }
        getOrganization();
    }, [selectedDistance, selectedShootingRange])

    function loadData() {
        apiClient.api.coreShootingRangesList().then(data => {
            setShootingRanges(data.data);
            if(data.data.length ===1){
                setSelectedShootingRange(data.data[0].id??"");
            }
        })
    }

    function getDistance() {
        const distanceList = shootingRanges?.find(sr => sr.id === selectedShootingRange)?.shootingRangeDisciplines ?? [];
        if(distanceList.length === 1 && selectedDistance !== distanceList[0].distanceId){
            setSelectedDistance(distanceList[0].distanceId??0);
        }
        return shootingRanges?.find(sr => sr.id === selectedShootingRange)?.shootingRangeDisciplines ?? [];
    }


    function getOrganization() {
        if(selectedShootingRange != "" && selectedDistance != 0){
            apiClient.api.coreOrganizationDetail(selectedShootingRange, selectedDistance).then(data => {
                setOrganizations(data.data);
            })
        }else{
            setOrganizations([]);
        }
    }
const getReport = async()=>{
        if(year !== "0" && selectedShootingRange !== "" && selectedDistance !== 0 && selectedOrganization !== 0){
            const result = await apiClient.api.coreShotAccountingDetailedcommunityreportDetail(selectedShootingRange,selectedDistance, parseInt(year), selectedOrganization)
            if(result.ok){
                await PrintDocument.printPdfDocument(result);
            }
        }else if(year !== "0" && selectedShootingRange !== "" && selectedDistance !== 0){
            const result = await apiClient.api.coreShotAccountingCommunityreportDetail(selectedShootingRange,selectedDistance, parseInt(year))
            if(result.ok){
                await PrintDocument.printPdfDocument(result);
            }
        }
}



    return <div><h1>{localizatedTexts.report}</h1>
        <div className={"container-fluid "}>
            <div className={"row"}>
                <div className={"col-md-10 shadow rounded p-4 m-4 mt-2"}>
                    <div>
                        <h2>{localizatedReportTexts.filter}</h2>
                        {shootingRanges?.length != undefined && shootingRanges.length > 1 ?
                            <div className={"input-group mb-3 m-1"}>
                                <span className={"input-group-text"}>{localizatedTexts.shootingrange}</span>
                                <select disabled={true} value={selectedShootingRange} className={"form-select"} onChange={data => {
                                    setSelectedShootingRange(data.currentTarget.value);
                                    setSelectedDistance(0)

                                    const selectElement = document.getElementById("distanceSelector") as HTMLSelectElement;
                                    if (selectElement) {
                                        selectElement.value = ""; // Zurücksetzen auf den übergebenen Standardwert
                                    }
                                }}>
                                    <option value={""}></option>
                                    {shootingRanges.sort((a,b)=> a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()??"") as number).map((range) => {
                                        return <option value={range.id || 0}>{range.name}</option>
                                    })
                                    }
                                    *                       </select>
                            </div> : <div>{localizatedTexts.shootingrange} : {shootingRanges?.map(sr => {
                                return <h1>{sr.name}</h1>
                            })}</div>}
                        {selectedShootingRange != "" && getDistance().length > 1?
                            <div className={"input-group mb-3 m-1"}>
                                <span className={"input-group-text "}>{localizatedReportTexts.discipline}</span>
                                <select disabled={true} value={selectedDistance} id={"distanceSelector"} className={"form-select col-10"} onChange={data => {setSelectedDistance(parseInt(data.currentTarget.value))}}>
                                    <option value={0}></option>
                                    { getDistance()?.map((ds) => {
                                        return <option value={ds.distanceId || 0}>{ds.disciplineName}</option>
                                    })
                                    }
                                </select>
                            </div> :  getDistance().length === 1 ? <div className={"input-group mb-3 m-1"}><span className={"input-group-text "}>{localizatedReportTexts.discipline}</span>  {getDistance()?.map(dc => {
                                return <input type={"text"} className={"form-control"} disabled={true} value={dc.disciplineName ??""}></input>
                            })}</div> : ""}

                        {organizations.length !== 0 ?
                            <div className={"input-group mb-3 m-1"}>
                                <span className={"input-group-text"}>{localizatedTexts.organization}</span>
                                <select value={selectedOrganization} className={"form-select"} onChange={(e) => setSelectedOrganization(parseInt(e.target.value))}>
                                    <option value={""}>{localizatedReportTexts.allOrganization}</option>
                                    {organizations.map((org) => {
                                        return <option value={org.id?? ""}>{org.name}</option>
                                    })}
                                </select>
                            </div> :""
                        }
                        { selectedShootingRange !== "" && selectedDistance !== 0 && organizations.length !== 0?
                            <>
                                {/*
                            <div className={"input-group mb-3 m-1"}>
                                <span className={"input-group-text"}>{localizatedTexts.shooter}</span>
                                <select className={"form-select"} onChange={(e) => setSelectedShooter(e.target.value)}>
                                    <option value={""}>{localizatedReportTexts.allShooter}</option>
                            {organizations.find(org => org.number === selectedOrganization)?.persons?.sort((a,b) => a.lastName?.toLowerCase().localeCompare(b.lastName?.toLowerCase() ??"") as number).map(per => {
                                return <option value={per.shooterNumber?? ""}>{per.firstName} {per.lastName}</option>

                            })}
                                </select>
                            </div>
                            <div className={"input-group mb3 m-1"}>
                                <span className={"input-group-text"}>{localizatedReportTexts.startDate}</span>
                                <select className={"form-select"} onChange={(e) => setYear(e.currentTarget.value)}>
                                    {[...Array(6)].map((_, index) => {
                                        const year = new Date().getFullYear() - 5 + index;
                                        return <option key={year} value={year}>{year}</option>;
                                    })}

                                </select>
                            </div> */}

                            <button className={"btn btn-primary m-1"} onClick={() => getReport()}>
                        {localizatedReportTexts.generateReport}
                    </button> </>: ""
                        }

                    </div>
                </div>

                {/*
                <div className={"col-md-10 shadow rounded p-4 m-4 mt-2"}>
                    <h2>{localizatedReportTexts.result}</h2>
                    <table className={"table table-striped table-hover table-bordered table-sm"}>
                        <thead>
                        <tr>
                            <th>{localizatedTexts.organization}</th>
                            <th>{localizatedTexts.shooter}</th>
                            <th>Schüsse verechnet</th>
                            <th>Schüsse nicht verrechnet</th>
                        </tr>
                        </thead>
                        <tbody className={"table-hover"}>
                        <tr className="table-secondary">
                            <td>Verein 1</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Schütze 1</td>
                            <td>2000</td>
                            <td>5000</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Schütze 2</td>
                            <td>2000</td>
                            <td>5000</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Schütze 3</td>
                            <td>2000</td>
                            <td>5000</td>
                        </tr>
                        <tr className="table-secondary">
                            <td>Verein 1</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Schütze 1</td>
                            <td>2000</td>
                            <td>5000</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Schütze 2</td>
                            <td>2000</td>
                            <td>5000</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Schütze 3</td>
                            <td>2000</td>
                            <td>5000</td>
                        </tr>
                        <tr className="table-secondary">
                            <td>Verein 1</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Schütze 1</td>
                            <td>2000</td>
                            <td>5000</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Schütze 2</td>
                            <td>2000</td>
                            <td>5000</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Schütze 3</td>
                            <td>2000</td>
                            <td>5000</td>
                        </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className="page-item"><a className="page-link" href="#">{localizatedTexts.paginatinoPrevious}</a></li>
                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item"><a className="page-link" href="#">{localizatedTexts.paginationNext}</a></li>
                        </ul>
                    </nav>
                </div>
                */}
            </div>
        </div>
    </div>
}

export default ReportWrapper;