import React, { useState } from "react";
import localizedTexts from "../texts/EventV2PaymentFileUploadModal.texts";

interface PaymentFileUploadModalProps {
  onUpload: (file: File) => Promise<void>;
  onClose: () => void;
}

const PaymentFileUploadModal: React.FC<PaymentFileUploadModalProps> = ({onUpload, onClose }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<string>("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setSelectedFile(file);
    setUploadStatus(""); // Reset status when a new file is selected
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setUploadStatus(localizedTexts.labelNoFileSelected!);
      return;
    }

    try {
      await onUpload(selectedFile); // Invoke the upload handler passed as props
      setUploadStatus(localizedTexts.labelFileUploadSuccessful!);
    } catch (error) {
      setUploadStatus(localizedTexts.labelFileUploadFailed!);
    }
  };

  return (
    <div className="modal fade"
         id="paymentFileUploadModal"
         tabIndex={-1}
         aria-labelledby="paymentFileUploadModalLabel"
         aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">

          <div className="modal-header">
            <h5 className="modal-title" id="paymentFileUploadModalLabel">{localizedTexts.title}</h5>
            <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="fileInput" className="form-label">
                {localizedTexts.textSelectFile}
              </label>
              <input
                type="file"
                className="form-control"
                id="fileInput"
                accept=".camt054,.xml"
                onChange={handleFileChange}
              />
            </div>
            <button className="btn btn-primary" onClick={handleUpload}>
              {localizedTexts.buttonUpload}
            </button>
          </div>

          <div className="modal-footer">
            <div className="me-auto text-secondary">{uploadStatus}</div>
            <button className="btn btn-secondary" data-bs-dismiss="modal" onClick={onClose}>
              {localizedTexts.buttonClose}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentFileUploadModal;