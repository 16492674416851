import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface FilterableDatePickerProps {
    allowedDateList: Date[];
    onDateChange: (date: string) => void
    value: string | undefined;
}

const FilterableDatePicker : React.FC<FilterableDatePickerProps> = ({ allowedDateList, onDateChange, value }) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    useEffect(() => {
        if (value) {
            // Parse the string value (e.g., "yyyy-mm-dd") into a Date object
            setSelectedDate(new Date(value));
        } else {
            setSelectedDate(null); // Clear the selected date if no value is provided
        }
    }, [value]);


    const isDateAllowed = (date: Date) => {
        return allowedDateList.some(
            (allowedDate : Date) =>
                date.getDate() === allowedDate.getDate() &&
                date.getMonth() === allowedDate.getMonth() &&
                date.getFullYear() === allowedDate.getFullYear()
        );
    };

    return (
        <div className="col-auto">
            <div className="position-relative" style={{width: "fit-content"}}>
                <DatePicker
                    selected={selectedDate}
                    onChange={(date) => {
                        onDateChange(date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
                            .toISOString()
                            .split("T")[0] : "");
                        setSelectedDate(date)
                    }}
                    filterDate={isDateAllowed}
                    placeholderText="tt.mm.jjjj"
                    dateFormat="dd.MM.yyyy"
                    className="form-contro  pe-5" // Apply Bootstrap styling to the input and add padding for the icon
                    popperClassName="custom-datepicker-popper"
                />
                {/* Calendar icon inside the input field */}
                <i
                    className="bi bi-calendar position-absolute"
                    style={{
                        top: "50%",       // Center vertically
                        right: "10px",    // Align to the right
                        transform: "translateY(-50%)", // Center precisely
                        pointerEvents: "none", // Make icon non-interactive
                    }}
                ></i>
            </div>
        </div>
    );
};

export default FilterableDatePicker;