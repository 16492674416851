import LocalizedStrings from 'react-localization';

const localizedTexts = new LocalizedStrings({
    "de": {
        "setting": "Einstellungen",
        "Status": "Status",
        "lastConnection": "Letzte Verbindung",
        "connected": "Verbunden",
        "disconnected": "Getrennt",
        "dataSync": "Datensynchronisierung",
        "dataDelete": "Daten löschen",
        "sync": "Synchronisieren",
        "nosync" : "Nicht synchronisiert",
        "synced" : "Synchronisiert",
        "syncError" : "Synchronisierung fehlgeschlagen",
        "syncText" : "Synchronisiert den Datenbestand aus Winfire auf die Schiessanlage. Alle Daten der Anlage werden überschrieben. Winfire ist der Datenmaster",
        "deleteData": "Daten von Anlage löschen",
        "deleteText":"Löscht alle Daten von der Anlage. Vereine und Schützen sind danach nicht mehr auf der Anlage verfügbar",
        "syncWarningText":  "Achtung, bei TG6302 und SA9006: falls bereits Schützen auf der Anlage erfasst sind, werden bestehende gleichnamige Vereine überschrieben."
    },
    "fr": {
        "setting": "Paramètres",
        "Status": "Statut",
        "lastConnection": "Dernière connexion",
        "connected": "Connecté",
        "disconnected": "Déconnecté",
        "dataSync": "Synchronisation des données",
        "dataDelete": "Supprimer les données",
        "sync": "Synchroniser",
        "syncText": "Synchronise les données de Winfire vers le stand de tir. Toutes les données de l'installation seront écrasées. Winfire est le maître des données",
        "deleteData": "Supprimer les données de l'installation",
        "deleteText": "Supprime toutes les données de l'installation. Les associations et les tireurs ne seront plus disponibles sur l'installation",
        "syncWarningText": "Attention, pour TG6302 et SA9006 : si des tireurs sont déjà enregistrés sur le système, les organisations existants portant le même nom seront écrasés."
    },
    "it": {
        "setting": "Impostazioni",
        "Status": "Stato",
        "lastConnection": "Ultima connessione",
        "connected": "Connesso",
        "disconnected": "Disconnesso",
        "dataSync": "Sincronizzazione dati",
        "dataDelete": "Elimina dati",
        "sync": "Sincronizza",
        "syncText": "Sincronizza i dati da Winfire al poligono di tiro. Tutti i dati dell'impianto saranno sovrascritti. Winfire è il master dei dati",
        "deleteData": "Elimina dati dall'impianto",
        "deleteText": "Elimina tutti i dati dall'impianto. Le associazioni e i tiratori non saranno più disponibili sull'impianto",
        "syncWarningText": "Attenzione, per TG6302 e SA9006: se i tiratori sono già registrati sul sistema, i organisation esistenti con lo stesso nome verranno sovrascritti."
    },
    "en": {
        "setting": "Settings",
        "Status": "Status",
        "lastConnection": "Last Connection",
        "connected": "Connected",
        "disconnected": "Disconnected",
        "dataSync": "Data Synchronization",
        "dataDelete": "Delete Data",
        "sync": "Sync",
        "syncText": "Synchronizes data from Winfire to the shooting range. All data on the system will be overwritten. Winfire is the data master",
        "deleteData": "Delete data from system",
        "deleteText": "Deletes all data from the system. Clubs and shooters will no longer be available on the system",
        "syncWarningText": "Warning, for TG6302 and SA9006: if shooters are already recorded on the system, existing organizations with the same name will be overwritten."
    }
})

export default localizedTexts;