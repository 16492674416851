import LocalizedStrings from 'react-localization';

const localizedTexts = new LocalizedStrings({
    "de": {
        "screenList" : "Bildschirme",
    },
    "fr": {
        "screenList": "Écrans"
    },
    "it": {
        "screenList": "Schermi"
    },
    "en": {
        "screenList": "Screens"
    }
})

export default localizedTexts;