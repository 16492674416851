import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Teilnehmer",
        "headerBarcode":"Schützennummer",
        "headerStatus":"Status",
        "headerFirstName":"Vorname",
        "headerLastName":"Nachname",
        "headerCity":"Ort",
        "headerOrganization":"Verein",
        "headerActions":"Aktionen",
        "textSiteLoading": "Seite lädt. Bitte einen moment Geduld haben.",
        "textSaveToDelete":"Sind sie sicher dass sie den Teilnehmer '{name}' entfernen möchten?",
        "labelToSettlement":"Zum Abrechnen",
        "labelToResultEntry":"Zur Resultateingabe",
        "labelToRegistration":"Zum Mutieren",
        "toolTipRegisteredExercises":"Gelöst",
        "toolTipExercisesWithResult":"Mit Resultaten",
    },
    "fr": {
        "title" : "Participant",
        "headerBarcode":"No. du tireur",
        "headerStatus":"Status",
        "headerFirstName":"Prénom",
        "headerLastName":"Nom de famille",
        "headerCity":"Lieu",
        "headerOrganization":"Association",
        "headerActions":"Actions",
        "textSiteLoading": "La page est en cours de chargement. Veuillez patienter un instant.",
        "labelToSettlement":"Vers décompte",
        "labelToResultEntry":"Vers enregistrement des résultats",
        "labelToRegistration":"Vers mutations",
        "toolTipRegisteredExercises":"commandé",
        "toolTipExercisesWithResult":"avec résultats",

    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;