import LocalizedStrings from 'react-localization';

const localizedTexts = new LocalizedStrings({
"de": {
        "screens": "Bildschirme",
        "shootinRange": "Schiessanlage",
        "screenName" : "Bildschirmname",
        "winfFireLive":"WinFire Live",
        "overview": "Übersicht",
    "winfireLiveScreenOverview":"WinFire Live Bildschirm Übersicht",
    "eventMapping": "Anlasszuordnung ",
    "screenContentTypes": "Inhaltstypen des Bildschirms",
    "noScreenAvailable" : "Keine Bildschirme registriert"
    },
    "fr": {
        "screens": "Écrans",
        "shootinRange": "Stand de tir",
        "screenName": "Nom de l'écran",
        "winfFireLive": "WinFire Live",
        "overview": "Vue d'ensemble",
        "winfireLiveScreenOverview": "Vue d'ensemble des écrans WinFire Live",
        "eventMapping": "Association d'événements",
        "screenContentTypes": "Types de contenu de l'écran",
        "noScreenAvailable": "Aucun écran enregistré"
    },
    "it": {
        "screens": "Schermi",
        "shootinRange": "Poligono di tiro",
        "screenName": "Nome dello schermo",
        "winfFireLive": "WinFire Live",
        "overview": "Panoramica",
        "winfireLiveScreenOverview": "Panoramica degli schermi WinFire Live",
        "eventMapping": "Mappatura eventi",
        "screenContentTypes": "Tipi di contenuto dello schermo",
        "noScreenAvailable": "Nessuno schermo registrato"
    },
    "en": {
        "screens": "Screens",
        "shootinRange": "Shooting Range",
        "screenName": "Screen Name",
        "winfFireLive": "WinFire Live",
        "overview": "Overview",
        "winfireLiveScreenOverview": "WinFire Live Screen Overview",
        "eventMapping": "Event Mapping",
        "screenContentTypes": "Screen Content Types",
        "noScreenAvailable": "No screens registered"
}

})
export default localizedTexts;