import LocalizedStrings from 'react-localization';

const localizedTexts = new LocalizedStrings({
    "de": {
        "all": "Alle",
        "filter": "Filter",
        "startDate" : "von",
        "endDate" : "bis",
        "generateReport": "Bericht erstellen",
        "result": "Ergebnis",
        "discipline": "Disziplin",
        "allShooter": "Alle Schützen",
        "allOrganization": "Alle Vereine"
    },
    "fr": {
        "all": "Tous",
        "filter": "Filtre",
        "startDate": "de",
        "endDate": "à",
        "generateReport": "Créer le rapport",
        "result": "Résultat"
    },
    "it": {
        "all": "Tutti",
        "filter": "Filtro",
        "startDate": "da",
        "endDate": "a",
        "generateReport": "Genera rapporto",
        "result": "Risultato"
    },
    "en": {
        "all": "All",
        "filter": "Filter",
        "startDate": "from",
        "endDate": "to",
        "generateReport": "Generate Report",
        "result": "Result"
    }
})
export default localizedTexts;