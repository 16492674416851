import React, {Component} from "react";
import localizedTexts from "./texts/NewEventModal.texts";
import {Api, EventType, NewEvent, NewEventShootingProgramDefinition} from "./models/Models";
import ApiClientFactory from "./models/ApiClientFactory";
import WinFireDropDown, {WinFireDropDownElement} from "../common/components/WinFireDropDown";

enum EventDisplayScreenEnum {
    Load,
    SelectType,
    EventInfo,
    FreeEventDisciplineSelection,
    FreeEventFieldSelection,
    FreeEventShootingProgramDefinition
}

export default class NewEventModal extends Component<NewEventModalProps, NewEventModalState> {
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: NewEventModalProps) {
        super(props);

        this.state = {
            name: "",
            shortname: "",
            eventLocation: "",
            eventOrganizer: "",
            eventType: 100,
            eventInfos: {},
            eventTypes: [],
            eventTemplateNames: [],
            selectedTemplate: "",
            currentDisplay: EventDisplayScreenEnum.Load,
            isLastDisplay: false,
            wizardFreeShootingSelectedField: "",
            selectedShootingProgram: undefined
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        var result = await this.apiClient.api.eventsEventsEventtypesList();
        if (result.ok) {
            this.setState({eventTypes: result.data, currentDisplay: EventDisplayScreenEnum.SelectType});
        }
        var templateResult = await this.apiClient.api.eventsEventsEventinfoTemplatenamesList();
        if (templateResult.ok){
            this.setState({eventTemplateNames: templateResult.data, selectedTemplate: templateResult.data[0]});
        }
    }

    async loadTemplateData(eventInfoType: number) {
        var result = await this.apiClient.api.eventsEventsEventinfoDetail(eventInfoType, this.state.selectedTemplate);
        if (result.ok) {
            this.setState({eventInfos: result.data, currentDisplay: EventDisplayScreenEnum.EventInfo});

            const currentYear = new Date().getFullYear();

            this.setState((prevState) => ({
                eventInfos: {
                    ...prevState.eventInfos,
                    eventYear: currentYear,
                },
            }));
        }
    }


    changeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
        var updateObj: any = this.state.eventInfos;
        updateObj[evt.currentTarget.id] = evt.currentTarget.value;
        this.setState({eventInfos: updateObj});
    }

    saveHandler = async () => {
        if (this.state.eventInfos){
            let saveResult = await this.props.apiClient.api.eventsEventsCreate(this.state.eventInfos);

            if (saveResult.ok){
                alert(localizedTexts.messageSaved);
                this.props.afterSaveUpdate();
            }
        }

        this.setState({eventInfos: undefined, currentDisplay: EventDisplayScreenEnum.SelectType});
    }

    nextHandler = () => {

    }

    render() {
        return <div id={this.props.modalId} className="modal fade modal-xl" tabIndex={-1}
                    aria-labelledby="Create New Event" aria-hidden="true">
            <div className="modal-dialog" role="document">
                {this.getCurrentScreenDisplay()}
            </div>
        </div>
    }

    private getCurrentScreenDisplay() {
        switch (this.state.currentDisplay) {
            case EventDisplayScreenEnum.Load:
                return <div><span className={'spinner-border'}/></div>;
            case EventDisplayScreenEnum.SelectType:
                return this.getEventTypeSelect();
            case EventDisplayScreenEnum.EventInfo:
                return this.getEventDetailScreen();
            case EventDisplayScreenEnum.FreeEventDisciplineSelection:
                return this.getFreeEventDisciplineSelection();
            case EventDisplayScreenEnum.FreeEventFieldSelection:
                return this.getFreeEventFieldSelection();
            case EventDisplayScreenEnum.FreeEventShootingProgramDefinition:
                return this.getShootingProgramDefinitions();
        }
    }

    private getShootingProgramDefinitions(){
        return <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title"
                    id="newEventModalLabel">{localizedTexts.title} - {localizedTexts.wfHeaderEventShootingProgramDefinition}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <div className={"shadow p-2 rounded"}>
                    <div className={"row"}>
                        <div className={"col-2"}>
                            <h3>{localizedTexts.labelDiscipline}</h3>
                            <div className={"list-group"} role={"tablist"}>
                                {
                                    this.state.eventInfos?.disciplines?.map((d, idx) => {
                                        return <a
                                            className={"list-group-item list-group-item-action " + (idx == 0 ? "active" : "")}
                                            data-bs-toggle={"list"} role={"tab"}
                                            href={`#${d.name!.defaultText ?? ""}Fields`} onClick={() => {
                                            this.setState({wizardFreeShootingSelectedField: (d.fields ? d.fields[0].shortName?.defaultText : "") ?? ""})
                                        }}>{d.name!.defaultText}
                                            <button
                                                className={"rounded-circle float-end btn " + (d.isEnabled ? "btn-success" : "btn-danger")}
                                                onClick={(evt) => {
                                                    var eventInfos = this.state.eventInfos;
                                                    var discipline = eventInfos?.disciplines?.find(dis => dis.name == d.name);
                                                    discipline!.isEnabled = !discipline!.isEnabled;
                                                    this.setState({eventInfos: eventInfos});
                                                }}>{d.isEnabled ?
                                                <span className={"bi-dash-circle"}></span> :
                                                <span className={"bi-plus-circle"}></span>}</button>
                                        </a>
                                    })
                                }
                            </div>
                        </div>
                        <div className={"col-10"}>
                            <div className={"tab-content"}>
                                {
                                    this.state.eventInfos?.disciplines?.map((d, idx) => {
                                        return <div
                                            className={"tab-pane fade " + (idx == 0 ? "show active" : "")}
                                            id={`${d.name!.defaultText ?? ""}ShootingPrograms`}
                                            role={"tabpanel"}>
                                            <div className={"row"}>
                                                <div className={"col-12"}>
                                                    <h3>{localizedTexts.labelShootingProgramDefinitions}</h3>
                                                    <div className={"list-group"} role={"tablist"}>
                                                        {
                                                            d.shootingProgramDefinitions && d.shootingProgramDefinitions.length > 0 ? <WinFireDropDown buttonText={this.state.selectedShootingProgram ? this.state.selectedShootingProgram.name!.defaultText!: localizedTexts.labelButtonSelectShootingProgramDefinition??""} elements={d.shootingProgramDefinitions!.map((spd, idx) => {
                                                                return {sourceObject: spd, key: `${spd.visualOrder??0}`, text: spd.name?.defaultText, selectFunction: (a: NewEventShootingProgramDefinition) => {this.setState({selectedShootingProgram: a})}} as WinFireDropDownElement
                                                            })} elementFilter={(a:string, b:NewEventShootingProgramDefinition) => true} /> : <p>Keine Stiche</p>
                                                        }
                                                        {
                                                            this.state.selectedShootingProgram ?
                                                                <div>

                                                                </div> :
                                                                <div></div>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                {
                    this.getFooterButtons(EventDisplayScreenEnum.FreeEventFieldSelection, undefined)
                }
            </div>
        </div>
    }

    private getEventTypeSelect() {
        return <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title"
                    id="newEventModalLabel">{localizedTexts.title} - {localizedTexts.wfHeaderSelectType}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <div className={"shadow p-2 rounded"}>
                    <select value={this.state.selectedTemplate} onChange={(evt) => {this.setState({selectedTemplate: evt.currentTarget.value})}}>
                        {
                            this.state.eventTemplateNames.map(t => {
                                return <option>{t}</option>
                            })
                        }
                    </select>
                    {
                        this.state.eventTypes.map(e => {
                            return <div className={"m-3"}>
                                <button key={"selectButton" + e.id} className={"btn btn-primary"}
                                        onClick={() => this.loadTemplateData(e.id!)}>{e.name}</button>
                            </div>
                        })
                    }
                </div>
            </div>
            <div className="modal-footer">
            </div>
        </div>
    }

    private getEventDetailScreen() {
        return <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title"
                    id="newEventModalLabel">{localizedTexts.title} - {localizedTexts.wfHeaderEventDetail}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <div className={"shadow p-2 rounded"}>
                    <div className={"row"}>
                        <div className={"col"}>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="name"
                                       placeholder={localizedTexts.labelName}
                                       value={this.state.eventInfos!.name!} onChange={this.changeHandler}/>
                                <label htmlFor="name">{localizedTexts.labelName}</label>
                            </div>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col"}>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="shortName"
                                       placeholder={localizedTexts.labelShortName}
                                       value={this.state.eventInfos!.shortName!} onChange={this.changeHandler}/>
                                <label htmlFor="shortname">{localizedTexts.labelShortName}</label>
                            </div>
                        </div>

                        <div className={"col"}>
                            <div className="form-floating mb-3">
                                <input type="number" className="form-control" id="eventYear"
                                       maxLength={4}
                                       value={this.state.eventInfos!.eventYear!}
                                       onChange={(e) => {
                                            const value = e.target.value;
                                            if (value.length == 4) {
                                                this.changeHandler(e);
                                            }
                                        }}
                                />
                                <label htmlFor="shortname">{localizedTexts.labelEventYear}</label>
                            </div>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col"}>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="eventLocation"
                                       placeholder={localizedTexts.labelEventLocation}
                                       value={this.state.eventInfos!.eventLocation!} onChange={this.changeHandler}/>
                                <label htmlFor="eventLocation">{localizedTexts.labelEventLocation}</label>
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col"}>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="eventOrganizer"
                                       placeholder={localizedTexts.labelEventOrganizer}
                                       value={this.state.eventInfos!.eventOrganizer!} onChange={this.changeHandler}/>
                                <label htmlFor="eventOrganizer">{localizedTexts.labelEventOrganizer}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                {
                    this.getFooterButtons(EventDisplayScreenEnum.SelectType, EventDisplayScreenEnum.FreeEventFieldSelection)
                }
            </div>
        </div>
    }

    disciplineSelectionChange(idx: number) {
        var eventInfos = this.state.eventInfos;
        eventInfos!.disciplines![idx].isEnabled = !eventInfos!.disciplines![idx].isEnabled;
        this.setState({eventInfos: eventInfos});
    }

    private getFreeEventDisciplineSelection() {
        return <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title"
                    id="newEventModalLabel">{localizedTexts.title} - {localizedTexts.wfHeaderEventDisciplineSelection}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <div className={"shadow p-2 rounded"}>
                    {
                        this.state.eventInfos?.disciplines?.map((d, idx) => {
                            return <div className={"row"}>
                                <div className={"col"}>
                                    <div className="form-floating mb-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value=""
                                                   id={d.name!.defaultText ?? ""} checked={d.isEnabled}
                                                   onChange={() => {
                                                       this.disciplineSelectionChange(idx);
                                                   }}/>
                                            <label className="form-check-label" htmlFor={d.name!.defaultText ?? ""}>
                                                {d.name?.defaultText}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
            <div className="modal-footer">
                {
                    this.getFooterButtons(EventDisplayScreenEnum.EventInfo, EventDisplayScreenEnum.FreeEventFieldSelection)
                }
            </div>
        </div>
    }

    private getFreeEventFieldSelection() {
        return <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title"
                    id="newEventModalLabel">{localizedTexts.title} - {localizedTexts.wfHeaderEventFieldSelection}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <div className={"shadow p-2 rounded"}>
                    <div className={"row"}>
                        <div className={"col-2"}>
                            <h3>{localizedTexts.labelDiscipline}</h3>
                            <div className={"list-group"} role={"tablist"}>
                                {
                                    this.state.eventInfos?.disciplines?.map((d, idx) => {
                                        return <a
                                            className={"list-group-item list-group-item-action " + (idx == 0 ? "active" : "")}
                                            data-bs-toggle={"list"} role={"tab"}
                                            href={`#${d.name!.defaultText ?? ""}Fields`} onClick={() => {
                                            this.setState({wizardFreeShootingSelectedField: (d.fields ? d.fields[0].shortName?.defaultText : "") ?? ""})
                                        }}>{d.name!.defaultText}
                                            <button
                                                className={"rounded-circle float-end btn " + (d.isEnabled ? "btn-success" : "btn-danger")}
                                                onClick={(evt) => {
                                                    var eventInfos = this.state.eventInfos;
                                                    var discipline = eventInfos?.disciplines?.find(dis => dis.name == d.name);
                                                    discipline!.isEnabled = !discipline!.isEnabled;
                                                    this.setState({eventInfos: eventInfos});
                                                }}>{d.isEnabled ?
                                                <span className={"bi-dash-circle"}></span> :
                                                <span className={"bi-plus-circle"}></span>}</button>
                                        </a>
                                    })
                                }
                            </div>
                        </div>
                        <div className={"col-10"}>
                            <div className={"tab-content"}>
                                {
                                    this.state.eventInfos?.disciplines?.map((d, idx) => {
                                        return <div
                                            className={"tab-pane fade " + (idx == 0 ? "show active" : "")}
                                            id={`${d.name!.defaultText ?? ""}Fields`}
                                            role={"tabpanel"}>
                                            <div className={"row"}>
                                                <div className={"col-6"}>
                                                    <h3>{localizedTexts.labelField}</h3>
                                                    <div className={"list-group"} role={"tablist"}>
                                                        {
                                                            d.fields?.sort((a, b) => a.order! - b.order!).map((f, idx) => {
                                                                return <a
                                                                    key={`${f.shortName!.defaultText}fieldListItem`}
                                                                    className={"list-group-item list-group-item-action " + (idx == 0 ? "active" : "")}
                                                                    data-bs-toggle={"list"} role={"tab"}
                                                                    href={`#${f.shortName!.defaultText ?? ""}Fields`}
                                                                    onClick={() => {
                                                                        this.setState({wizardFreeShootingSelectedField: f.shortName!.defaultText ?? ""})
                                                                    }}>{f.name!.defaultText}
                                                                    <button
                                                                        name={f.shortName?.defaultText ?? ""}
                                                                        className={"rounded-circle float-end btn " + (f.isEnabled ? "btn-success" : "btn-danger")}
                                                                        onClick={(evt) => {
                                                                            var eventInfos = this.state.eventInfos;
                                                                            var discipline = eventInfos?.disciplines?.find(dis => dis.name == d.name);
                                                                            if (discipline) {
                                                                                var field = discipline.fields?.find(ftw => ftw.shortName?.defaultText == evt.currentTarget.name);
                                                                                if (field) {
                                                                                    field.isEnabled = !field.isEnabled;
                                                                                }
                                                                            }
                                                                            this.setState({eventInfos: eventInfos});
                                                                        }}>{f.isEnabled ?
                                                                        <span className={"bi-dash-circle"}></span> :
                                                                        <span
                                                                            className={"bi-plus-circle"}></span>}</button>
                                                                </a>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className={"col-6"}>
                                                    <h3>{localizedTexts.labelWeapon}</h3>
                                                    {
                                                        d.weapons?.map((w, idx) => {
                                                            var selectedFielWeapons = d.fieldToWeaponMappings?.find(ftw => ftw.fieldShortName == this.state.wizardFreeShootingSelectedField);

                                                            return <div className={"row"}>
                                                                <div className={"col"}>
                                                                    <div className="form-floating mb-3">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input"
                                                                                   type="checkbox" value=""
                                                                                   name={w.shortName?.defaultText ?? ""}
                                                                                   id={w.shortName!.defaultText ?? ""}
                                                                                   checked={(selectedFielWeapons?.listMappings?.find(we => we == w.shortName?.defaultText) != undefined)}
                                                                                   onChange={(evt) => {
                                                                                       var eventInfos = this.state.eventInfos;
                                                                                       var discipline = eventInfos?.disciplines?.find(dis => dis.name == d.name);
                                                                                       if (evt.currentTarget.checked) {
                                                                                           if (discipline) {
                                                                                               var fieldAdd = discipline.fieldToWeaponMappings?.find(ftw => ftw.fieldShortName == this.state.wizardFreeShootingSelectedField);
                                                                                               if (fieldAdd) {
                                                                                                   fieldAdd.listMappings!.push(evt.currentTarget.name);
                                                                                               }
                                                                                           }
                                                                                       } else {
                                                                                           if (discipline) {
                                                                                               var fieldRemove = discipline.fieldToWeaponMappings?.find(ftw => ftw.fieldShortName == this.state.wizardFreeShootingSelectedField);
                                                                                               if (fieldRemove) {
                                                                                                   var idx = fieldRemove.listMappings!.findIndex(wsn => wsn == w.shortName?.defaultText);
                                                                                                   if (idx >= 0) {
                                                                                                       fieldRemove.listMappings!.splice(idx, 1);
                                                                                                   }
                                                                                               }
                                                                                           }
                                                                                       }
                                                                                       this.setState({eventInfos: eventInfos});
                                                                                   }}/>
                                                                            <label className="form-check-label"
                                                                                   htmlFor={w.name!.defaultText ?? ""}>
                                                                                {w.name?.defaultText}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                {
                    this.getFooterButtons(EventDisplayScreenEnum.EventInfo, EventDisplayScreenEnum.FreeEventShootingProgramDefinition)
                }
            </div>
        </div>
    }

    private getFooterButtons(prev: EventDisplayScreenEnum, next: EventDisplayScreenEnum | undefined) {
        return <div className={"row"}>
            <div className={"col"}>
                <div className="form-floating mb-3">
                    <button className={'btn btn-secondary'}
                            onClick={() => this.setState({currentDisplay: prev})}>{localizedTexts.buttonBack}</button>
                    &nbsp;
                    {
                        next ? <button className={'btn btn-primary'}
                                       onClick={() => this.setState({currentDisplay: next})}>{localizedTexts.buttonNext}</button> :
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                                    onClick={this.saveHandler}>{localizedTexts.buttonSave}
                            </button>
                    }

                </div>
            </div>
        </div>
    }
}


interface NewEventModalState {
    eventInfos: NewEvent | undefined;
    eventTypes: EventType[];
    eventTemplateNames: string[];
    selectedTemplate: string;
    currentDisplay: EventDisplayScreenEnum;
    isLastDisplay: boolean;

    wizardFreeShootingSelectedField: string;

    name: string;
    shortname: string;
    eventType: number;
    eventLocation: string;
    eventOrganizer: string;

    selectedShootingProgram: NewEventShootingProgramDefinition | undefined;
}

interface NewEventModalProps {
    modalId: string;
    eventTypes: EventType[];
    apiClient: Api<any>;
    afterSaveUpdate: Function;
}