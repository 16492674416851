import localizatedTexts from "../texts/overview.texts"
import "react-datepicker/dist/react-datepicker.css";
import ApiClientFactory from "../../models/ApiClientFactory";
import React, {useEffect, useState} from "react";
import {RangeScreen, ShootingRange} from "../../models/Models";
import {useParams} from "react-router";

function ScreenOverviewWrapper(){
    const {rangeId} = useParams();
    return <ScreenOverview rangeId={rangeId}/>
}


interface ScreenOverviewProps {
    rangeId: string | undefined
}

const ScreenOverview : React.FC<ScreenOverviewProps> = ({rangeId}) => {
    const apiClient = ApiClientFactory.GetApiClient();
    const [screens, setScreens] = useState<RangeScreen[]>([]);

    const [selectedShootingRange, setSelectedShootingRange] = useState<string>("")

    const [shootingRanges , setShootingRanges] = useState<ShootingRange[]>()


    useEffect(() => {
        if(shootingRanges == undefined){
            loadData();
        }
        if(selectedShootingRange === "" && rangeId !=="" ) {
            setSelectedShootingRange(rangeId??"")
        }
        loadScreens();
    }, [selectedShootingRange])

    function loadScreens(){
        if(selectedShootingRange!== ""){
            apiClient.api.coreScreenDetail(selectedShootingRange).then(data => {
                setScreens(data.data);
            })
        }

    }

    function loadData() {
        apiClient.api.coreShootingRangesList().then(data => {
            setShootingRanges(data.data);
            if(data.data.length ===1){
                setSelectedShootingRange(data.data[0].id??"");
            }
        })
    }


    return <><div className={"container-fluid"}>
        <div className={"row"}>
            <div className={"col-md-10 shadow rounded p-4 m-4 mt-2"}>
                <h1>{localizatedTexts.winfireLiveScreenOverview } BETA DUMMY</h1>
                {shootingRanges?.length != undefined && shootingRanges.length > 1 ?
                    <div className={"input-group mb-3 m-1"}>
                        <span className={"input-group-text"}>{localizatedTexts.shootinRange}</span>
                        <select disabled={true} value={selectedShootingRange} className={"form-select"} onChange={data => {
                            setSelectedShootingRange(data.currentTarget.value);

                            const selectElement = document.getElementById("distanceSelector") as HTMLSelectElement;
                            if (selectElement) {
                                selectElement.value = ""; // Zurücksetzen auf den übergebenen Standardwert
                            }
                        }}>
                            <option value={""}></option>
                            {shootingRanges.sort((a,b)=> a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()??"") as number).map((range) => {
                                return <option value={range.id || 0}>{range.name}</option>
                            })
                            }
                            *                       </select>
                    </div> : <div>{localizatedTexts.shootinRange} : {shootingRanges?.map(sr => {
                        return <h1>{sr.name}</h1>
                    })}</div>}
            </div>

            <div className={"col-md-10 shadow rounded p-4 m-4 mt-2"}>
                <h2>{localizatedTexts.overview}</h2>
                {screens.length !== 0 ?
                    <table className={"table table-striped table-hover table-bordered table-sm"}>
                        <thead>
                        <tr>
                            <th className={"col-3"}>{localizatedTexts.screenName}</th>
                            <th className={"col-6"}>{localizatedTexts.eventMapping}</th>
                            <th className={"col-3"}>{localizatedTexts.screenContentTypes}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {screens.sort((a,b) =>  a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()??"") as number).map((screen) => {
                                return <tr>
                                    <td className={"align-middle"}>{screen.name}</td>
                                    <td>
                                        <select className={"form-select"}>
                                            <option>Anlass 1</option>
                                            <option>Anlass 2</option>
                                            <option>Anlass 3</option>
                                        </select></td><td>
                                        <select className={"form-select"}>
                                            <option>Rangliste</option>
                                            <option>Schussbild</option>
                                            <option>Bla 3</option>
                                        </select>
                                    </td>
                                </tr>
                            })}

                        </tbody>
                    </table> : <span> {localizatedTexts.noScreenAvailable} </span>
                }
            </div>
        </div>

    </div>
        </>
}

export default ScreenOverviewWrapper;