/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AddressWithIban {
  firstName?: string | null;
  lastName?: string | null;
  street?: string | null;
  streetNr?: string | null;
  zipCode?: string | null;
  city?: string | null;
  iban?: string | null;
  email?: string | null;
}

export interface AgeCategory {
  /** @format int32 */
  id?: number;
  name?: string | null;
  shortName?: string | null;
  /** @format int32 */
  ageFrom?: number;
  /** @format int32 */
  ageTo?: number;
}

export interface Discipline {
  /** @format int32 */
  id?: number;
  name?: string | null;
  distance?: Distance;
  fields?: Field[] | null;
  shootingProgramDefinitions?: ShootingProgramDefinition[] | null;
}

export interface Distance {
  /** @format int32 */
  id?: number;
  name?: string | null;
}

export interface EventInfo {
  name?: string | null;
  shortName?: string | null;
  /** @format int32 */
  eventYear?: number;
  eventLocation?: string | null;
  eventOrganizer?: string | null;
  /** @format int32 */
  participantCount?: number;
}

export interface EventRegistrationInformation {
  disciplines?: Discipline[] | null;
}

export interface ExerciseRegistration {
  /** @format int32 */
  shootingProgramDefinitionId?: number;
  /** @format int32 */
  count?: number;
  /** @format double */
  amount?: number | null;
}

export interface ExerciseSettlement {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  shootingProgramDefinitionId?: number;
  /** @format double */
  result?: number;
  /** @format double */
  bestShotValue?: number;
  /** @format int32 */
  weaponDefinitionId?: number;
  /** @format double */
  payoutAmount?: number | null;
  awardCardGranted?: boolean;
  awardCardReceived?: boolean;
  payoutAmountReceived?: boolean;
  awardGranted?: boolean;
  awardReceived?: boolean;
  /** @format double */
  naturalPayoutAmount?: number | null;
  naturalPayoutAmountReceived?: boolean;
}

export interface Field {
  /** @format int32 */
  id?: number;
  name?: string | null;
  shortName?: string | null;
  weapons?: Weapon[] | null;
}

export interface FinancialInfo {
  /** @format int32 */
  invoiceCount?: number;
  /** @format int32 */
  openInvoiceCount?: number;
  /** @format double */
  totalInvoiceAmount?: number;
  /** @format double */
  totalPaidAmount?: number;
}

export interface Group {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  groupNumber?: number;
  groupName?: string | null;
  organizationNumber?: string | null;
  /** @format int32 */
  shootingProgramDefinitionId?: number;
  /** @format int32 */
  participantsCount?: number;
  /** @format int32 */
  maxGroupSize?: number;
  /** @format int32 */
  groupChefId?: number | null;
  groupParticipants: Participant[] | null;
}

export interface Invoice {
  /** @format int32 */
  invoiceNr?: number;
  /** @format date-time */
  date?: string;
  /** @format date-time */
  dueDate?: string;
  /** @format double */
  amount?: number;
  organizationName?: string | null;
  organizationNumber: string | null;
  /** @format int32 */
  invoiceStatusId?: number;
  invoiceStatusName?: string | null;
}

export interface NewEvent {
  name?: string | null;
  shortName?: string | null;
  /** @format int32 */
  eventYear?: number;
  /** @format int32 */
  eventType?: number;
  eventOrganizer?: string | null;
  eventLocation?: string | null;
  ageCategories?: NewEventAgeCategories[] | null;
  disciplines?: NewEventDiscipline[] | null;
  generalParticipatingCosts?: NewEventGeneralParticipatingCosts[] | null;
  generalParticipatingCostsToDisciplines?: NewEventFieldToListMapping[] | null;
}

export interface NewEventAgeCategories {
  name?: NewEventTranslationText;
  shortName?: string | null;
  /** @format int32 */
  ageFrom?: number;
  /** @format int32 */
  ageTo?: number;
}

export interface NewEventAmmunition {
  name?: NewEventTranslationText;
  /** @format double */
  caliber?: number;
}

export interface NewEventDiscipline {
  /** @format int32 */
  id?: number;
  distance?: NewEventDistance;
  name?: NewEventTranslationText;
  isEnabled?: boolean;
  weapons?: NewEventWeapon[] | null;
  fields?: NewEventField[] | null;
  fieldToWeaponMappings?: NewEventFieldToListMapping[] | null;
  shootingProgramDefinitions?: NewEventShootingProgramDefinition[] | null;
}

export interface NewEventDistance {
  /** @format int32 */
  id?: number;
  name?: string | null;
}

export interface NewEventField {
  name?: NewEventTranslationText;
  shortName?: NewEventTranslationText;
  /** @format int32 */
  order?: number;
  isEnabled?: boolean;
}

export interface NewEventFieldToListMapping {
  fieldShortName?: string | null;
  listMappings?: string[] | null;
}

export interface NewEventGeneralParticipatingCosts {
  name?: NewEventTranslationText;
  description?: NewEventTranslationText;
  /** @format double */
  amount?: number;
  isOnce?: boolean;
  /** @format int32 */
  generalParticipatingCostType?: number;
}

export interface NewEventShootingProgram {
  program?: string | null;
  /** @format int32 */
  shotCount?: number;
}

export interface NewEventShootingProgramDefinition {
  name?: NewEventTranslationText;
  description?: NewEventTranslationText;
  isGroupExercise?: boolean;
  isBestShotRequired?: boolean;
  /** @format int32 */
  maxPassen?: number;
  isCalculated?: boolean;
  isOrderable?: boolean;
  isMandatory?: boolean;
  /** @format int32 */
  visualOrder?: number;
  isSection?: boolean;
  isShotsRequired?: boolean;
  isTrialExercise?: boolean;
  isTenthScoring?: boolean;
  isNachdoppel?: boolean;
  /** @format int32 */
  nachdoppelShotCount?: number;
  innerTenCountRequired?: boolean;
  /** @format int32 */
  specialTargetReservationCount?: number;
  /** @format int32 */
  targetReservationMode?: number;
  /** @format int32 */
  groupSize?: number;
  prices?: NewEventShootingProgramDefinitionPrice[] | null;
  shootingProgram?: NewEventShootingProgram;
}

export interface NewEventShootingProgramDefinitionPrice {
  ageCategory?: string | null;
  /** @format double */
  price?: number;
}

export interface NewEventTranslation {
  language?: string | null;
  translation?: string | null;
}

export interface NewEventTranslationText {
  defaultText?: string | null;
  translations?: NewEventTranslation[] | null;
}

export interface NewEventWeapon {
  name?: NewEventTranslationText;
  shortName?: NewEventTranslationText;
  includingAmmunition?: boolean;
  ammunition?: NewEventAmmunition;
  isEnabled?: boolean;
}

export interface Organization {
  number?: string | null;
  name?: string | null;
  shortName?: string | null;
  zipCode?: string | null;
  city?: string | null;
  disciplines?: Discipline[] | null;
  organizationDisciplineCategories?: OrganizationDisciplineCategory[] | null;
}

export interface OrganizationDisciplineCategory {
  /** @format int32 */
  disciplineId?: number;
  /** @format int32 */
  category?: number;
  /** @format int32 */
  minimalParticipants?: number;
}

export interface OrganizationMailSent {
  emailType?: string | null;
  recipient?: string | null;
  discipline?: string | null;
  /** @format date-time */
  mailSentDateTime?: string;
}

export interface OrganizationTimeSlotInformation {
  /** @format int32 */
  disciplineId?: number;
  disciplineName?: string | null;
  /** @format int32 */
  standardTimeSlots?: number;
  /** @format int32 */
  specialTimeSlots?: number;
  /** @format int32 */
  usedTimeSlots?: number;
  shootingProgramDefinitionTimeSlots?: ShootingProgramDefinitionTimeSlot[] | null;
}

export interface OrganizationWithOverviewInformation {
  organizationNumber?: string | null;
  organizationName?: string | null;
  disciplines?: Discipline[] | null;
  /** @format int32 */
  groupCount?: number;
  /** @format int32 */
  incompleteGroupCount?: number;
  /** @format int32 */
  invoiceCount?: number;
  /** @format int32 */
  openInvoiceCount?: number;
  /** @format int32 */
  payedInvoiceCount?: number;
  /** @format int32 */
  overdueInvoiceCount?: number;
  /** @format int32 */
  timeSlotsCount?: number;
  /** @format int32 */
  unfixedTimeSlotCount?: number;
  /** @format date-time */
  lastEmailSent?: string | null;
  lastEmailSentType?: string | null;
}

export interface OrganizationWithTimeSlotInformation {
  organizationNumber?: string | null;
  organizationName?: string | null;
  organizationTimeSlotInformation?: OrganizationTimeSlotInformation[] | null;
}

export interface Participant {
  /** @format int32 */
  participantId?: number;
  participantNumber?: string | null;
  person?: Person;
  organization?: Organization;
  startNumber?: string | null;
  shootingProgramDefinitionRegistrations?: ShootingProgramDefinitionRegistration[] | null;
}

export interface ParticipantExercises {
  /** @format int32 */
  participantId?: number;
  participantNumber?: string | null;
  person?: Person;
  organization?: Organization;
  startNumber?: string | null;
  exerciseRegistrations?: ExerciseRegistration[] | null;
  disciplineIds?: number[] | null;
}

export interface ParticipantSearch {
  participantNumber?: string | null;
  personSearch?: PersonSearch;
}

export interface ParticipantSettlement {
  /** @format int32 */
  participantId?: number;
  ageCategory?: string | null;
  participantNumber?: string | null;
  person?: RegisterPerson;
  organization?: Organization;
  exerciseSettlements?: ExerciseSettlement[] | null;
}

export interface PaymentInfo {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  date?: string;
  /** @format double */
  amount?: number;
  reference?: string | null;
  debitorName?: string | null;
  isMatched?: boolean;
}

export interface Person {
  /** @format int32 */
  id?: number;
  licenseNumber?: string | null;
  personNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string | null;
  zipCode?: string | null;
  city?: string | null;
  postBox?: string | null;
  /** @format date */
  birthday?: string;
  isWoman?: boolean;
  organizations?: PersonOrganization[] | null;
}

export interface PersonOrganization {
  number?: string | null;
  name?: string | null;
  shortName?: string | null;
  zipCode?: string | null;
  city?: string | null;
  disciplines?: Discipline[] | null;
  organizationDisciplineCategories?: OrganizationDisciplineCategory[] | null;
  isAMember?: boolean;
  discipline?: Discipline;
}

export interface PersonResponsibleForOrganization {
  /** @format int32 */
  id?: number;
  firstName?: string | null;
  lastName?: string | null;
  zipCode?: string | null;
  city?: string | null;
  email?: string | null;
}

export interface PersonSearch {
  personNumber?: string | null;
  licenseNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string | null;
  zipCode?: string | null;
  city?: string | null;
  postBox?: string | null;
  /** @format date */
  birthday?: string | null;
  organization?: Organization;
}

export interface RegisterPerson {
  /** @format int32 */
  id?: number;
  licenseNumber?: string | null;
  personNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string | null;
  zipCode?: string | null;
  city?: string | null;
  postBox?: string | null;
  /** @format date */
  birthday?: string;
  isWoman?: boolean;
}

export interface ResultSheetDefinition {
  /** @format int32 */
  id?: number;
  name?: string | null;
  isBarcodeSheet?: boolean;
}

export interface ServiceResult {
  successful?: boolean;
  errorCode?: string | null;
}

export interface ShootingProgramDefinition {
  /** @format int32 */
  id?: number;
  name?: string | null;
  description?: string | null;
  isGroupExercise?: boolean;
  isMandatory?: boolean;
  /** @format int32 */
  maxPassen?: number;
  /** @format int32 */
  visualOrder?: number;
  isBestShotRequired?: boolean;
  shotsRequired?: boolean;
  sequencesRequired?: boolean;
  innerTenCountRequired?: boolean;
  /** @format int32 */
  shotCount?: number;
  shootingProgramDefinitionPrices?: ShootingProgramDefinitionPrice[] | null;
  shootingProgramSequences?: ShootingProgramSequence[] | null;
  hasAward?: boolean;
  hasAwardCard?: boolean;
  hasAwardNatural?: boolean;
  awardNaturalName?: string | null;
  hasPayout?: boolean;
  hasNaturalPayout?: boolean;
  isCalculated?: boolean;
  isOrderable?: boolean;
  /** @format int32 */
  groupSize?: number;
  resultSheetDefinition?: ResultSheetDefinition;
  /** @format int32 */
  singleScoreListDefinitionId?: number | null;
}

export interface ShootingProgramDefinitionPrice {
  ageCategory?: AgeCategory;
  /** @format double */
  price?: number;
  isDefault?: boolean;
  /** @format double */
  groupPriceAdd?: number;
  /** @format double */
  priceWithoutAmmo?: number;
  /** @format double */
  nachdoppelPriceSub?: number;
}

export interface ShootingProgramDefinitionRegistration {
  /** @format int32 */
  shootingProgramDefinitionId?: number;
  /** @format int32 */
  weaponId?: number;
  /** @format int32 */
  passenCount?: number;
  isOrdered?: boolean;
  group?: Group;
  /** @format double */
  price?: number;
}

export interface ShootingProgramDefinitionTimeSlot {
  /** @format int32 */
  shootingProgramDefinitionId?: number;
  shootingProgramDefinitionName?: string | null;
  /** @format int32 */
  availableTimeSlots?: number;
  /** @format int32 */
  usedTimeSlots?: number;
}

export interface ShootingProgramSequence {
  /** @format int32 */
  order?: number;
  /** @format int32 */
  shotCount?: number;
  firekind?: string | null;
  /** @format int32 */
  firekindId?: number;
  /** @format int32 */
  maxShotvalue?: number;
}

export interface ShootingRange {
  number?: string | null;
  name?: string | null;
  distances?: Distance[] | null;
}

export interface ShootingRangeTimeSlotReservationInformations {
  shootingRangeNumber?: string | null;
  shootingRangeName?: string | null;
  timeSlotReservations?: TimeSlotReservation[] | null;
}

export interface TimeSlotInformation {
  /** @format date-time */
  startTime?: string;
  /** @format int32 */
  durationInMinutes?: number;
  shootingRangeName?: string | null;
  /** @format int32 */
  laneNr?: number;
  /** @format int32 */
  registeredDisciplineId?: number | null;
  /** @format int32 */
  stateId?: number;
}

export interface TimeSlotReservation {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  timeSlotReservationGroup?: number | null;
  /** @format date-time */
  startTime?: string;
  isMorning?: boolean;
  /** @format int32 */
  durationMinutes?: number;
  assignedOrganizationNumber?: string | null;
  /** @format int32 */
  assignedDisciplineId?: number | null;
  /** @format int32 */
  stateId?: number;
  /** @format int32 */
  laneNumber?: number;
  distance?: Distance;
  shootingProgramDefinitionName?: string | null;
  participantName?: string | null;
  allowedDisciplines?: Discipline[] | null;
}

export interface TimeSlotReservationCalculationDefinition {
  shootingRangeNumber?: string | null;
  /** @format int32 */
  distanceId?: number;
  /** @format int32 */
  laneFrom?: number;
  /** @format int32 */
  laneTo?: number;
  blockedLanes?: number[] | null;
  standardReservationOnly?: boolean;
  allowedDisciplines?: number[] | null;
  /** @format date-time */
  from?: string;
  /** @format date-time */
  to?: string;
  shootingProgramDefinitionRotation?: number[] | null;
}

export interface TimeSlotReservationData {
  shootingRangeNumber?: string | null;
  /** @format int32 */
  distanceId?: number;
  times?: TimeSlotReservationDataTime[] | null;
}

export interface TimeSlotReservationDataGroup {
  /** @format int32 */
  groupId?: number;
  groupName?: string | null;
  shootingProgramDefinitionName?: string | null;
  /** @format int32 */
  shootingProgramDefinitionId?: number | null;
  items?: TimeSlotReservationDataItem[] | null;
}

export interface TimeSlotReservationDataItem {
  /** @format int32 */
  id?: number;
  isMorning?: boolean;
  /** @format int32 */
  durationMinutes?: number;
  /** @format int32 */
  stateId?: number;
  /** @format int32 */
  laneNumber?: number;
  allowedDisciplineIds?: number[] | null;
  shootingProgramDefinitionName?: string | null;
  assignedOrganizationNumber?: string | null;
  participantName?: string | null;
  /** @format int32 */
  assignedDisciplineId?: number | null;
}

export interface TimeSlotReservationDataTime {
  time?: string | null;
  isMorning?: boolean;
  groups?: TimeSlotReservationDataGroup[] | null;
}

export interface TimeSlotReservationInformation {
  shootingRangeNumber?: string | null;
  /** @format date */
  date?: string;
  isMorning?: boolean;
  /** @format int32 */
  distanceId?: number;
  /** @format int32 */
  count?: number;
}

export interface TimeSlotReservationOverview {
  shootingRangeName?: string | null;
  shootingRangeNumber?: string | null;
  /** @format int32 */
  distanceId?: number;
  distanceName?: string | null;
  /** @format date */
  date?: string;
  /** @format int32 */
  morningOpenCount?: number;
  /** @format int32 */
  afternoonOpenCount?: number;
  /** @format int32 */
  usedCount?: number;
  /** @format int32 */
  blockedCount?: number;
  /** @format int32 */
  totalCount?: number;
}

export interface TimeSlotSwapInfo {
  fromSlotIds?: number[] | null;
  toSlotIds?: number[] | null;
}

export interface Weapon {
  /** @format int32 */
  id?: number;
  name?: string | null;
  shortName?: string | null;
  priceIncludingAmmo?: boolean;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Event API V2
 * @version v2
 *
 * Event API V2 for handling Events
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Document
     * @name EventsV2DocumentInvoiceDetail
     * @request GET:/api/events/v2/{winFireEventId}/Document/invoice/{invoiceNr}
     */
    eventsV2DocumentInvoiceDetail: (
      invoiceNr: number,
      winFireEventId: string,
      query?: {
        /** @default "pdf" */
        fileType?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/events/v2/${winFireEventId}/Document/invoice/${invoiceNr}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name EventsV2DocumentInvoiceCreate
     * @request POST:/api/events/v2/{winFireEventId}/Document/invoice/{invoiceNr}
     */
    eventsV2DocumentInvoiceCreate: (invoiceNr: number, winFireEventId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/events/v2/${winFireEventId}/Document/invoice/${invoiceNr}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name EventsV2DocumentRegistrationconfirmationDetail
     * @request GET:/api/events/v2/{winFireEventId}/Document/registrationconfirmation/{organizationNr}
     */
    eventsV2DocumentRegistrationconfirmationDetail: (
      organizationNr: string,
      winFireEventId: string,
      query?: {
        /** @default "pdf" */
        fileType?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/events/v2/${winFireEventId}/Document/registrationconfirmation/${organizationNr}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name EventsV2DocumentRegistrationconfirmationCreate
     * @request POST:/api/events/v2/{winFireEventId}/Document/registrationconfirmation/{organizationNr}
     */
    eventsV2DocumentRegistrationconfirmationCreate: (
      organizationNr: string,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/events/v2/${winFireEventId}/Document/registrationconfirmation/${organizationNr}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name EventsV2DocumentTimeslotconfirmationDisciplineDetail
     * @request GET:/api/events/v2/{winFireEventId}/Document/timeslotconfirmation/{organizationNr}/discipline/{disciplineId}
     */
    eventsV2DocumentTimeslotconfirmationDisciplineDetail: (
      organizationNr: string,
      disciplineId: number,
      winFireEventId: string,
      query?: {
        /** @default "pdf" */
        fileType?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/events/v2/${winFireEventId}/Document/timeslotconfirmation/${organizationNr}/discipline/${disciplineId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name EventsV2DocumentTimeslotconfirmationDisciplineCreate
     * @request POST:/api/events/v2/{winFireEventId}/Document/timeslotconfirmation/{organizationNr}/discipline/{disciplineId}
     */
    eventsV2DocumentTimeslotconfirmationDisciplineCreate: (
      organizationNr: string,
      disciplineId: number,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/events/v2/${winFireEventId}/Document/timeslotconfirmation/${organizationNr}/discipline/${disciplineId}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name EventsV2DocumentTimeslotoverviewDistanceDetail
     * @request GET:/api/events/v2/{winFireEventId}/Document/timeslotoverview/{rangeNr}/distance/{distanceId}
     */
    eventsV2DocumentTimeslotoverviewDistanceDetail: (
      rangeNr: string,
      distanceId: number,
      winFireEventId: string,
      query?: {
        /** @default "pdf" */
        fileType?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/events/v2/${winFireEventId}/Document/timeslotoverview/${rangeNr}/distance/${distanceId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventInformation
     * @name EventsV2EventInformationDetail
     * @request GET:/api/events/v2/{winFireEventId}/EventInformation
     */
    eventsV2EventInformationDetail: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<EventInfo, ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/EventInformation`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventInformation
     * @name EventsV2EventInformationRegistrationinformationDetail
     * @request GET:/api/events/v2/{winFireEventId}/EventInformation/registrationinformation
     */
    eventsV2EventInformationRegistrationinformationDetail: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<EventRegistrationInformation, ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/EventInformation/registrationinformation`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventInformation
     * @name EventsV2EventInformationShootingprogrambasicinformationDetail
     * @request GET:/api/events/v2/{winFireEventId}/EventInformation/shootingprogrambasicinformation
     */
    eventsV2EventInformationShootingprogrambasicinformationDetail: (
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<ShootingProgramDefinition[], ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/EventInformation/shootingprogrambasicinformation`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventInformation
     * @name EventsV2EventInformationSetmyselftoorganizationfunctionCreate
     * @request POST:/api/events/v2/{winFireEventId}/EventInformation/setmyselftoorganizationfunction/{organizationnumber}
     */
    eventsV2EventInformationSetmyselftoorganizationfunctionCreate: (
      organizationnumber: string,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/EventInformation/setmyselftoorganizationfunction/${organizationnumber}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name EventsEventsDummyGeneratorDetail
     * @request GET:/api/events/Events/{winFireEventId}/dummyGenerator/{eventTypeId}
     */
    eventsEventsDummyGeneratorDetail: (eventTypeId: number, winFireEventId: string, params: RequestParams = {}) =>
      this.request<NewEvent, any>({
        path: `/api/events/Events/${winFireEventId}/dummyGenerator/${eventTypeId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Financial
     * @name EventsV2FinancialDetail
     * @request GET:/api/events/v2/{winFireEventId}/Financial
     */
    eventsV2FinancialDetail: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<FinancialInfo, ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/Financial`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Financial
     * @name EventsV2FinancialInvoiceDetail
     * @request GET:/api/events/v2/{winFireEventId}/Financial/invoice
     */
    eventsV2FinancialInvoiceDetail: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<Invoice[], ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/Financial/invoice`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Financial
     * @name EventsV2FinancialInvoiceDetail2
     * @request GET:/api/events/v2/{winFireEventId}/Financial/invoice/{organizationNr}
     * @originalName eventsV2FinancialInvoiceDetail
     * @duplicate
     */
    eventsV2FinancialInvoiceDetail2: (organizationNr: string, winFireEventId: string, params: RequestParams = {}) =>
      this.request<Invoice[], ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/Financial/invoice/${organizationNr}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Financial
     * @name EventsV2FinancialInvoiceCreate
     * @request POST:/api/events/v2/{winFireEventId}/Financial/invoice/{organizationNr}/{requestedDisciplineId}
     */
    eventsV2FinancialInvoiceCreate: (
      organizationNr: string,
      requestedDisciplineId: number,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<ServiceResult, any>({
        path: `/api/events/v2/${winFireEventId}/Financial/invoice/${organizationNr}/${requestedDisciplineId}`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Financial
     * @name EventsV2FinancialInvoiceDelete
     * @request DELETE:/api/events/v2/{winFireEventId}/Financial/invoice/{organizationNr}/{invoiceId}
     */
    eventsV2FinancialInvoiceDelete: (
      invoiceId: number,
      winFireEventId: string,
      organizationNr: string,
      params: RequestParams = {},
    ) =>
      this.request<ServiceResult, any>({
        path: `/api/events/v2/${winFireEventId}/Financial/invoice/${organizationNr}/${invoiceId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Financial
     * @name EventsV2FinancialPaymentDetail
     * @request GET:/api/events/v2/{winFireEventId}/Financial/payment
     */
    eventsV2FinancialPaymentDetail: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<PaymentInfo[], ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/Financial/payment`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Financial
     * @name EventsV2FinancialPaymentDelete
     * @request DELETE:/api/events/v2/{winFireEventId}/Financial/payment/{paymentId}
     */
    eventsV2FinancialPaymentDelete: (paymentId: number, winFireEventId: string, params: RequestParams = {}) =>
      this.request<any, ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/Financial/payment/${paymentId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Financial
     * @name EventsV2FinancialPaymentParsefileCreate
     * @request POST:/api/events/v2/{winFireEventId}/Financial/payment/parsefile
     */
    eventsV2FinancialPaymentParsefileCreate: (
      winFireEventId: string,
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/Financial/payment/parsefile`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Financial
     * @name EventsV2FinancialPaymentExecutematchingUpdate
     * @request PUT:/api/events/v2/{winFireEventId}/Financial/payment/executematching
     */
    eventsV2FinancialPaymentExecutematchingUpdate: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<any, ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/Financial/payment/executematching`,
        method: "PUT",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name EventsV2OrganizationsDetail
     * @request GET:/api/events/v2/{winFireEventId}/Organizations
     */
    eventsV2OrganizationsDetail: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<Organization[], any>({
        path: `/api/events/v2/${winFireEventId}/Organizations`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name EventsV2OrganizationsRegisteredDetail
     * @request GET:/api/events/v2/{winFireEventId}/Organizations/registered
     */
    eventsV2OrganizationsRegisteredDetail: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<Organization[], any>({
        path: `/api/events/v2/${winFireEventId}/Organizations/registered`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name EventsV2OrganizationsRegisteredOverviewDetail
     * @request GET:/api/events/v2/{winFireEventId}/Organizations/registered-overview
     */
    eventsV2OrganizationsRegisteredOverviewDetail: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<OrganizationWithOverviewInformation[], ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/Organizations/registered-overview`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name EventsV2OrganizationsGroupsDetail
     * @request GET:/api/events/v2/{winFireEventId}/Organizations/{organizationnumber}/groups/{shootingProgramDefinitionId}
     */
    eventsV2OrganizationsGroupsDetail: (
      organizationnumber: string,
      shootingProgramDefinitionId: number,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<Group[], any>({
        path: `/api/events/v2/${winFireEventId}/Organizations/${organizationnumber}/groups/${shootingProgramDefinitionId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name EventsV2OrganizationsTimeslotsDetail
     * @request GET:/api/events/v2/{winFireEventId}/Organizations/{organizationnumber}/timeslots
     */
    eventsV2OrganizationsTimeslotsDetail: (
      organizationnumber: string,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<TimeSlotInformation[], ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/Organizations/${organizationnumber}/timeslots`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name EventsV2OrganizationsTimeslotsInformationDetail
     * @request GET:/api/events/v2/{winFireEventId}/Organizations/{organizationnumber}/{disciplineid}/timeslots/information
     */
    eventsV2OrganizationsTimeslotsInformationDetail: (
      organizationnumber: string,
      disciplineid: number,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<OrganizationWithTimeSlotInformation, ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/Organizations/${organizationnumber}/${disciplineid}/timeslots/information`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name EventsV2OrganizationsResponsiblepersonsDetail
     * @request GET:/api/events/v2/{winFireEventId}/Organizations/{organizationnumber}/responsiblepersons
     */
    eventsV2OrganizationsResponsiblepersonsDetail: (
      organizationnumber: string,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<PersonResponsibleForOrganization[], ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/Organizations/${organizationnumber}/responsiblepersons`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name EventsV2OrganizationsBillinginfoDetail
     * @request GET:/api/events/v2/{winFireEventId}/Organizations/{organizationnumber}/billinginfo
     */
    eventsV2OrganizationsBillinginfoDetail: (
      organizationnumber: string,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<AddressWithIban, ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/Organizations/${organizationnumber}/billinginfo`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name EventsV2OrganizationsBillinginfoCreate
     * @request POST:/api/events/v2/{winFireEventId}/Organizations/{organizationnumber}/billinginfo
     */
    eventsV2OrganizationsBillinginfoCreate: (
      organizationnumber: string,
      winFireEventId: string,
      data: AddressWithIban,
      params: RequestParams = {},
    ) =>
      this.request<any, ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/Organizations/${organizationnumber}/billinginfo`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name EventsV2OrganizationsSentmailsDetail
     * @request GET:/api/events/v2/{winFireEventId}/Organizations/{organizationnumber}/sentmails
     */
    eventsV2OrganizationsSentmailsDetail: (
      organizationnumber: string,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<OrganizationMailSent[], ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/Organizations/${organizationnumber}/sentmails`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name EventsV2OrganizationsInitialDistributionOfTimeslotsFor25MOrganizationDetail
     * @request GET:/api/events/v2/{winFireEventId}/Organizations/InitialDistributionOfTimeslotsFor25mOrganization
     */
    eventsV2OrganizationsInitialDistributionOfTimeslotsFor25MOrganizationDetail: (
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<OrganizationMailSent[], ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/Organizations/InitialDistributionOfTimeslotsFor25mOrganization`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsV2ParticipantsDetail
     * @request GET:/api/events/v2/{winFireEventId}/Participants
     */
    eventsV2ParticipantsDetail: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<Participant[], ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/Participants`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsV2ParticipantsByOrganizationDetail
     * @request GET:/api/events/v2/{winFireEventId}/Participants/byOrganization/{organizationnumber}
     */
    eventsV2ParticipantsByOrganizationDetail: (
      organizationnumber: string,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<ParticipantExercises[], ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/Participants/byOrganization/${organizationnumber}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsV2ParticipantsByOrganizationDetail2
     * @request GET:/api/events/v2/{winFireEventId}/Participants/byOrganization/{organizationnumber}/{disciplineid}
     * @originalName eventsV2ParticipantsByOrganizationDetail
     * @duplicate
     */
    eventsV2ParticipantsByOrganizationDetail2: (
      organizationnumber: string,
      disciplineid: number,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<ParticipantExercises[], ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/Participants/byOrganization/${organizationnumber}/${disciplineid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsV2ParticipantsDelete
     * @request DELETE:/api/events/v2/{winFireEventId}/Participants/{participantNumber}
     */
    eventsV2ParticipantsDelete: (participantNumber: string, winFireEventId: string, params: RequestParams = {}) =>
      this.request<ServiceResult, ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/Participants/${participantNumber}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsV2ParticipantsByLicenseDetail
     * @request GET:/api/events/v2/{winFireEventId}/Participants/byLicense/{licenseNumber}
     */
    eventsV2ParticipantsByLicenseDetail: (licenseNumber: string, winFireEventId: string, params: RequestParams = {}) =>
      this.request<Participant, ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/Participants/byLicense/${licenseNumber}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsV2ParticipantsSearchCreate
     * @request POST:/api/events/v2/{winFireEventId}/Participants/search
     */
    eventsV2ParticipantsSearchCreate: (winFireEventId: string, data: ParticipantSearch, params: RequestParams = {}) =>
      this.request<Participant[], any>({
        path: `/api/events/v2/${winFireEventId}/Participants/search`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsV2ParticipantsSettlementDetail
     * @request GET:/api/events/v2/{winFireEventId}/Participants/{participantNumber}/settlement
     */
    eventsV2ParticipantsSettlementDetail: (
      participantNumber: string,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<ParticipantSettlement, any>({
        path: `/api/events/v2/${winFireEventId}/Participants/${participantNumber}/settlement`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShootingRangeManagement
     * @name EventsV2ShootingRangeManagementDetail
     * @request GET:/api/events/v2/{winFireEventId}/ShootingRangeManagement
     */
    eventsV2ShootingRangeManagementDetail: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<ShootingRange[], ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/ShootingRangeManagement`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShootingRangeManagement
     * @name EventsV2ShootingRangeManagementTargetreservationtimeslotsGenerateCreate
     * @request POST:/api/events/v2/{winFireEventId}/ShootingRangeManagement/targetreservationtimeslots/generate
     */
    eventsV2ShootingRangeManagementTargetreservationtimeslotsGenerateCreate: (
      winFireEventId: string,
      data: TimeSlotReservationCalculationDefinition[],
      params: RequestParams = {},
    ) =>
      this.request<void, ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/ShootingRangeManagement/targetreservationtimeslots/generate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShootingRangeManagement
     * @name EventsV2ShootingRangeManagementTargetreservationtimeslotsSwapCreate
     * @request POST:/api/events/v2/{winFireEventId}/ShootingRangeManagement/targetreservationtimeslots/swap
     */
    eventsV2ShootingRangeManagementTargetreservationtimeslotsSwapCreate: (
      winFireEventId: string,
      data: TimeSlotSwapInfo,
      params: RequestParams = {},
    ) =>
      this.request<void, ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/ShootingRangeManagement/targetreservationtimeslots/swap`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShootingRangeManagement
     * @name EventsV2ShootingRangeManagementTargetreservationtimeslotsChangeshoootingprogramdefinitionCreate
     * @request POST:/api/events/v2/{winFireEventId}/ShootingRangeManagement/targetreservationtimeslots/changeshoootingprogramdefinition/{shootingProgramDefinitionId}
     */
    eventsV2ShootingRangeManagementTargetreservationtimeslotsChangeshoootingprogramdefinitionCreate: (
      shootingProgramDefinitionId: number,
      winFireEventId: string,
      data: number[],
      params: RequestParams = {},
    ) =>
      this.request<void, ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/ShootingRangeManagement/targetreservationtimeslots/changeshoootingprogramdefinition/${shootingProgramDefinitionId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShootingRangeManagement
     * @name EventsV2ShootingRangeManagementTargetreservationtimeslotsResetstateCreate
     * @request POST:/api/events/v2/{winFireEventId}/ShootingRangeManagement/targetreservationtimeslots/resetstate
     */
    eventsV2ShootingRangeManagementTargetreservationtimeslotsResetstateCreate: (
      winFireEventId: string,
      data: number[],
      params: RequestParams = {},
    ) =>
      this.request<void, ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/ShootingRangeManagement/targetreservationtimeslots/resetstate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShootingRangeManagement
     * @name EventsV2ShootingRangeManagementTargetreservationtimeslotsDetail
     * @request GET:/api/events/v2/{winFireEventId}/ShootingRangeManagement/targetreservationtimeslots/{shootingrangenumber}/{date}/{isMorning}
     */
    eventsV2ShootingRangeManagementTargetreservationtimeslotsDetail: (
      shootingrangenumber: string,
      date: string,
      isMorning: boolean,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<ShootingRangeTimeSlotReservationInformations, ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/ShootingRangeManagement/targetreservationtimeslots/${shootingrangenumber}/${date}/${isMorning}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShootingRangeManagement
     * @name EventsV2ShootingRangeManagementTargetreservationtimeslotdatasDetail
     * @request GET:/api/events/v2/{winFireEventId}/ShootingRangeManagement/targetreservationtimeslotdatas/{shootingrangenumber}/{date}/{distanceId}
     */
    eventsV2ShootingRangeManagementTargetreservationtimeslotdatasDetail: (
      shootingrangenumber: string,
      date: string,
      distanceId: number,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<TimeSlotReservationData, ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/ShootingRangeManagement/targetreservationtimeslotdatas/${shootingrangenumber}/${date}/${distanceId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShootingRangeManagement
     * @name EventsV2ShootingRangeManagementTargetreservationtimeslotsLockDetail
     * @request GET:/api/events/v2/{winFireEventId}/ShootingRangeManagement/targetreservationtimeslots/{organizationnumber}/{shootingrangenumber}/{distanceId}/{date}/{isMorning}/lock
     */
    eventsV2ShootingRangeManagementTargetreservationtimeslotsLockDetail: (
      organizationnumber: string,
      shootingrangenumber: string,
      distanceId: number,
      date: string,
      isMorning: boolean,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<ShootingRangeTimeSlotReservationInformations, ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/ShootingRangeManagement/targetreservationtimeslots/${organizationnumber}/${shootingrangenumber}/${distanceId}/${date}/${isMorning}/lock`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShootingRangeManagement
     * @name EventsV2ShootingRangeManagementTargetreservationtimeslotsStateinfoDetail
     * @request GET:/api/events/v2/{winFireEventId}/ShootingRangeManagement/targetreservationtimeslots/stateinfo
     */
    eventsV2ShootingRangeManagementTargetreservationtimeslotsStateinfoDetail: (
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<TimeSlotReservationInformation[], ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/ShootingRangeManagement/targetreservationtimeslots/stateinfo`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShootingRangeManagement
     * @name EventsV2ShootingRangeManagementTargetreservationtimeslotsOverviewDetail
     * @request GET:/api/events/v2/{winFireEventId}/ShootingRangeManagement/targetreservationtimeslots/overview
     */
    eventsV2ShootingRangeManagementTargetreservationtimeslotsOverviewDetail: (
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<TimeSlotReservationOverview[], ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/ShootingRangeManagement/targetreservationtimeslots/overview`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShootingRangeManagement
     * @name EventsV2ShootingRangeManagementTargetreservationtimeslotsDateinfoDetail
     * @request GET:/api/events/v2/{winFireEventId}/ShootingRangeManagement/targetreservationtimeslots/dateinfo
     */
    eventsV2ShootingRangeManagementTargetreservationtimeslotsDateinfoDetail: (
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<string[], ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/ShootingRangeManagement/targetreservationtimeslots/dateinfo`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SsvData
     * @name EventsV2SsvDataUpdate
     * @request PUT:/api/events/v2/{winFireEventId}/SsvData/{isAuflage}
     */
    eventsV2SsvDataUpdate: (
      isAuflage: boolean,
      winFireEventId: string,
      data: {
        /** @format binary */
        communitiesfile?: File;
        /** @format binary */
        shootersfile?: File;
        /** @format binary */
        nationalShooters?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ServiceResult>({
        path: `/api/events/v2/${winFireEventId}/SsvData/${isAuflage}`,
        method: "PUT",
        body: data,
        type: ContentType.FormData,
        ...params,
      }),
  };
}
