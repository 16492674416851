import LocalizedStrings from 'react-localization';

const localizedTexts = new LocalizedStrings({
    "de": {
        "city": "Stadt",
        "countShooter": "# Schützen",
        "editOrganisation": "Verein bearbeiten",
        "createOrganisation": "Verein erstellen",
        "organisationName": "Vereinsname",
        "organisationNumber": "Vereinsnummer",
        "postcode": "PLZ"
    },
    "fr": {
        "city": "Ville",
        "countShooter": "# Tireurs",
        "editOrganisation": "Modifier l'association",
        "createOrganisation": "Créer une association",
        "organisationName": "Nom de l'association",
        "organisationNumber": "Numéro d'association",
        "postcode": "Code postal"
    },
    "it": {
        "city": "Città",
        "countShooter": "# Tiratori",
        "editOrganisation": "Modificare l'associazione",
        "createOrganisation": "Creare associazione",
        "organisationName": "Nome dell'associazione",
        "organisationNumber": "Numero dell'associazione",
        "postcode": "Codice postale"
    },
    "en": {
        "city": "City",
        "countShooter": "# Shooters",
        "editOrganisation": "Edit Organization",
        "createOrganisation": "Create Organization",
        "organisationName": "Organization Name",
        "organisationNumber": "Organization Number",
        "postcode": "Postal code"
    }
})

export default localizedTexts;