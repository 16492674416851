import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "overview" : "Übersicht",
        "title" : "Vereinsübersicht",
        "labelOrganization":"Verein",
        "labelDiscipline":"Disziplin",
        "labelSearch":"Suchen",
        "labelLabels":"Standblätter",
        "labelTimeSlotInfoTitle":"Rangeure",
        "labelGroupInfoTitle":"Gruppen",
        "labelGroupInfoGroupName":"Gruppenname",
        "labelGroupInfoGroupSize":"Grösse",
        "labelGroupInfoGroupMembers":"Mitglieder",
        "labelTimeSlotInfoStandard":"Standard Rangeure",
        "labelTimeSlotInfoSpecial":"Meisterschaft Rangeure",
        "labelTimeSlotInfoTotal":"Total Rangeure",
        "labelTimeSlotInfoUsed":"Reservierte Rangeure",
        "labelMails":"Mails",
        "labelInvoices":"Rechnungen",
        "labelResponsiblePersons":"Funktionäre",
        "labelInvoiceAddress":"Rechnungsadresse",
        "labelSingle":"einzeln",
        "textOrganizationSelect":"Verein auswählen...",
        "headerCategory":"Kategorie",
        "headerBarcode":"SchützenNr.",
        "headerFirstName":"Vorname",
        "headerLastName":"Nachname",
        "headerEmailType":"Mailtyp",
        "headerMailSentDateTime":"Gesendet am",
        "headerRecipient":"Empfänger",
        "headerDiscipline":"Disziplin",
        "headerNr":"Nr.",
        "headerAmount":"Betrag",
        "ageOfBirth": "Jg.",
        "headerCity":"Ort",
        "headerDate":"Datum",
        "headerStartTime":"Von",
        "headerEndTime":"Bis",
        "headerLaneNr":"Scheibe",
        "headerShootingRangeName": "Schiessanlage",
        "headerOrganization":"Verein",
        "headerActions":"Aktionen",
        "headerEmail":"E-Mail",
        "headerTimeSlotConfirmation":"Rangeurbestätigung",
        "headerInvoice":"Rechnung",
        "headerRegistrationConfirmation":"Anmeldebestätigung",
        "shooteroffsetmessage":"Wieviele Schützen wurden bereits auf dieser Seite gedruckt?",
        "textTimeslotCount":"Anzahl Rangeure",
        "textMailSentSuccessful":"Mail versendet",
        "textNoGroupParticipant":"Keine Gruppenmitglieder",
        "messageInvoiceCreated":"Rechnung erstellt",
        "messageInvoiceCreationFailed":"Rechnung konnte nicht erstellt werden",
        "messageInvoiceDeletionConfirmation":"Sind Sie sicher, dass Sie diese Rechnung LÖSCHEN möchten?",
        "messageInvoiceStornoConfirmation":"Sind Sie sicher, dass Sie diese Rechnung STORNIEREN möchten?",
        "errorGeneralText":"Ein Fehler ist aufgetreten. Bitte nochmals versuchen.",
        "errorCode-ERRORORGANIZATIONNOTFOUND":"Verein nicht gefunden",
        "errorCode-ERRORNORESPONSIBLEPERSON":"Keine verantwortliche Person für den Verein gefunden",
        "errorCode-ERRORINVOICENOTFOUND":"Rechnung nicht gefunden",
        "errorCode-ERRORINVOICEADDRESSNOTAVAILABLE":"Keine Rechnungsadresse vorhanden",
        "errorCode-INVOICETEMPLATENOTFOUND":"Vorlagedatei für Rechnung nicht vorhanden. Bitte kontaktieren Sie uns.",
        "errorCode-NORIGHTS":"Keine Berechtigung.",
        "errorCode-ERRORQRINVOICENOTGENERATED":"QR Rechnung konnte nicht erstellt werden.",
        "errorCode-DISCIPLINEIDNOTFOUND":"Unbekannte Disziplin.",
        "errorCode-TIMESLOTSEMPTY":"Keine Rangeure vorhanden.",
        "errorCode-ERRORRANGEURCONFIGURATIONNOTGENERATED":"Rangeurbestätigung konnte nicht erstellt werden.",
        "errorCode-ERRORREGISTRATIONCONFIRMATIONNOTGENERATED": "Anmeldebestätigung konnte nicht erstellt werden."
    },
    "fr": {
        "overview" : "Aperçu",
        "title" : "Aperçu des sociétés",
        "labelOrganization": "Société",
        "labelDiscipline":"Discipline",
        "labelLabels": "Standblatt",
        "labelTimeSlotInfoTitle": "Rangeurs",
        "labelGroupInfoTitle": "Groupes",
        "labelGroupInfoGroupName": "Nom du groupe",
        "labelGroupInfoGroupSize": "Taille du groupe",
        "labelGroupInfoGroupMembers": "Membres",
        "labelTimeSlotInfoStandard": "Rangeurs standard",
        "labelTimeSlotInfoSpecial": "Rangeurs de maîtrise",
        "labelTimeSlotInfoTotal": "Rangeurs total",
        "labelTimeSlotInfoUsed": "Rangeurs réservées",
        "labelSearch": "Rechercher",
        "labelMails": "Mails",
        "labelInvoices": "Factures",
        "labelResponsiblePersons": "Responsables",
        "labelInvoiceAddress": "Adresse de facturation",
        "labelSingle":"seul",
        "textOrganizationSelect": "Sélectionnez une société...",
        "headerCategory": "Catégorie",
        "headerBarcode": "Code barre",
        "headerFirstName": "Prénom",
        "headerLastName": "Nom de famille",
        "headerEmailType": "Type d'email",
        "headerMailSentDateTime": "Envoyé le",
        "headerRecipient": "Récepteur",
        "headerDiscipline": "Discipline",
        "headerNr": "N°",
        "headerAmount": "Montant",
        "ageOfBirth": "Née",
        "headerCity": "Ville",
        "headerDate": "Date",
        "headerStartTime": "De",
        "headerEndTime": "À",
        "headerLaneNr": "Cible",
        "headerShootingRangeName": "Stand de tir",
        "headerOrganization": "Société",
        "headerActions": "Actions",
        "headerEmail": "E-mail",
        "headerTimeSlotConfirmation": "Confirmation des rangeurs",
        "headerInvoice": "Facture",
        "headerRegistrationConfirmation": "Confirmation d'enregistrement",
        "shooteroffsetmessage": "Combien de tireurs ont déjà été imprimés ici?",
        "textTimeslotCount": "Nombre de rangeurs",
        "textMailSentSuccessful": "E-mail envoyé",
        "textNoGroupParticipant": "Aucun membre dans le groupe",
        "messageInvoiceCreated": "Facture créée",
        "messageInvoiceCreationFailed": "Problème de création de la facture",
        "messageInvoiceDeletionConfirmation": "Êtes-vous sûr de vouloir SUPPRIMER cette facture?",
        "messageInvoiceStornoConfirmation": "Êtes-vous sûr de vouloir ANNULER cette facture?",
        "errorGeneralText": "Une erreur s'est produite. Veuillez réessayer.",
        "errorCode-ERRORORGANIZATIONNOTFOUND": "Société introuvable",
        "errorCode-ERRORNORESPONSIBLEPERSON": "Pas de responsable trouvé pour la société.",
        "errorCode-ERRORINVOICENOTFOUND": "Facture introuvable",
        "errorCode-ERRORINVOICEADDRESSNOTAVAILABLE": "Adresse de facturation non disponible",
        "errorCode-INVOICETEMPLATENOTFOUND": "Modèle de facture introuvable. Veuillez nous contacter.",
        "errorCode-NORIGHTS": "Pas d'autorisation.",
        "errorCode-ERRORQRINVOICENOTGENERATED": "La facture QR n'a pas pu être générée.",
        "errorCode-DISCIPLINEIDNOTFOUND": "Discipline inconnue.",
        "errorCode-TIMESLOTSEMPTY": "Aucune rangeurs disponible.",
        "errorCode-ERRORRANGEURCONFIGURATIONNOTGENERATED": "La confirmation de les rangeurs n'a pas pu être générée.",
        "errorCode-ERRORREGISTRATIONCONFIRMATIONNOTGENERATED": "La confirmation de l'inscription n'a pas pu être générée."

    },
    "it": {
        "overview": "Panoramica",
        "title": "Panoramica dell'associazione",
        "labelOrganization": "Associazione",
        "labelDiscipline": "Disciplina",
        "labelSearch": "Cerca",
        "labelLabels": "Schede di tiro",
        "labelTimeSlotInfoTitle": "Intervalli",
        "labelGroupInfoTitle": "Gruppi",
        "labelGroupInfoGroupName": "Nome del gruppo",
        "labelGroupInfoGroupSize": "Dimensione del gruppo",
        "labelGroupInfoGroupMembers": "Membri",
        "labelTimeSlotInfoStandard": "Intervalli standard",
        "labelTimeSlotInfoSpecial": "Intervalli campionati",
        "labelTimeSlotInfoTotal": "Totale intervalli",
        "labelTimeSlotInfoUsed": "Intervalli riservati",
        "labelMails": "Email",
        "labelInvoices": "Fatture",
        "labelResponsiblePersons": "Responsabili",
        "labelInvoiceAddress": "Indirizzo di fatturazione",
        "textOrganizationSelect": "Seleziona associazione...",
        "labelSingle":"singolo",
        "headerCategory": "Categoria",
        "headerBarcode": "Codice",
        "headerFirstName": "Nome",
        "headerLastName": "Cognome",
        "headerEmailType": "Tipo di email",
        "headerMailSentDateTime": "Inviato il",
        "headerRecipient": "Destinatario",
        "headerDiscipline": "Disciplina",
        "headerNr": "Nr.",
        "headerAmount": "Importo",
        "ageOfBirth": "Anno di nascita",
        "headerCity": "Città",
        "headerDate": "Data",
        "headerStartTime": "Da",
        "headerEndTime": "A",
        "headerLaneNr": "Bersaglio",
        "headerShootingRangeName": "Poligono di tiro",
        "headerOrganization": "Associazione",
        "headerActions": "Azioni",
        "headerEmail": "Email",
        "headerTimeSlotConfirmation": "Conferma intervallo",
        "headerInvoice": "Fattura",
        "headerRegistrationConfirmation": "Conferma registrazione",
        "shooteroffsetmessage": "Quanti tiratori sono già stati stampati su questa pagina?",
        "textTimeslotCount": "Numero intervalli",
        "textMailSentSuccessful": "Email inviata",
        "textNoGroupParticipant": "Nessun membro nel gruppo",
        "messageInvoiceCreated": "Fattura creata",
        "messageInvoiceCreationFailed": "Creazione della fattura non riuscita",
        "messageInvoiceDeletionConfirmation": "Sei sicuro di voler ELIMINARE questa fattura?",
        "messageInvoiceStornoConfirmation": "Sei sicuro di voler ANNULLARE questa fattura?",
        "errorGeneralText": "Si è verificato un errore. Si prega di riprovare.",
        "errorCode-ERRORORGANIZATIONNOTFOUND": "Associazione non trovata",
        "errorCode-ERRORNORESPONSIBLEPERSON": "Nessuna persona responsabile trovata per l'associazione",
        "errorCode-ERRORINVOICENOTFOUND": "Fattura non trovata",
        "errorCode-ERRORINVOICEADDRESSNOTAVAILABLE": "Indirizzo di fatturazione non disponibile",
        "errorCode-INVOICETEMPLATENOTFOUND": "Modello per la fattura non trovato. Contattaci per favore.",
        "errorCode-NORIGHTS": "Nessuna autorizzazione.",
        "errorCode-ERRORQRINVOICENOTGENERATED": "La fattura QR non è stata generata.",
        "errorCode-DISCIPLINEIDNOTFOUND": "Disciplina sconosciuta.",
        "errorCode-TIMESLOTSEMPTY": "Nessun intervallo disponibile.",
        "errorCode-ERRORRANGEURCONFIGURATIONNOTGENERATED": "La configurazione dell'intervallo non è stata generata."
    },
    "en": {
        "overview" : "Overview",
        "title" : "Organization Overview",
    }
    }
)
export default localizedTexts;