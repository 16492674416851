import LocalizedStrings from 'react-localization';
import React from "react";

const localizedTexts = new LocalizedStrings({
    "de": {
        "report": "Bericht",
        "shootingrange": "Schiessanlage",
        "organization": "Verein",
        "organisations": "Vereine",
        "shooter": "Schütze",
        "shooterId" : "Schützennummer",
        "shooters": "Schützen",
        "overview": "Übersicht",
        "save": "Speichern",
        "reset":"Reset",
        "paginatinoPrevious": "Vorherige Seite",
        "paginationNext": "Nächste Seite",
        "search": "Suche",
        "elementsPerPage": "Elemente pro Seite",
        "OrganisationNameOrCity": "Vereinsname oder Stadt",
        "ShooterOrCity":"Schütze oder Stadt",
    },
    "fr": {
        "report": "Rapport",
        "shootingrange": "Stand de tir",
        "organization": "Association",
        "organisations": "Associations",
        "shooter": "Tireur",
        "shooterId" : "No du tireur",
        "shooters": "Tireurs",
        "overview": "Aperçu",
        "save": "Enregistrer",
        "reset": "Réinitialiser",
        "paginatinoPrevious": "Page précédente",
        "paginationNext": "Page suivante"
    },
    "it": {
        "report": "Rapporto",
        "shootingrange": "Poligono di tiro",
        "organization": "Associazione",
        "organisations": "Associazioni",
        "shooter": "Tiratore",
        "shooterId": "Numero del tiratore",
        "shooters": "Tiratori",
        "overview": "Panoramica",
        "save": "Salva",
        "reset": "Ripristina",
        "paginatinoPrevious": "Pagina precedente",
        "paginationNext": "Pagina successiva"
    },
    "en": {
        "report": "Report",
        "shootingrange": "Shooting Range",
        "organization": "Organization",
        "organisations": "Organizations",
        "shooter": "Shooter",
        "shooterId": "Shooter number",
        "shooters": "Shooters",
        "overview": "Overview",
        "save": "Save",
        "reset": "Reset",
        "paginatinoPrevious": "Previous page",
        "paginationNext": "Next page"
    }
})

export default localizedTexts;