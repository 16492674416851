import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Schiessanlagen",
        "headerId":"ID",
        "headerName":"Name",
        "headerCity":"Ort",
        "headerComment":"Kommentar",
        "headerActions":"Aktionen",
        "headerDiscipline":"Disziplin",
        "headerLastSeen":"Zuletzt online",
        "labelLanes":"Scheiben",
        "labelNewRange": "Neue Schiessanlage erfassen",
        "shootingPrograms" : "Schiessprogramme",
        "settings": "Einstellungen",
        "textSiteLoading": "Seite lädt. Bitte einen Moment Geduld haben.",
        "eventSettingsDescription" : "Diese Einstellungen werden beim Deaktivieren eines Events programmiert",
        "save" : "Speichern",
        "nosettingsmanuelmode" : "Für manuelle Anlagen sind keine weitere Einstellungen verfügbar",
        "tooltipOverview" : "Übersicht",
        "tooltipShotAccounting" : "Schussabrechnung",
        "tooltipWinFireLive" : "WinFire Live"
    },
    "fr": {
        "title" : "stand de tir",
        "headerId":"ID",
        "headerName":"nom",
        "headerCity":"lieu",
        "headerComment":"commentaire",
        "headerActions":"activités",
        "headerDiscipline":"discipline",
        "headerLastSeen":"dernier en ligne",
        "labelLanes":"cibles",
        "labelNewRange": "enregistrer un nouveaux stand de tir",
        "shootingPrograms" : "exercises",
        "settings" : "paramètres",
        "textSiteLoading": "La page est en cours de chargement. Veuillez patienter un instant.",
        "eventSettingsDescription" : "Ces paramètres sont programmés lors de la désactivation d'un événement",
        "save" : "enregistrer",
        "nosettingsmanuelmode" : "Aucun autre paramètres n'est disponible pour le mode manuel",
        "tooltipOverview" : "Aperçu",
        "tooltipShotAccounting" : "Décompté des coups",
        "tooltipWinFireLive" : "WinFire Live"

    },
    "it": {
        "title" : "stand di tiro",
        "headerId":"ID",
        "headerName":"Nome",
        "headerCity":"Città",
        "headerComment":"Commento",
        "headerActions":"Azioni",
        "headerDiscipline":"Disciplina",
        "headerLastSeen":"Ultimo online",
        "labelLanes":"Bersagli",
        "labelNewRange": "Crea nuovo stand di tiro",
        "shootingPrograms" : "Programmi di tiro",
        "settings": "Impostazioni",
        "textSiteLoading": "La pagina sta caricando. Attendere un momento.",
        "eventSettingsDescription" : "Queste impostazioni vengono applicate quando un evento viene disattivato",
        "save" : "Salva",
        "nosettingsmanuelmode" : "Nessuna impostazione aggiuntiva disponibile per poligoni manuali",
        "tooltipOverview" : "Panoramica",
        "tooltipShotAccounting" : "Conteggio dei colpi",
        "tooltipWinFireLive" : "WinFire Live"
    },
    "en": {
        "title" : "Shooting Ranges",
        "headerId":"ID",
        "headerName":"Name",
        "headerCity":"City",
        "headerComment":"Comment",
        "headerActions":"Actions",
        "headerDiscipline":"Discipline",
        "headerLastSeen":"Last Online",
        "labelLanes":"Targets",
        "labelNewRange": "Create New Shooting Range",
        "shootingPrograms" : "Shooting Programs",
        "settings": "Settings",
        "textSiteLoading": "Page is loading. Please wait a moment.",
        "eventSettingsDescription" : "These settings are applied when an event is deactivated",
        "save" : "Save",
        "nosettingsmanuelmode" : "No additional settings are available for manual ranges",
        "tooltipOverview" : "Overview",
        "tooltipShotAccounting" : "Shot Accounting",
        "tooltipWinFireLive" : "WinFire Live"

    }
});
export default localizedTexts;