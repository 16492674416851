import {Component} from "react";
import {NavLink} from "react-router-dom";
import localizedTexts from "./texts/ShootAccount.texts";
import {useParams} from "react-router";

function ShootingAccountingNavigationWrapper() {
    const {rangeId, distanceId} = useParams();
    return <ShootingAccountingNavigation rangeId={rangeId} distanceId={parseInt(distanceId??"")}/>
}

export class ShootingAccountingNavigation extends Component<ShootingAccountingNavigationProps, ShootingAccountingNavigationState> {

    render() {
        return <div className="btn-group py-2" role="group" aria-label="Vertical button group">
            {/* <NavLink to="overview" className="btn btn-outline-dark">
                <span className="bi-speedometer2"> </span>{localizedTexts.overview}
            </NavLink> */}

            <NavLink to="report" className="btn btn-outline-dark">
                <span className="bi-table"> </span>{localizedTexts.report}
            </NavLink>

            <NavLink to="organisation" className="btn btn-outline-dark">
                <span className="bi-people"> </span> {localizedTexts.organization}
            </NavLink>
            <NavLink to="shooter" className="btn btn-outline-dark">
                <span className="bi-person"> </span> {localizedTexts.shooter}
            </NavLink>

            <NavLink to="shootingrange" className="btn btn-outline-dark">
                <span className="bi-bullseye"> </span>{localizedTexts.shootingrange}
            </NavLink>
        </div>
    }
}

interface ShootingAccountingNavigationState {

}

interface ShootingAccountingNavigationProps {
    rangeId: string | undefined
    distanceId: number
}

export default ShootingAccountingNavigationWrapper;