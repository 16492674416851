import LocalizedStrings from 'react-localization';

const localizedTexts = new LocalizedStrings({
    "de": {
        "report": "Bericht",
        "shootingrange": "Schiessanlage",
        "organization": "Verein",
        "shooter": "Schütze",
        "overview": "Übersicht",
    },
    "fr": {
        "report": "Rapport",
        "shootingrange": "Stand de tir",
        "organization": "Association",
        "shooter": "Tireur",
        "overview": "Aperçu",
    },
    "it": {
        "report": "Rapporto",
        "shootingrange": "Poligono di tiro",
        "organization": "Associazione",
        "shooter": "Tiratore",
        "overview": "Panoramica",
    },
    "en": {
        "report": "Report",
        "shootingrange": "Shooting Range",
        "organization": "Organization",
        "shooter": "Shooter",
        "overview": "Overview",
    }
})

export default localizedTexts;