import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import './styles/styles.css';
import ApiClientFactory from "./models/ApiClientFactory";
import localizedTexts from "./texts/EventV2OrganisationDetailView.texts";
import WinFireDropDown, { WinFireDropDownElement } from "../common/components/WinFireDropDown";
import {
    AddressWithIban,
    Discipline,
    EventRegistrationInformation, Invoice, Organization, OrganizationMailSent,
    OrganizationWithTimeSlotInformation,
    ParticipantExercises,
    PersonResponsibleForOrganization,
    TimeSlotInformation,
    Group
} from "./models/Models";
import ToastMessage from "../common/components/ToastMessage";
import PrintDocument from "../common/Helpers/PrintDocument";
import AddressFormModal from "./components/AddressFormModal";


const EventOrganisationDetailView = () => {
    const { eventId } = useParams<{ eventId: string }>();
    const { requestedOrganizationNumber } = useParams();

    const apiClient = ApiClientFactory.GetApiClient();
    const [disciplinesRegistered, setDisciplinesRegistered] = useState<Discipline[]>([]);
    const [selectedDiscipline, setSelectedDiscipline] = useState({} as Discipline)

    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<Organization|undefined>(undefined);
    const [organizationNumber, setOrganizationNumber] = useState("");
    const [availableParticipants, setAvailableParticipants] = useState<ParticipantExercises[]>([]);
    const [mandatoryCommunityProgramResults, setMandatoryCommunityProgramResults] = useState<number|undefined>(undefined);
    const [search, setSearch] = useState<string>("");
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBgClass, setToastBgClass] = useState('danger');
    const [eventInfo, setEventInfo] = useState<EventRegistrationInformation>({});
    const [timeSlotData, setTimeSlotData] = useState<TimeSlotInformation[]|undefined>([]);
    const [timeSlotReservationData, setTimeSlotReservationData] = useState<OrganizationWithTimeSlotInformation>({});
    const [responsiblePersons, setResponsiblePersons] = useState<PersonResponsibleForOrganization[]>([]);
    const [organizationGroups, setOrganizationGroups] = useState<Group[]>([]);
    const [organizationMailHistory, setOrganizationMailHistory] = useState<OrganizationMailSent[]>([]);
    const [organizationInvoices, setOrganizationInvoices] = useState<Invoice[]>([]);
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice|undefined>(undefined);
    const [invoiceInfo, setInvoiceInfo] = useState<AddressWithIban|undefined>(undefined);
    const [singleDisciplineChecked, setSingleDisciplineChecked] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            await loadData();
            setIsDataLoaded(true);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (!selectedOrganization) {
            setDisciplinesRegistered([]);
            setOrganizationNumber("");
        } else {
            setOrganizationNumber(selectedOrganization.number ?? "");
        }
    }, [selectedOrganization]);

    useEffect(() => {
        if (isDataLoaded && organizations.length > 0 && requestedOrganizationNumber) {
            const organization = organizations.find(o => o.number === requestedOrganizationNumber);
            if (organization) {
                selectOrganization(organization);
            } else {
                showErrorToast("ERRORORGANIZATIONNOTFOUND");
            }
        }
    }, [isDataLoaded, requestedOrganizationNumber]);


    // Handle key press in input field
    const handleInputKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            // User pressed Enter, initiate search
            await searchOrganizationByNumber(organizationNumber);
        }
    };

    const handleSingleDisciplineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSingleDisciplineChecked(event.target.checked);
    }

    const searchOrganizationByNumber = async (number: string) => {
        try {
            const result = await apiClient.api.eventsV2OrganizationsRegisteredDetail(`${eventId}`);
            if (result.ok) {
                const matchingOrganization = result.data.find(
                    (org: Organization) => org.number === number
                );
                if (matchingOrganization) {
                     await selectOrganization(matchingOrganization)
                } else {
                    alert(`No organization found with number: ${number}`); // Alert if no match found
                    await selectOrganization(undefined);
                }
            } else {
                setToastBgClass("danger");
                setShowToast(true);
                setToastMessage(localizedTexts.errorGeneralText??"");
                console.error("Failed to fetch organizations:", result.statusText);
            }
        } catch (error) {
            setToastBgClass("danger");
            setShowToast(true);
            setToastMessage(localizedTexts.errorGeneralText??"");
        }
    };

    function showErrorToast(errorCode: string) {
        let errorCodeTranslation = localizedTexts.getString(`errorCode-${errorCode}`);
        if (!errorCodeTranslation) {
            errorCodeTranslation = localizedTexts.errorGeneralText ?? "";
        }
        setToastMessage(`${errorCodeTranslation}`);
        setToastBgClass("danger");
        setShowToast(true);
    }

    async function loadData() {
        try {
            const eventDetailsResponse = await apiClient.api.eventsV2EventInformationRegistrationinformationDetail(eventId ?? "");
            if (eventDetailsResponse.ok) {
                setEventInfo(eventDetailsResponse.data);

                if (eventDetailsResponse.data.disciplines && eventDetailsResponse.data.disciplines.length > 0) {
                    setSelectedDiscipline(eventDetailsResponse.data.disciplines[0]);
                }
            }

            const organizationsResult = await apiClient.api.eventsV2OrganizationsRegisteredDetail(`${eventId}`);
            if (organizationsResult.ok){
                setOrganizations(organizationsResult.data);
            }

        } catch (e: any) {
            console.error(e);
        }
    }

    async function selectOrganization(organization: Organization | undefined) {
        if (!organization) {
            setSelectedOrganization(undefined);
            await selectDiscipline(undefined)
        } else {
            setSelectedOrganization(organization);
            if (organization.disciplines){
                var disciplines = eventInfo.disciplines?.filter(d => (organization?.disciplines?.findIndex(od => od.id == d.id)??-1) > -1)??[];
                setDisciplinesRegistered(disciplines);
                if (disciplines.length > 0){
                    setSelectedDiscipline(disciplines[0]);
                    setMandatoryCommunityProgramResults(organization.organizationDisciplineCategories?.find(odc => odc.disciplineId === disciplines[0].id)?.minimalParticipants)
                    try {
                        await fetchParticipantExerciseData(organization.number!, disciplines[0].id!);
                        await fetchTimeSlotData(organization.number!, disciplines[0].id!);
                        await fetchOrganisationResponsiblePersons(organization.number!);
                        await fetchGroupsForOrganization(organization.number!, disciplines[0].id!);
                        await fetchMailHistory(organization.number!);
                        await fetchInvoices(organization.number!)
                        await fetchInvoiceInfo(organization.number!)
                    } catch (e: any) {
                        showErrorToast(e.error.errorCode);
                        setSelectedOrganization(undefined);
                        await selectDiscipline(undefined)
                    }
                }
            }
        }
    }

    async function selectDiscipline(discipline: Discipline|undefined){
        if (selectedOrganization && discipline != undefined){
            await fetchParticipantExerciseData(selectedOrganization.number!, discipline.id!);
            await fetchTimeSlotData(selectedOrganization.number!, discipline.id!);
            await fetchGroupsForOrganization(selectedOrganization.number!, discipline.id!);
            await fetchMailHistory(selectedOrganization.number!);
            setSelectedDiscipline(discipline);
            setMandatoryCommunityProgramResults(selectedOrganization.organizationDisciplineCategories?.find(odc => odc.disciplineId === discipline.id)?.minimalParticipants)
        }
        else
        {
            setTimeSlotData(undefined);
            setTimeSlotReservationData({} as OrganizationWithTimeSlotInformation);
            setAvailableParticipants([]);
            setResponsiblePersons([]);
            setMandatoryCommunityProgramResults(undefined)
        }
    }

    async function fetchParticipantExerciseData(organizationNumber: string, disciplineId: number) {
        const results = await apiClient.api.eventsV2ParticipantsByOrganizationDetail2(organizationNumber, disciplineId, eventId ?? "");
        if (results.ok) {
            const participants = results.data;
            setAvailableParticipants(participants);
        } else {
            console.error('Failed to fetch participants:', results.statusText);
        }
    }

    async function fetchTimeSlotData(organizationNumber: string, disciplineId: number) {
        const results = await apiClient.api.eventsV2OrganizationsTimeslotsDetail(organizationNumber, eventId ?? "");
        if (results.ok) {
            let timeslotData = results.data;
            setTimeSlotData(timeslotData);
        } else {
            console.error('Failed to fetch timeslots:', results.statusText);
        }
        const timeSlotInfoResult = await apiClient.api.eventsV2OrganizationsTimeslotsInformationDetail(organizationNumber, disciplineId, eventId??"");
        if (timeSlotInfoResult.ok){
            setTimeSlotReservationData(timeSlotInfoResult.data);
        } else {
            console.error('Failed to fetch timeslotdata:', timeSlotInfoResult.statusText);
        }
    }

    async function fetchOrganisationResponsiblePersons(organizationNumber: string) {
        const results = await apiClient.api.eventsV2OrganizationsResponsiblepersonsDetail(organizationNumber, eventId ?? "");
        if (results.ok) {
            let responsibleForOrganizations = results.data;
            setResponsiblePersons(responsibleForOrganizations);
        } else {
            showErrorToast(results.error.errorCode ?? "");
        }
    }

    async function fetchGroupsForOrganization(organizationNumber: string, disciplineId: number) {
        const groupExercise = eventInfo.disciplines?.find(d => d.id == disciplineId)?.shootingProgramDefinitions?.find(s => s.isGroupExercise);
        //const groupExercise = selectedDiscipline.shootingProgramDefinitions?.find(s => s.isGroupExercise);
        if (groupExercise != undefined)
        {
            const results = await apiClient.api.eventsV2OrganizationsGroupsDetail(organizationNumber, groupExercise.id!, eventId ?? "");
            if (results.ok) {
                let groupsForOrganization = results.data;
                setOrganizationGroups(groupsForOrganization);
            } else {
                showErrorToast(results.error.errorCode);
            }
        }
    }

    async function fetchMailHistory(organizationNumber: string){
        const mailHistoryResult = await apiClient.api.eventsV2OrganizationsSentmailsDetail(organizationNumber, `${eventId}`);
        if (mailHistoryResult.ok){
            setOrganizationMailHistory(mailHistoryResult.data);
        }
    }

    async function fetchInvoices(organizationNumber: string){
        const invoiceResult = await apiClient.api.eventsV2FinancialInvoiceDetail2(organizationNumber, `${eventId}`);
        if (invoiceResult.ok){
            setOrganizationInvoices(invoiceResult.data);
            setSelectedInvoice(undefined);
        }
    }

    async function fetchInvoiceInfo(organizationNumber: string){
        const invoiceInfoResult = await apiClient.api.eventsV2OrganizationsBillinginfoDetail(organizationNumber, `${eventId}`);
        if (invoiceInfoResult.ok){
            setInvoiceInfo(invoiceInfoResult.data);
        }
        else
        {
            setInvoiceInfo(undefined);
            console.error('Failed to fetch billing infos for organization:', invoiceInfoResult.statusText);
        }
    }

    async function PrintTimeSlotConfirmation(organizationNumber: string) {
        const result = await apiClient.api.eventsV2DocumentTimeslotconfirmationDisciplineDetail(organizationNumber, selectedDiscipline.id!, eventId ?? "");
        if (result.ok) {
            await PrintDocument.printPdfDocument(result);
        } else {
            showErrorToast(result.error.errorCode);
        }
    }

    async function SendTimeSlotConfirmationMail(organizationNumber: string) {
        const result = await apiClient.api.eventsV2DocumentTimeslotconfirmationDisciplineCreate(organizationNumber, selectedDiscipline.id!, eventId ?? "");
        if (result.ok) {
            setShowToast(true);
            setToastMessage(localizedTexts.textMailSentSuccessful ?? "");
            setToastBgClass("success");
            await fetchMailHistory(organizationNumber);
        } else {
            showErrorToast(result.error.errorCode);
        }
    }

    async function PrintRegistrationConfirmation(organizationNumber: string) {
        const result = await apiClient.api.eventsV2DocumentRegistrationconfirmationDetail(organizationNumber, eventId ?? "");
        if (result.ok) {
            await PrintDocument.printPdfDocument(result);
        } else {
            showErrorToast(result.error.errorCode);
        }
    }

    async function SendRegistrationConfirmationMail(organizationNumber: string) {
        const result = await apiClient.api.eventsV2DocumentRegistrationconfirmationCreate(organizationNumber, eventId ?? "");
        if (result.ok) {
            setShowToast(true);
            setToastMessage(localizedTexts.textMailSentSuccessful ?? "");
            setToastBgClass("success");
            await fetchMailHistory(organizationNumber);
        } else {
            showErrorToast(result.error.errorCode);
        }
    }

    async function PrintInvoice(invoiceNr: number) {
        const result = await apiClient.api.eventsV2DocumentInvoiceDetail(invoiceNr, eventId ?? "");
        if (result.ok) {
            await PrintDocument.printPdfDocument(result);
        } else {
            showErrorToast(result.error.errorCode);
        }
    }

    async function SendInvoiceMail(organizationNumber: string, invoiceNr: number) {
        const result = await apiClient.api.eventsV2DocumentInvoiceCreate(invoiceNr, eventId ?? "");
        if (result.ok) {
            setShowToast(true);
            setToastMessage(localizedTexts.textMailSentSuccessful ?? "");
            setToastBgClass("success");
            await fetchMailHistory(organizationNumber);
        } else {
            showErrorToast(result.error.errorCode);
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.toLowerCase());
    }

    const handleRowSelect = (invoice: Invoice) => {
        setSelectedInvoice(invoice);
    };

    function addMinutes(date: Date, minutes: number): Date {
        date.setMinutes(date.getMinutes() + minutes);
        return date;
    }

    return <div>
            {showToast && (
                <div id="toast-container" className="position-fixed top-0 start-50 translate-middle-x p-3"
                     style={{zIndex: 11}}>
                    <ToastMessage id="errorToast" message={toastMessage} onClose={() => setShowToast(false)} role="alert" bgClass={toastBgClass}/>
                </div>
            )}
            <div className="container" style={{maxWidth: '95%'}}>
                <h1><span className="bi-people"/> {localizedTexts.title}</h1>
                <div className="row">
                <div className="col-3 d-flex align-items-center" style={{zIndex: '15'}}>
                    <span className="h3">{localizedTexts.labelOrganization}: </span>&nbsp;&nbsp;
                    <WinFireDropDown
                        buttonText={selectedOrganization?.name ?? localizedTexts.textOrganizationSelect ?? ""}
                        elements={organizations.map(o => ({
                            sourceObject: o,
                            key: o.number,
                            text: o.name,
                            selectFunction: selectOrganization
                        } as WinFireDropDownElement))}
                        elementFilter={(a: string, b: Organization) => {
                            if (!b) {
                                return true;
                            }

                            return b.name?.toLowerCase().includes(a.toLowerCase()) || b.number?.includes(a);
                        }}
                    />
                </div>
                    <div className="col-2 d-flex align-items-center flex-wrap">
                        <button className={"btn btn-primary me-2"} disabled={!selectedOrganization}
                                onClick={async () => {
                                    if (selectedOrganization) {
                                        const result = await apiClient.api.eventsV2EventInformationSetmyselftoorganizationfunctionCreate(selectedOrganization.number!, `${eventId}`);
                                        if (result.ok) {
                                            selectOrganization(selectedOrganization);
                                            setToastBgClass("success");
                                            setToastMessage("Erfolgreich zugewiesen");
                                            setShowToast(true);
                                        }
                                    }

                                }}><span className={"bi-person-arms-up"}/></button>
                        <button className={"btn btn-danger me-2"} disabled={!selectedOrganization}
                                onClick={async () => {
                                    if (selectedOrganization) {
                                        const result = await apiClient.api.eventsV2EventInformationSetmyselftoorganizationfunctionCreate("REMOVE", `${eventId}`);
                                        if (result.ok) {
                                            selectOrganization(selectedOrganization);
                                            setToastBgClass("success");
                                            setToastMessage("Erfolgreich entfernt");
                                            setShowToast(true);
                                        }
                                    }

                                }}><span className={"bi-person-x"}/></button>
                        <input placeholder={"Vereinsnummer"}
                               value={organizationNumber}
                               style={{width: '15ch'}}
                               onChange={(e) => setOrganizationNumber(e.target.value)}
                               onKeyDown={handleInputKeyDown}
                        />
                        {
                            selectedDiscipline ?
                                selectedOrganization?.organizationDisciplineCategories?.filter(odc => odc.disciplineId == selectedDiscipline?.id)
                                    .map(odc => <span
                                        className="ms-2">{localizedTexts.headerCategory + ":" + odc?.category} </span>) : ""
                        }
                    </div>
                    <div className="col-2 d-flex align-items-center" style={{zIndex: '15'}}>
                        <span className={"h3"}>{localizedTexts.labelDiscipline}: </span>&nbsp;&nbsp;<WinFireDropDown
                        buttonText={selectedDiscipline.name ?? ""}
                        elements={disciplinesRegistered?.sort((a, b) => a.id! - b.id!).map(d => {
                            return {
                                key: d.name ?? "", text: d.name, sourceObject: d, selectFunction: (d: Discipline) => {
                                    selectDiscipline(d)
                                }
                            } as WinFireDropDownElement
                        }) ?? []}
                        elementFilter={(a: string, b: Discipline) => (b.name?.toLowerCase().indexOf(a.toLowerCase()) ?? -1) > -1}/>
                </div>

                <div className="col-3 d-flex align-items-center">
                    <span className="h3">{localizedTexts.labelSearch}: </span>&nbsp;&nbsp;
                    <input placeholder="suchen" onChange={handleChange}/>
                </div>
            </div>
            <div className="row mt-2 mb-2">
                <div className="col-3">
                    <table className="table table-responsive">
                        <thead></thead>
                        <tbody>
                        <tr className={"align-middle"}>
                            <th>{localizedTexts.headerTimeSlotConfirmation} {selectedDiscipline?.name ?? ""}</th>
                            <td className={"text-end"} style={{width: "30%"}}>
                                <button className={"btn btn-primary"}
                                        style={{margin: "2%"}}
                                        disabled={selectedOrganization === undefined || timeSlotData === undefined || timeSlotData.length === 0}
                                        onClick={() => selectedOrganization && PrintTimeSlotConfirmation(selectedOrganization.number!)}><span className="bi-printer"/></button>
                                <button className={"btn btn-primary"}
                                        style={{margin: "2%"}}
                                        disabled={selectedOrganization === undefined || timeSlotData === undefined || timeSlotData.length === 0}
                                        onClick={() => selectedOrganization && SendTimeSlotConfirmationMail(selectedOrganization.number!)}><span className="bi-envelope"/></button>
                            </td>
                        </tr>
                        <tr className={"align-middle"}>
                            <th>{localizedTexts.headerRegistrationConfirmation}</th>
                            <td className={"text-end"} style={{width: "30%"}}>
                                <button className={"btn btn-primary"}
                                        style={{margin: "2%"}}
                                        disabled={selectedOrganization === undefined}
                                        onClick={() => selectedOrganization && PrintRegistrationConfirmation(selectedOrganization.number!)}><span className="bi-printer"/></button>
                                <button className={"btn btn-primary"}
                                        style={{margin: "2%"}}
                                        disabled={selectedOrganization === undefined}
                                        onClick={() => selectedOrganization && SendRegistrationConfirmationMail(selectedOrganization.number!)}><span className="bi-envelope"/></button>
                            </td>
                        </tr>
                        <tr className={"align-middle"}>
                            <th>{localizedTexts.headerInvoice}</th>
                            <td className={"text-end"} style={{width: "30%"}}>
                                <button className={"btn btn-primary"}
                                        style={{margin: "2%"}}
                                        disabled={selectedInvoice === undefined  || selectedInvoice.invoiceNr === undefined}
                                        onClick={() => selectedOrganization && PrintInvoice(selectedInvoice?.invoiceNr!)}><span className="bi-printer"/></button>
                                <button className={"btn btn-primary"}
                                        style={{margin: "2%"}}
                                        disabled={selectedInvoice === undefined  ||  selectedInvoice.invoiceNr === undefined}
                                        onClick={() => selectedOrganization && SendInvoiceMail(selectedOrganization.number!, selectedInvoice?.invoiceNr!)}><span className="bi-envelope"/></button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-3">
                </div>
                <div className="col-5 d-flex justify-content-end align-items-start mt-2">
                    <div>
                        <div className="d-flex">
                            <h5 className={"mb-0"}>{localizedTexts.labelInvoiceAddress}</h5>
                            <a data-bs-toggle="modal" data-bs-target="#addressFormModal"><span className="ms-2 bi-pencil" hidden={selectedOrganization == undefined}></span></a>
                        </div>
                        <table className="table table-responsive w-auto float-end" hidden={invoiceInfo == undefined}>
                            <tbody>
                            <tr>
                                <td>{invoiceInfo?.firstName}</td>
                                <td>{invoiceInfo?.lastName}</td>
                                <td>{invoiceInfo?.street} {invoiceInfo?.streetNr}</td>
                                <td>{invoiceInfo?.zipCode} {invoiceInfo?.city}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>{invoiceInfo?.iban}</td>
                                <td colSpan={2} className={invoiceInfo?.email == "" ? "table-danger" : ""}>{invoiceInfo?.email}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
                <div className="row mt-2 mb-2">
                    <div className="col-4">
                    <h4>{localizedTexts.labelResponsiblePersons}</h4>
                    <table className="table table-striped table-responsive sticky">
                        <thead>
                        <tr>
                            <th>{localizedTexts.headerLastName}</th>
                            <th>{localizedTexts.headerFirstName}</th>
                            <th>{localizedTexts.headerEmail}</th>
                            <th>{localizedTexts.headerCity}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {responsiblePersons.map(p => {
                            return <tr>
                                <td>{p.lastName}</td>
                                <td>{p.firstName}</td>
                                <td>{p.email}</td>
                                <td>{p.zipCode} {p.city}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>
                <div className={"col-4"}>
                    <h4>{localizedTexts.labelMails}</h4>
                    <table className="table table-striped table-responsive sticky">
                        <thead>
                        <tr>
                            <th>{localizedTexts.headerEmailType}</th>
                            <th>{localizedTexts.headerMailSentDateTime}</th>
                            <th>{localizedTexts.headerRecipient}</th>
                            <th>{localizedTexts.headerDiscipline}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {organizationMailHistory.map(m => {
                            return <tr>
                                <td>{m.emailType}</td>
                                <td>{m.mailSentDateTime}</td>
                                <td>{m.recipient}</td>
                                <td>{m.discipline}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>
                <div className={"col-3"}>
                    <h4>{localizedTexts.labelInvoices}</h4>
                    <table className="table table-striped table-responsive sticky">
                        <thead>
                        <tr>
                            <th></th>
                            <th>{localizedTexts.headerDate}</th>
                            <th>{localizedTexts.headerNr}</th>
                            <th>{localizedTexts.headerAmount}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {organizationInvoices.map(i => {
                            const isOverdue = (i.dueDate && new Date(i.dueDate) < new Date()) && i.invoiceStatusId != 3;
                            const isOpen = i.invoiceStatusId == 2;
                            const isPayed = i.invoiceStatusId == 3;
                            const isVoided = i.invoiceStatusId == 4;
                            return (
                                <tr key={i.invoiceNr}>
                                    <td className={isVoided ? "" : isPayed ? "bg-success" : isOverdue ? "bg-danger" : isOpen ? "bg-warning" : ""}>
                                        <input
                                            type="radio"
                                            name="invoice-select"
                                            checked={selectedInvoice?.invoiceNr === i.invoiceNr}
                                            onChange={() => handleRowSelect(i)}
                                        />
                                    </td>
                                    <td style={{textDecoration: isVoided ? "line-through" : "none"}}
                                        className={isVoided ? "" : isPayed ? "bg-success" : isOverdue ? "bg-danger" : isOpen ? "bg-warning" : ""}>{new Date(i.date!).toLocaleDateString()}</td>
                                    <td style={{textDecoration: isVoided ? "line-through" : "none"}}
                                        className={isVoided ? "" : isPayed ? "bg-success" : isOverdue ? "bg-danger" : isOpen ? "bg-warning" : ""}>{i.invoiceNr}</td>
                                    <td style={{textDecoration: isVoided ? "line-through" : "none"}}
                                        className={isVoided ? "" : isPayed ? "bg-success" : isOverdue ? "bg-danger" : isOpen ? "bg-warning" : ""}>{i.amount}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <div className="col-1">
                    <div className="d-flex justify-content-between">
                        <button className={"btn btn-success"} style={{marginRight: "5%"}} disabled={selectedOrganization == undefined} onClick={async () =>
                            {
                                let selectedDisciplineId = selectedDiscipline.id ?? 0;
                                if (!singleDisciplineChecked) selectedDisciplineId = 0;

                                const result = await apiClient.api.eventsV2FinancialInvoiceCreate(organizationNumber, selectedDisciplineId, `${eventId}`)
                                if (result.ok) {
                                    setToastBgClass("success")
                                    setToastMessage(localizedTexts.messageInvoiceCreated ?? "OK")
                                    setShowToast(true)
                                }
                                else {
                                    setToastBgClass("danger")
                                    setToastMessage(localizedTexts.messageInvoiceCreationFailed ?? "FAIL")
                                    setShowToast(true)
                                }
                                await fetchInvoices(organizationNumber);
                            }}>
                            <span className={"bi-plus-lg fs-6"}></span>
                        </button>

                        <button className={"btn btn-danger"} style={{marginRight: "90%"}} disabled={!selectedInvoice || selectedInvoice?.invoiceStatusId === 3 || selectedInvoice?.invoiceStatusId === 4}
                                onClick={async () => {
                                    const confirmed = selectedInvoice?.invoiceStatusId === 1 ? window.confirm(localizedTexts.messageInvoiceDeletionConfirmation)
                                                        : selectedInvoice?.invoiceStatusId === 2 ? window.confirm(localizedTexts.messageInvoiceStornoConfirmation)
                                                        : false;
                                    if (confirmed && selectedInvoice) {
                                        await apiClient.api.eventsV2FinancialInvoiceDelete(selectedInvoice?.invoiceNr!, `${eventId}`, organizationNumber)
                                        await fetchInvoices(organizationNumber);
                                    }
                                }}>
                            <span className={"bi-x-lg fs-6"}></span>
                        </button>
                    </div>
                    <label htmlFor="allDisciplinesCheckbox">
                        <input type="checkbox" id="allDisciplinesCheckbox"
                               checked={singleDisciplineChecked}
                               onChange={handleSingleDisciplineChange}
                        />
                        &nbsp;{localizedTexts.labelSingle}
                    </label>
                </div>
                </div>
                <div className="row">
                <table className="table table-striped table-responsive sticky">
                    <thead>
                    <tr>
                        <th className="sticky"><span className="bi-printer"/></th>
                        <th className="sticky">{localizedTexts.headerBarcode}</th>
                        <th className="sticky">{localizedTexts.headerLastName}</th>
                        <th className="sticky">{localizedTexts.headerFirstName}</th>
                        <th className="sticky">{localizedTexts.ageOfBirth}</th>
                        <th className="sticky">{localizedTexts.headerCity}</th>
                        {selectedDiscipline?.shootingProgramDefinitions?.map(sp => (
                            <th key={"sp" + sp.id} className="rotate sticky" style={{textAlign: 'center'}}>
                                <div>{sp.name}</div>
                            </th>
                        ))}
                        <th className="sticky">{localizedTexts.headerOrganization}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {availableParticipants
                        .sort((a, b) => a.person?.lastName?.localeCompare(b.person?.lastName ?? '') ?? 0)
                        .filter(p => {
                            if (search && search != "") {
                                return ((p.person?.lastName?.toLowerCase().indexOf(search.toLowerCase()) ?? -1) > -1)
                                    || ((p.person?.firstName?.toLowerCase().indexOf(search.toLowerCase()) ?? -1) > -1)
                                    || ((p.person?.licenseNumber?.toLowerCase().indexOf(search.toLowerCase()) ?? -1) > -1)
                                    || ((p.participantNumber?.toLowerCase().indexOf(search.toLowerCase()) ?? -1) > -1)
                            }
                            return true;
                        })
                        .map(p => (
                            <tr key={p.participantNumber}>
                                <td></td>
                                <td>{p.participantNumber}</td>
                                <td>{p.person?.lastName}</td>
                                <td>{p.person?.firstName}</td>
                                <td>{new Date(p.person?.birthday!).getFullYear()}</td>
                                <td>{p.person?.city}</td>
                                {selectedDiscipline.shootingProgramDefinitions?.map(sp => {
                                    const registrationCount = p.exerciseRegistrations?.find(esp => esp.shootingProgramDefinitionId == sp.id)?.count || 0
                                    const isCommunityProgram = sp.name == "Verein";
                                    const isCommunityProgramMandatory = availableParticipants.length >= (mandatoryCommunityProgramResults ?? 0);
                                    const cellBackground = isCommunityProgramMandatory && isCommunityProgram && registrationCount == 0 ? "bg-danger" : "";
                                    return (
                                        <td  className={cellBackground} style={{fontWeight: registrationCount > 0 ? 'bold' : 'normal', textAlign: 'center'}}>{registrationCount > 0 ? registrationCount : 0}</td>
                                    );
                                })}
                                <td>{p.organization?.name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="card mb-3 mt-3">
                <div className={"card-header"}>
                    <h5>{localizedTexts.labelTimeSlotInfoTitle}</h5>
                </div>
                <div className={"card-body"}>
                    <table className={"table table-striped"}>
                        <tbody>
                        <tr>
                            <th>{localizedTexts.labelTimeSlotInfoStandard}</th>
                            <td>{timeSlotReservationData.organizationTimeSlotInformation?.find(otsi => otsi.disciplineId == selectedDiscipline.id)?.standardTimeSlots}</td>
                        </tr>
                        <tr>
                            <th>{localizedTexts.labelTimeSlotInfoSpecial}</th>
                            <td>{timeSlotReservationData.organizationTimeSlotInformation?.find(otsi => otsi.disciplineId == selectedDiscipline.id)?.specialTimeSlots}</td>
                        </tr>
                        <tr>
                            <th>{localizedTexts.labelTimeSlotInfoTotal}</th>
                            <td>{(timeSlotReservationData.organizationTimeSlotInformation?.find(otsi => otsi.disciplineId == selectedDiscipline.id)?.standardTimeSlots ?? 0) + (timeSlotReservationData.organizationTimeSlotInformation?.find(otsi => otsi.disciplineId == selectedDiscipline.id)?.specialTimeSlots ?? 0)}</td>
                        </tr>
                        <tr>
                            <th>{localizedTexts.labelTimeSlotInfoUsed}</th>
                            <td>{timeSlotReservationData.organizationTimeSlotInformation?.find(otsi => otsi.disciplineId == selectedDiscipline.id)?.usedTimeSlots}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row mt-3">
                <table className="table table-striped table-responsive sticky">
                    <thead>
                    <tr>
                        <th><span className="bi-printer"/></th>
                        <th>{localizedTexts.headerDate}</th>
                        <th>{localizedTexts.headerShootingRangeName}</th>
                        <th>{localizedTexts.headerLaneNr}</th>
                        <th>{localizedTexts.headerStartTime}</th>
                        <th>{localizedTexts.headerEndTime}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {timeSlotData != undefined ? timeSlotData
                        .filter(t => t.registeredDisciplineId === selectedDiscipline.id)
                        .map(t => (
                            <tr key={t.shootingRangeName + t.startTime!.toString() + t.laneNr}>
                                <td className={"bg-" + (t.stateId == 3 ? "success-subtle" : "danger-subtle")}></td>
                                <td className={"bg-" + (t.stateId == 3 ? "success-subtle" : "danger-subtle")}>{new Date(t.startTime!).toLocaleDateString([], {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric'
                                })}</td>
                                <td className={"bg-" + (t.stateId == 3 ? "success-subtle" : "danger-subtle")}>{t.shootingRangeName}</td>
                                <td className={"bg-" + (t.stateId == 3 ? "success-subtle" : "danger-subtle")}>{t.laneNr}</td>
                                <td className={"bg-" + (t.stateId == 3 ? "success-subtle" : "danger-subtle")}>{new Date(t.startTime!).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}</td>
                                <td className={"bg-" + (t.stateId == 3 ? "success-subtle" : "danger-subtle")}>{addMinutes(new Date(t.startTime!), t.durationInMinutes ?? 0).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}</td>
                            </tr>
                        )) : ""}
                    </tbody>
                </table>
            </div>
            <div className="card mb-3 mt-3">
                <div className={"card-header"}>
                    <h5>{localizedTexts.labelGroupInfoTitle}</h5>
                </div>
                <div className={"card-body"}>
                    <table className={"table table-striped"}>
                        <thead>
                        <tr>
                            <th style={{width: "25%"}}>{localizedTexts.labelGroupInfoGroupName}</th>
                            <th style={{width: "10%"}}>{localizedTexts.labelGroupInfoGroupSize}</th>
                            <th>{localizedTexts.labelGroupInfoGroupMembers}</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {organizationGroups != undefined
                            ? organizationGroups.map(g => {
                                return (
                                    <tr key={g.id} className={g.participantsCount! !== g.maxGroupSize! ? "table-warning" : ""}>
                                        <td>
                                            <i className="bi bi-people"></i>&nbsp;&nbsp;{g.groupName}
                                        </td>
                                        <td>{g.participantsCount}</td>
                                        {
                                            g.groupParticipants?.length ? (
                                                g.groupParticipants?.map(gp => (
                                                    <td key={`participant-${g.id}-${gp.participantId}`}>
                                                        {gp.person?.lastName} {gp.person?.firstName}
                                                    </td>
                                                ))
                                                ) : (<td>{localizedTexts.textNoGroupParticipant}</td>
                                            )
                                        }
                                    </tr>
                                );
                            })
                            : ""}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        {invoiceInfo ? (
            <AddressFormModal
            initialAddress={invoiceInfo} onSubmit={async (address) =>
                {
                    await apiClient.api.eventsV2OrganizationsBillinginfoCreate(selectedOrganization?.number ?? "", eventId ?? "", address)
                    await fetchInvoiceInfo(selectedOrganization?.number ?? "")
                }
            }
        />) : ""}
    </div>
};

export default EventOrganisationDetailView;