import LocalizedStrings from 'react-localization';

const localizedTexts = new LocalizedStrings({
    "de": {
        "city": "Stadt",
        "countShooter": "# Schützen",
        "shooterName": "Name",
        "shooterSurname": "Nachname",
        "phoneNumber": "Telefonnummer",
        "comment": "Kommentar",
        "birthDate": "Geburtsdatum",
        "createShooter": "Schütze erstellen",
        "editShooter" : "Schütze bearbeiten"
    },
    "fr": {
        "city": "Ville",
        "countShooter": "# Tireurs",
        "shooterName": "Prénom",
        "shooterSurname": "Nom",
        "phoneNumber": "Numéro de téléphone",
        "comment": "Commentaire",
        "birthDate": "Date de naissance",
        "createShooter": "Créer un tireur",
        "editShooter": "Modifier le tireur"
    },
    "it": {
        "city": "Città",
        "countShooter": "# Tiratori",
        "shooterName": "Nome",
        "shooterSurname": "Cognome",
        "phoneNumber": "Numero di telefono",
        "comment": "Commento",
        "birthDate": "Data di nascita",
        "createShooter": "Creare tiratore",
        "editShooter": "Modificare tiratore"
    },
    "en": {
        "city": "City",
        "countShooter": "# Shooters",
        "shooterName": "First name",
        "shooterSurname": "Last name",
        "phoneNumber": "Phone number",
        "comment": "Comment",
        "birthDate": "Date of birth",
        "createShooter": "Create shooter",
        "editShooter": "Edit shooter"
    }
})

export default localizedTexts;