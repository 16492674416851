import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "overview" : "Schiessanlässe",
        "headerName":"Name",
        "headerType":"Anlass Art",
        "headerYear":"Jahr",
        "headerActions":"Aktionen",
        "newEvent":"Neuer Anlass erstellen",
        "toEvent":"Zum Anlass"
    },
    "fr": {
        "overview" : "Fêtes de tir",
        "headerName":"Nom",
        "headerYear":"Année",
        "headerType":"Manifestation type",
        "headerActions":"Actions",
        "newEvent":"Créer une nouvelle manifestation",
        "toEvent":"Vers manifestation"
    },
    "it": {
        "overview" : "Eventi di tiro",
        "headerName":"Nome",
        "headerType":"Tipo di evento",
        "headerYear":"Anno",
        "headerActions":"Azioni",
        "newEvent":"Crea nuovo evento",
        "toEvent":"Vai all'evento"

    },
    "en": {
        "overview" : "Shooting Events",
        "headerName":"Name",
        "headerType":"Event Type",
        "headerYear":"Year",
        "headerActions":"Actions",
        "newEvent":"Create New Event",
        "toEvent":"Go to Event"

    }
});
export default localizedTexts;