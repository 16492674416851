import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Neuer Anlass",
        "labelName":"Anlass Name",
        "labelShortName":"Anlass Kurzname",
        "labelEventYear":"Jahr",
        "labelType":"Anlass Art",
        "labelEventLocation":"Ort",
        "labelEventOrganizer":"Organisator",
        "labelDiscipline":"Disziplin",
        "labelField":"Feld",
        "labelShootingProgramDefinitions": "Stiche",
        "labelWeapon":"Waffe",
        "labelButtonSelectShootingProgramDefinition":"Stich auswählen...",
        "messageSaved":"Anlass gespeichert",
        "buttonNext":"Weiter",
        "buttonBack":"Zurück",
        "buttonSave":"Speichern",
        "wfHeaderSelectType":"Anlasstyp auswählen",
        "wfHeaderEventDetail":"Anlass Informationen",
        "wfHeaderEventDisciplineSelection":"Disziplinen auswählen",
        "wfHeaderEventFieldSelection":"Disziplinen & Felder auswählen",
        "wfHeaderEventShootingProgramDefinition":"Stiche definieren"
    },
    "fr": {
        "title" : "Nouvelle manifestation",
        "labelName":"Manifestation nom",
        "labelShortName":"Manifestation nom court",
        "labelType":"Manifestation type",
        "labelEventLocation":"Lieu",
        "labelEventOrganizer":"Organisateur",
        "messageSaved":"Manifestation enregistrée "
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;