import {useParams} from "react-router";
import React, {Component} from "react";
import localizedTexts from "./texts/EventParticipantsOverview.texts";
import {Participant, ParticipantWithStatus} from "./models/Models";
import ApiClientFactory from "./models/ApiClientFactory";
import {NavLink} from "react-router-dom";

function EventParticipantsOverviewWrapper(){
    let {eventId} = useParams();
    return <EventParticipantsOverview eventId={eventId} />
}

export class EventParticipantsOverview extends Component<EventParticipantsProps, EventParticipantsState>{
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: EventParticipantsProps) {
        super(props);

        this.state = {
            participants: [],
            isLoading: true,
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        try {
            var participantResult = await this.apiClient.api.eventsParticipantsDetail(`${this.props.eventId}`);
            if (participantResult.ok) {
                this.setState({participants: participantResult.data, isLoading: false});
            }
        } catch (e: any) {

        }
    }

    async removeParticipant(participant: Participant) {
        if (window.confirm(localizedTexts.textSaveToDelete!.replace("{name}", `${participant.person?.lastName!} ${participant.person?.firstName!}`))){
            var removeResult = await this.apiClient.api.eventsParticipantsDelete(participant.participantNumber!, this.props.eventId!)
            if (removeResult.ok) {
                this.loadData();
            }else{
                alert("Ned geklappt...");
            }
        }
    }

    render(){
        if (this.state.isLoading){
            return <div>
                <h1>{localizedTexts.title}</h1>
                <h4>{localizedTexts.textSiteLoading}</h4>
            </div>
        }

        return <div>
            <h1>{localizedTexts.title}</h1>
            <table className={"table table-striped table-responsive"} style={{ verticalAlign: "middle"}}>
                <thead>
                <tr>
                    <th>{localizedTexts.headerBarcode}</th>
                    <th>{localizedTexts.headerStatus}</th>
                    <th>{localizedTexts.headerLastName}</th>
                    <th>{localizedTexts.headerFirstName}</th>
                    <th>{localizedTexts.headerCity}</th>
                    <th>{localizedTexts.headerOrganization}</th>
                    <th>{localizedTexts.headerActions}</th>
                </tr>
                </thead>
                <tbody>
                {
                    this.state.participants.map((p) => {
                        return <tr>
                            <td  style={{width: "10ch"}}>{p.participantNumber}</td>
                            <td style={{ textAlign: "center",  justifyContent: "center", width: "20px"}}>
                                <div style={{
                                    width: "15px",
                                    height: "15px",
                                    borderRadius: "50%",
                                    marginLeft: "10px",
                                    backgroundColor: (() => {
                                        if (p.registeredExercisesCount === 0) {
                                            return "red";
                                        } else if (p.registeredExercisesCount === p.exercisesWithResultsCount) {
                                            return "green";
                                        } else {
                                            return "yellow";
                                        }
                                    })()
                                }} title={`${localizedTexts.toolTipRegisteredExercises}: ${p.registeredExercisesCount}, ${localizedTexts.toolTipExercisesWithResult}: ${p.exercisesWithResultsCount}`}>
                                </div>
                            </td>
                            <td>{p.person?.lastName}</td>
                            <td>{p.person?.firstName}</td>
                            <td>{p.person?.city}</td>
                            <td>{p.organization?.name}</td>
                            <td>
                                <NavLink className={"btn btn-primary"} data-toggle="tooltip" data-placement="top" title={localizedTexts.labelToRegistration}
                                         to={`/event/${this.props.eventId}/participants/register/${p.participantNumber}`}><span
                                    className={"bi-pencil-square"}> </span></NavLink>&nbsp;
                                <NavLink className={"btn btn-primary"}data-toggle="tooltip" data-placement="top" title={localizedTexts.labelToResultEntry}
                                         to={`/event/${this.props.eventId}/participants/result/${p.participantNumber}`}><span
                                    className={"bi-file-spreadsheet-fill"}> </span></NavLink>&nbsp;
                                <NavLink className={"btn btn-primary"} data-toggle="tooltip" data-placement="top" title={localizedTexts.labelToSettlement}
                                         to={`/event/${this.props.eventId}/participants/settlement/${p.participantNumber}`}><span
                                    className={"bi-clipboard-check-fill"}> </span></NavLink>&nbsp;
                                <button className={'btn btn-danger'} onClick={() => this.removeParticipant(p)}><span
                                    className={"bi-trash3-fill"}> </span></button>
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    }
}

interface EventParticipantsState{
    isLoading: boolean;
    participants: ParticipantWithStatus[];
}

interface EventParticipantsProps{
    eventId: string|undefined;
}

export default EventParticipantsOverviewWrapper;