import localizatedTexts from "./texts/standard.texts";
import localizatedShootingRangeTexts from "./texts/shootingrange.texts";
import {useParams} from "react-router";
import React, {useEffect, useState} from "react";
import ApiClientFactory from "../../models/ApiClientFactory";

function ShootingRangeWrapper(){
    const {rangeId, distanceId} = useParams();
    return <ShootingRange rangeId={rangeId} distanceId={parseInt(distanceId??"")??0}/>
}

interface ShootingRangeProps {
    rangeId: string | undefined
    distanceId: number
}

const ShootingRange : React.FC<ShootingRangeProps> = ({ rangeId, distanceId }) => {
    const [selectedShootingRange, setSelectedShootingRange] = useState<string>("")
    const [selectedDistance , setSelectedDistance] = useState<number>(0)
    const apiClient = ApiClientFactory.GetApiClient();
    const [syncState, setSyncState] = useState<string>("nosync")


    useEffect(
        () => {
            if(selectedShootingRange === "" && selectedDistance === 0 && rangeId !=="" &&distanceId !== 0) {
                setSelectedDistance(distanceId)
                setSelectedShootingRange(rangeId??"")
            }
            console.log(rangeId, distanceId)
        },
        [rangeId, distanceId]
    )

    const updateShootingRange = async () => {
        try{
            const result =  await apiClient.api.coreShotAccountingSendshotaccountingshootersCreate(rangeId??"", distanceId)
            if(result.ok){
                setSyncState("ok")
            }else{
                setSyncState("error")
            }
        }catch(e){
            setSyncState("error")
        }
    }

    return <div><h1>
        {localizatedTexts.shootingrange}
    </h1>
        <div className={"container-fluid "}>
            <div className={"row"}>
                <div className={"col-md-9 shadow rounded p-3 m-3"}>
                    <h2>{localizatedShootingRangeTexts.sync}</h2>
                    <table className={"table table-striped table-hover table-bordered"}>
                        <thead>
                        <th className={"col-6"}></th>
                        <th>{localizatedShootingRangeTexts.Status}</th>
                    </thead>
                        <tbody className={"table-hover"}>
                        <tr>
                            <td className={"text-end"}><button onClick={() => updateShootingRange()} className={"btn btn-primary"}>{localizatedShootingRangeTexts.sync}</button></td>
                            {syncState === "ok" ?
                                <td className={"text-start align-content-lg-center"}><h3><span className={"badge text-bg-success"}> {localizatedShootingRangeTexts.synced}</span></h3></td> :
                                syncState === "error" ?
                                    <td className={"text-start align-content-lg-center"}><h3><span className={"badge text-bg-danger"}> {localizatedShootingRangeTexts.syncError}</span></h3></td> :
                                    <td className={"text-start align-content-lg-center"}><h3><span className={"badge text-bg-primary"}> {localizatedShootingRangeTexts.nosync}</span></h3></td>}
                        </tr>
                        </tbody>
                    </table>
                    <h4>
                        <span className="bi-exclamation-triangle" style={{ color: 'orange' }}>&nbsp;{localizatedShootingRangeTexts.syncWarningText}</span>
                    </h4>
                </div>
            </div>
        </div>

    </div>
}

export default ShootingRangeWrapper;