import {Fragment} from "react";
import CoreNavigation from "./CoreNavigation";
import CoreOverview from "./CoreOverview";
import {Navigate, Route, Routes } from 'react-router-dom';
import RangeAdministration from "./RangeAdministration";
import ScreenRegistrationWrapper, {ScreenRegistration} from "./ScreenRegistration";
import RangeManagementNavigationWrapper from "./navigations/RangeManagementNavigation";
import EventManagementOverviewWrapper from "../event/EventManagementOverview";
import EventParticipantsNavigationWrapper from "../event/navigations/EventParticipantsNavigation";
import EventManagementNavigationWrapper from "../event/navigations/EventManagementNavigation";
import RangeOverview from "./RangeOverview";
import RangeShootingPrograms  from "./RangeShootingprograms";
import RangeSettings from "./RangeSettings";
import ShootingAccountingNavigationWrapper from "./navigations/ShootingAccountNavigation";
import ShootAccountingOverview from "./shooAccounting/subsites/Overview";
import ScreenManagementNavigationWrapper from "./navigations/ScreenManagementNavigation";
import Shooter from "./shooAccounting/subsites/Shooter";
import ScreenOverview from "./screenManagement/subsites/Overview";
import ReportWrapper from "./shooAccounting/subsites/Report";
import OrganizationWrapper from "./shooAccounting/subsites/Organization";
import ShootingRangeWrapper from "./shooAccounting/subsites/ShootingRange";
import ScreenOverviewWrapper from "./screenManagement/subsites/Overview";


function CoreMainView() {

    return <div>
        <div className={"row"}>
            <div>
                <CoreNavigation></CoreNavigation>
            </div>
            <div>
                <Routes>
                    <Route path={"/ranges/:rangeId/:disciplineId/*"} element={<RangeManagementNavigationWrapper/>}/>
                    <Route path={"/shootingAccounting/:rangeId/:distanceId/*"} element={<ShootingAccountingNavigationWrapper/>}/>
                    <Route path={"/winfirelive/*" } element= {<ScreenManagementNavigationWrapper/>} />
                </Routes>
            </div>
            <div className={"row"}>
                <Fragment>
                    <Routes>
                        <Route path={"/overview/"} element={<CoreOverview/>}/>
                        <Route path={"/"} element={<CoreOverview/>}/>
                        <Route path={"/ranges"} element={<RangeAdministration/>}/>
                        <Route path={"/ranges/:rangeId/:distanceId/overview"}
                               element={<RangeOverview/>}/>
                        <Route path={"/ranges/:rangeId/:distanceId/shootingprograms"}
                               element={<RangeShootingPrograms/>}/>
                        <Route path={"/ranges/:rangeId/:distanceId/settings"}
                               element={<RangeSettings/>}/>
                        <Route path={"/shootingAccounting/overview"} element={<ShootAccountingOverview/>}/>
                        <Route path={"/shootingAccounting/:rangeId/:distanceId/"} element ={<Navigate to={"report"}/>}/>
                        <Route path={"/shootingAccounting/:rangeId/:distanceId/report"} element={<ReportWrapper/>}/>
                        <Route path={"/shootingAccounting/:rangeId/:distanceId/organisation"} element={<OrganizationWrapper/>}/>
                        <Route path={"/shootingAccounting/:rangeId/:distanceId/shootingrange"} element={<ShootingRangeWrapper/>}/>
                        <Route path={"/shootingAccounting/:rangeId/:distanceId/shooter"} element={<Shooter/>}/>
                        <Route path={"/winfirelive/:rangeId"} element={<Navigate to={"screens"}/>}/>
                        <Route path={"/winfirelive/:rangeId/screens"} element={<ScreenOverviewWrapper/>}/>
                        <Route path={"/registration/:liveguid"} element={<ScreenRegistrationWrapper/>}/>

                    </Routes>
                </Fragment>
            </div>
        </div>
    </div>
}


export default CoreMainView;