import localizatedTexts from "./texts/standard.texts";
import localizatedShooterTexts from "./texts/shooter.texts";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import React, {useEffect, useState} from "react";
import ApiClientFactory from "../../models/ApiClientFactory";
import {OrganizationShotCount, ShootingRange, ShotAccountingOrganization, ShotAccountingShooter} from "../../models/Models";
import localizatedReportTexts from "./texts/report.texts";
import ToastMessage from "../../../common/components/ToastMessage";
import PrintDocument from "../../../common/Helpers/PrintDocument";
import {useParams} from "react-router";
import Report from "./Report";

function ShooterWrapper(){
    const {rangeId, distanceId} = useParams();
    return <Shooter rangeId={rangeId} distanceId={parseInt(distanceId??"")}/>
}

interface ShooterProps {
    rangeId: string | undefined
    distanceId: number
}

const Shooter: React.FC<ShooterProps> = ({ rangeId, distanceId }) => {

    const apiClient = ApiClientFactory.GetApiClient();
    const [organizations, setOrganizations] = useState<ShotAccountingOrganization[]>([]);
    const [selectedShootingRange, setSelectedShootingRange] = useState<string>("")
    const [selectedDistance , setSelectedDistance] = useState<number>(0)
    const [selectedOrganization , setSelectedOrganization] = useState<number>(0)
    const [itemPerPage, setItemPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [filter, setFilter] = useState<string>("");
    const [shooterUpdate, isShooterUpdate] = useState<boolean>(false)
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [updateShooter, setUpdateShooter] = useState<string>("");

    const [toastMessage, setToastMessage] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastBgClass, setToastBgClass] = useState('danger');

    const [shootingRanges, setShootingRanges] = useState<ShootingRange[]>()

    const [name, setName] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [surName, setSurName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [comment, setComment] = useState<string>("");

    function showSuccessToast(message: string) {
        setShowToast(true);
        setToastMessage(message);
        setToastBgClass("success");
    }

    function showErrorToast(errorCode: string) {
        setToastMessage(`${errorCode}`);
        setToastBgClass("danger");
        setShowToast(true);
    }

    function resetInputFields(){
        setSelectedDate(null);
        setName("");
        setCity("");
        setSurName("");
        setPhoneNumber("");
        setComment("");

        isShooterUpdate(false);
        setUpdateShooter("");
    }

    function setFields4Update(Shooter: ShotAccountingShooter){
        setName(Shooter.firstName??"")
        setCity(Shooter.city??"")
        setSurName(Shooter.lastName??"")
        setPhoneNumber(Shooter.phoneNumber??"")
        setComment(Shooter.comment??"")
        setSelectedDate(new Date(Shooter.birthday??"") ?? new Date())
        setUpdateShooter(Shooter.shooterNumber??"")
        isShooterUpdate(true);
    }

    useEffect(() => {
        if (shootingRanges == undefined) {
            loadData();
        }
        if(selectedShootingRange === "" && selectedDistance === 0 && rangeId !=="" &&distanceId !== 0) {
            setSelectedDistance(distanceId)
            setSelectedShootingRange(rangeId??"")
        }
        getOrganization();
    }, [selectedDistance, selectedShootingRange])

    function loadData() {
        apiClient.api.coreShootingRangesList().then(data => {
            setShootingRanges(data.data);
            if(data.data.length ===1){
                setSelectedShootingRange(data.data[0].id??"");
            }
        })
    }

    function getDistance(crud : boolean) {
        const distanceList = shootingRanges?.find(sr => sr.id === selectedShootingRange)?.shootingRangeDisciplines ?? [];
        if(distanceList.length === 1 && selectedDistance !== distanceList[0].distanceId){
            setSelectedDistance(distanceList[0].distanceId??0);
        }
        return shootingRanges?.find(sr => sr.id === selectedShootingRange)?.shootingRangeDisciplines ?? [];
    }

    function getOrganization(): OrganizationShotCount[] {
        if(selectedShootingRange != "" && selectedDistance != 0) {
            apiClient.api.coreOrganizationDetail(selectedShootingRange, selectedDistance).then(data => {
                if (data.data.length > 0 && selectedOrganization === 0) {
                    setSelectedOrganization(data.data[0].id ?? 0);
                }
                setTotalItems(data.data.length);
                return setOrganizations(data.data);
            })
        }
        setOrganizations([])
        return []
    }
    
    const  createUpdateShooter = async () =>{
        const shooter : ShotAccountingShooter = {
            firstName: name,
            lastName : surName,
            city : city,
            organizationId : selectedOrganization,
            birthday : selectedDate?.toISOString().split("T")[0] ?? "",
            shooterNumber : updateShooter,
            phoneNumber : phoneNumber,
            comment : comment,
        }
        if(shooterUpdate){
            const result = await apiClient.api.coreShooterUpdate(selectedShootingRange, selectedDistance, shooter);
            if(result.ok){
                showSuccessToast("Saved");
                isShooterUpdate(false);
                resetInputFields();
                getOrganization();
            }else{
                showErrorToast("Error"+result.statusText);
            }
        }else{
            const result = await apiClient.api.coreShooterCreate(selectedShootingRange, selectedDistance, shooter);
            if(result.ok){
                showSuccessToast("Saved");
                isShooterUpdate(false);
                resetInputFields();
                getOrganization();
            }else{
                showErrorToast("Error"+result.statusText);
            }
        }

    }

    const getMemberCard = async(shooterNr:string)=>{
        if(shooterNr !== "" && selectedShootingRange !== "" && selectedDistance !== 0 && selectedOrganization !== 0){
            const result = await apiClient.api.coreShotAccountingMembercardDetail(selectedShootingRange,selectedDistance, shooterNr)
            if(result.ok){
                await PrintDocument.printPdfDocument(result);
            }
        }
    }

    const deleteShooter = async (shooterNr : string) => {
        const resultet = await apiClient.api.coreShooterDelete(selectedShootingRange,selectedDistance, shooterNr);

        if(resultet.ok){
            showSuccessToast("Deleted");
            getOrganization();
        }else{
            showErrorToast("Error"+resultet.statusText);
        }
    }

    function changePage(page: number) {
        setCurrentPage(page)
    }

    const shooters = organizations.find(org =>
        org.id === selectedOrganization)?.persons ??[]

    const currentShooter =
        organizations.find(org =>
            org.id === selectedOrganization)?.persons?.filter(per =>
            per.firstName?.toLowerCase().includes(filter.toLowerCase())
            || per.lastName?.toLowerCase().includes(filter.toLowerCase())).slice((currentPage - 1) * itemPerPage, currentPage * itemPerPage) ?? [];

    const totalPages = Math.ceil(shooters.length / itemPerPage);

    return <div>
        {showToast && (
            <div id="toast-container" className="position-fixed top-0 start-50 translate-middle-x p-3"
                 style={{zIndex: 11}}>
                <ToastMessage id="errorToast" message={toastMessage} onClose={() => setShowToast(false)} role="alert" bgClass={toastBgClass}/>
            </div>
        )}
        <h1>
        {localizatedTexts.shooter}
    </h1>
        <div className={"container-fluid "}>
            <div className={"row"}>
                <div className={"col-md-10 shadow rounded p-4 m-4 mt-2"}>
                    <form>
                    <h2>{ shooterUpdate ? localizatedShooterTexts.editShooter :localizatedShooterTexts.createShooter}</h2>
                        {shootingRanges?.length != undefined && shootingRanges.length > 1 ?
                            <div className={"input-group mb-3 m-1"}>
                                <span className={"input-group-text"}>{localizatedTexts.shootingrange}</span>
                                <select disabled={true} value={selectedShootingRange} className={"form-select"} onChange={data => {
                                    setSelectedShootingRange(data.currentTarget.value);
                                    setSelectedDistance(0)

                                    const selectElement = document.getElementById("distanceSelector") as HTMLSelectElement;
                                    if (selectElement) {
                                        selectElement.value = ""; // Zurücksetzen auf den übergebenen Standardwert
                                    }
                                }}>
                                    <option value={""}></option>
                                    {shootingRanges.sort((a,b)=> a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()??"") as number).map((range) => {
                                        return <option value={range.id || 0}>{range.name}</option>
                                    })
                                    }
                                    *                       </select>
                            </div> : <div>{localizatedTexts.shootingrange} : {shootingRanges?.map(sr => {
                                return <h1>{sr.name}</h1>
                            })}</div>}
                        {selectedShootingRange != "" && getDistance(false).length > 1?
                            <div className={"input-group mb-3 m-1"}>
                                <span className={"input-group-text "}>{localizatedReportTexts.discipline}</span>
                                <select disabled={true} value={selectedDistance} id={"distanceSelector"} className={"form-select col-10"} onChange={data => {setSelectedDistance(parseInt(data.currentTarget.value))}}>
                                    <option value={0}></option>
                                    { getDistance(false)?.map((ds) => {
                                        return <option value={ds.distanceId || 0}>{ds.disciplineName}</option>
                                    })
                                    }
                                </select>
                            </div> :  getDistance(false).length === 1 ? <div className={"input-group mb-3 m-1"}><span className={"input-group-text "}>{localizatedReportTexts.discipline}</span>  {getDistance(false)?.map(dc => {
                                return <input type={"text"} className={"form-control"} disabled={true} value={dc.disciplineName ??""}></input>
                            })}</div> : ""}
                        {organizations.length !== 0 ?
                        <div className={"input-group mb-3 m-1"}>
                            <span className={"input-group-text"}>{localizatedTexts.organization}</span>
                            <select value={selectedOrganization} className={"form-select"} onChange={(e) => setSelectedOrganization(parseInt(e.target.value))}>
                            {organizations.map((org) => {
                                    return <option value={org.id?? ""}>{org.name}</option>
                                })}
                            </select>
                        </div> :""
                        }
                        {selectedOrganization !== 0 ? <>
                            <div className={"row"}>
                                <div className={"col-6"}>
                                    <div className={"input-group mb-3 m-1"}>
                                        <span className={"input-group-text"}>{localizatedShooterTexts.shooterName}</span>
                                        <input onChange={(e) => setName(e.target.value)} value={name} required={true} type={"text"} className={"form-control"} id={"Name"}/>
                                    </div>
                                </div>
                                <div className={"col-6"}>
                                    <div className={"input-group mb-3 m-1"}>
                                        <span className={"input-group-text"}>{localizatedShooterTexts.shooterSurname}</span>
                                        <input onChange={(e)=> setSurName(e.target.value)} value={surName} required={true} type={"text"} className={"form-control"} id={"SurName"}/>
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-4"}>
                                    <div className={"input-group mb-3 m-1"}>
                                        <span className={"input-group-text"}>{localizatedShooterTexts.city}</span>
                                        <input onChange={(e)=> setCity(e.target.value)} value={city} required={true} type={"text"} className={"form-control"} id={"City"}/>
                                    </div>
                                </div>
                                <div className={"col-4"}>
                                    <div className={"input-group mb-3 m-1"}>
                                        <span className={"input-group-text"}>{localizatedShooterTexts.phoneNumber}</span>
                                        <input onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber} required={false} type={"text"} className={"form-control"} id={"phoneNumber"}/>
                                    </div>
                                </div>
                                <div className={"col-4"}>
                                    <div className={"input-group mb-3 m-1"}>
                                        <span className={"input-group-text"}>{localizatedShooterTexts.birthDate}</span>
                                        <DatePicker required={true}
                                                    selected={selectedDate}
                                                    onChange={(date) => setSelectedDate(date)}
                                                    className={"form-control"}
                                                    id={"BirthDate"}
                                        />
                                    </div>
                                </div>
                            </div>

                        <div className={"input-group mb-3 m-1"}>
                            <span className={"input-group-text"}>{localizatedShooterTexts.comment}</span>
                            <input onChange={(e) => setComment(e.target.value)} value={comment} required={false} type={"text"} className={"form-control"} id={"comment"}/>
                        </div>

                        <button className={"btn btn-primary m-1"} onClick={(e) => {
                            e.preventDefault();

                            createUpdateShooter();
                        }}>
                            {localizatedTexts.save}
                        </button> </> : ""}
                        <button className={"btn btn-secondary m-1"} onClick={(e) => {
                            e.preventDefault();
                            resetInputFields()
                        }}>
                            {localizatedTexts.reset}
                        </button>
                    </form>
                </div>
                <div className={"col-md-10 shadow rounded p-4 m-4 mt-2"}>
                    <h2>{localizatedTexts.shooters}</h2>
                    {organizations.length > 0 ?
                        <div className={"row"}>
                            <div className={"input-group mb-3 m-1"}>
                                <span className={"input-group-text"}>{localizatedTexts.search}</span>
                                <input value={filter} type={"text"} className={"form-control"} placeholder={localizatedTexts.ShooterOrCity} onChange={data => {setFilter(data.currentTarget.value)}}/>
                                <span className={"input-group-text"}>{localizatedTexts.elementsPerPage}</span>
                                <select className={"form-select"} defaultValue={itemPerPage} onChange={data => {setItemPerPage(parseInt(data.currentTarget.value))}}>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                            </div>

                        </div> :"" }
                    <table className={"table table-striped table-hover table-bordered"}>
                        <thead>
                        <tr>
                            <th>{localizatedTexts.shooter}</th>
                            <th>{localizatedTexts.shooterId}</th>
                            <th>{localizatedShooterTexts.city}</th>
                            <th className={"col-2"}>{localizatedShooterTexts.birthDate}</th>
                            <th className={"col-2"}>{localizatedShooterTexts.phoneNumber}</th>
                            <th className={"col-2"}></th>
                        </tr>
                        </thead>
                        <tbody className={"table-hover"}>
                        {currentShooter.sort((a,b) => a.lastName?.toLowerCase().localeCompare(b.lastName?.toLowerCase() ??"") as number).map((shooter) => {
                            return <tr>
                                <td className={"align-middle"}>{shooter.firstName} {shooter.lastName}</td>
                                <td className={"align-middle"}>{shooter.shooterNumber}</td>
                                <td className={"align-middle"}>{shooter.city}</td>
                                <td className={"text-center align-middle"}>{(new Date(shooter.birthday??"2025-01-01")).toLocaleDateString('de-DE', {
                                        day: '2-digit',
                                        month: 'short',
                                        year: 'numeric'
                                    }
                                )}</td>
                                <td className={"text-center align-middle"}>{shooter.phoneNumber}</td>
                                <td className={"text-center align-middle"}>
                                    <button className={"btn btn-primary"} onClick={() => getMemberCard(shooter.shooterNumber?? "")}><span className={"bi-credit-card-2-front"}></span> </button>&nbsp;
                                    <button className={"btn btn-primary"} onClick={() => setFields4Update(shooter)}><span className={"bi-pen"}></span></button>
                                    &nbsp;
                                    <button className={"btn btn-danger"} onClick={() => deleteShooter(shooter.shooterNumber??"")}><span className={"bi-trash"}></span></button>
                                </td>
                            </tr>
                        })}

                        </tbody>
                    </table>
                    <nav aria-label="Page navigation">
                        <ul className="pagination">
                            {currentPage !== 1 ? <li className="page-item"><a className="page-link" onClick={() => changePage(currentPage-1)} >{localizatedTexts.paginatinoPrevious}</a></li> :""}
                            {Array.from({length: totalPages}, (_, i) => (
                                <li key={i} className={i === currentPage - 1 ? 'page-item active' : 'page-item'}><a className={"page-link"} onClick={() => {
                                    if(currentPage !== i+1){
                                        changePage(i+1)
                                    }
                                }}>{i+1}</a> </li>
                            ))}
                            {currentPage !== totalPages && totalPages > 0 ? <li className="page-item"><a className="page-link" onClick={() => changePage(currentPage+1)}>{localizatedTexts.paginationNext}</a></li> : ""}
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
}

export default ShooterWrapper;